import React from 'react';
import styled from 'styled-components';
import { Body5, Body7, Close, Container } from '../../libs/nvstr-common-ui.es';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { useTagSearchResults } from '../../hooks/features/useStockSearch';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { useDispatch } from 'react-redux';
import { showErrorBanner } from '../../utils/application';

const Label = styled.div`
  padding-bottom: 8px;
`;
const InputWrapper = styled.div`
  position: relative;
  min-width: 120px;
  flex: 1;
`;
const InputStyling = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  color: ${({ theme }) => theme.themeColors.text};
  background-color: transparent;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  cursor: text;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  input {
    font-size: 14px;
    border: none !important;
    padding: 4px 0 0 0 !important;
    outline: none;
    line-height: 18px;
  }
`;
const TagWrapper = styled.div`
  border-radius: 2px;
  background: ${({ theme, isSelectedToRemove }) => (isSelectedToRemove ? 'none' : theme.themeColors.primaryCtaButton)};
  border: 1px solid
    ${({ theme, isSelectedToRemove }) => (isSelectedToRemove ? theme.themeColors.primaryCtaButton : 'none')};
  cursor: pointer;
  z-index: 1;

  padding: 3px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: ${({ isSelectedToRemove }) => (isSelectedToRemove ? 0.5 : 1)};

  * {
    color: ${({ theme, isSelectedToRemove }) =>
      isSelectedToRemove ? theme.themeColors.text : theme.themeColors.buttonText} !important;
    fill: ${({ theme, isSelectedToRemove }) =>
      isSelectedToRemove ? theme.themeColors.secondary : theme.themeColors.buttonText} !important;
  }

  svg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    height: 8px !important;
    width: 12px !important;

    opacity: 0.8;
  }

  :hover {
    opacity: 0.9;
  }
`;
const Wrapper = styled.div`
  //
`;
const SelectableTagsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: ${({ isFocused }) => (isFocused ? 'block' : 'none')};
`;
const SelectableTagWrapper = styled.div`
  color: ${({ theme, isSelected }) => (isSelected ? theme.themeColors.buttonText : theme.themeColors.text)};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.themeColors.primaryCtaButton : convertHexToRGBA(theme.themeColors.appBackground, 0.3)};
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  padding: 8px 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: all 150ms;

  * {
    color: ${({ theme, isSelected }) => (isSelected ? theme.themeColors.buttonText : theme.themeColors.text)};
  }

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.75)};
    color: ${({ theme }) => theme.themeColors.buttonText};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
const TruncateText = styled.span`
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CloseIconWrapper = styled.div``;

const SelectableTagsPositionWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: -8px;
  right: -8px;
  max-height: 60vh;
  overflow: auto;
  z-index: 5;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);

  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-top-left-radius: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 1px;
`;

export const CompanyInputTag = ({ value, onRemoveTag, isSelectedToRemove }) => {
  const handleRemoveClick = (e) => {
    e.stopPropagation(); // prevent input from focusing on propagation
    onRemoveTag(value);
  };
  return (
    <TagWrapper isSelectedToRemove={isSelectedToRemove}>
      <Body5>{value}</Body5>
      <CloseIconWrapper onClick={handleRemoveClick}>
        <Close width={12} />
      </CloseIconWrapper>
    </TagWrapper>
  );
};

const SelectableTag = ({ value, onClick, isSelected }) => {
  const { company_id, name, symbol } = value;

  const handleClick = () => {
    onClick(value);
  };

  const text = symbol === null ? name : `${symbol} - ${name}`;
  return (
    <SelectableTagWrapper isSelected={isSelected} onClick={handleClick}>
      <TruncateText>
        <Body7>{text}</Body7>
      </TruncateText>
    </SelectableTagWrapper>
  );
};

function checkHasExactMatch(query, results) {
  if (!results) return false;
  if (!query) return false;

  let has = false;
  results.forEach((r) => {
    if (r.name === query) {
      has = true;
    }
  });
  return has;
}

function formatTagResponseData(data) {
  return {
    ...data,
    id: data.doc_tag_id || data.id,
  };
}

export const SelectableTags = ({
  query,
  value,
  isQuerying,
  onClick,
  isFocused,
  selectResultIndex,
  enableCreateTag,
  enableRequestTicker,
}) => {
  const dispatch = useDispatch();
  const scrollRef = React.useRef(null);

  const onCreateTag = async () => {
    const URL = `v1/genai_doc_tags`;
    const form = {
      name: query,
    };
    const { status, data, error } = await sendApiRequest('post', URL, form);
    logNetRequest(status, data, error);
    if (status === 200) {
      onClick(formatTagResponseData(data));
    } else {
      if (data?.error === 'Doc tag with that name or symbol already exists') {
        showErrorBanner('This company already exists, please refresh the page and try again.', null, dispatch);
      } else {
        showErrorBanner('Something went wrong, please try again.', null, dispatch);
      }
    }
  };

  React.useEffect(() => {
    try {
      const inBounds = (scrollBounds, elHeight, elPosY) => {
        const lower = scrollBounds[0];
        const upper = scrollBounds[1];
        return lower <= elPosY && elPosY <= upper;
      };
      const selectedRow = scrollRef.current.children[selectResultIndex];
      const scrollBoxHeight = scrollRef.current.offsetHeight;
      const scrollPos = scrollRef.current.scrollTop;
      const selectedElementHeight = selectedRow.offsetHeight;
      const selectedElementPosY = selectedRow.offsetTop;

      const scrollBounds = [scrollPos, scrollBoxHeight + scrollPos];
      if (!inBounds(scrollBounds, selectedElementHeight, selectedElementPosY)) {
        selectedRow.scrollIntoView({ alignToTop: false, behavior: 'smooth', inline: 'nearest' });
      }
    } catch (e) {
      // console.error(e);
    }
  }, [scrollRef, selectResultIndex, value]);

  if (!value || !query || query === '' || isQuerying) return null;

  const hasExactMatch = checkHasExactMatch(query, value);
  return (
    <SelectableTagsWrapper isFocused={isFocused}>
      <SelectableTagsPositionWrapper ref={scrollRef}>
        {value?.map((r, i) => (
          <SelectableTag key={r} value={r} onClick={onClick} isSelected={selectResultIndex === i} />
        ))}
        {value?.length === 0 ? (
          <SelectableTagWrapper onClick={() => null}>
            <Container vertical={4}>
              <Body7>No results for "{query}"</Body7>
            </Container>
          </SelectableTagWrapper>
        ) : null}
        {!hasExactMatch && enableCreateTag && (
          <SelectableTagWrapper onClick={onCreateTag}>
            <Container vertical={4}>
              <Body7 bold>+ CREATE "{query}"</Body7>
            </Container>
          </SelectableTagWrapper>
        )}
      </SelectableTagsPositionWrapper>
    </SelectableTagsWrapper>
  );
};

let queuedBlur = null;

const queueBlur = (cb) => {
  queuedBlur = setTimeout(() => {
    cb();
  }, 200);
};

export const TagInput = ({ label, tags, onAddTag, onRemoveTag, isResearchMode, hideLabel, showCompanyTags }) => {
  const inputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);
  const [selectResultIndex, setSelectResultIndex] = React.useState(null);

  const [isQuerying, selectableTags, activeQuery] = useTagSearchResults(inputValue, isResearchMode, showCompanyTags);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === ' ') {
      return;
    }
    setInputValue(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    queueBlur(() => setIsFocused(false));
    // need delay otherwise clicks are missed
  };

  const handleTagSelect = (v) => {
    const { symbol: ticker, name } = v;
    onAddTag(ticker, name);
    setInputValue('');
    setIsFocused(true);
    if (queuedBlur) {
      clearTimeout(queuedBlur);
    }
  };

  const handleInputClick = () => {
    inputRef?.current?.focus();
  };

  React.useEffect(() => {
    // always reset index on input change
    setSelectResultIndex(0);
  }, [inputValue]);

  React.useEffect(() => {
    const handleDeleteTagKeyBinds = (e) => {
      const key = e?.key?.toLowerCase();
      if (key === 'backspace' && tags.length > 0 && inputValue === '' && isFocused) {
        const tag = tags[tags.length - 1];
        onRemoveTag(tag);
      }
    };
    document.addEventListener('keydown', handleDeleteTagKeyBinds);
    return () => {
      document.removeEventListener('keydown', handleDeleteTagKeyBinds);
    };
  }, [isFocused, inputValue, onRemoveTag, inputRef, tags]);

  React.useEffect(() => {
    const handleSelectSearchResultsKeyBinds = (e) => {
      const key = e?.key?.toLowerCase();

      if (['enter'].includes(key)) {
        const selectedTicker = selectableTags[selectResultIndex];
        if (selectedTicker && selectedTicker.length > 0) {
          handleTagSelect(selectedTicker);
        }
      }
    };
    document.addEventListener('keydown', handleSelectSearchResultsKeyBinds);
    return () => {
      document.removeEventListener('keydown', handleSelectSearchResultsKeyBinds);
    };
  }, [tags, inputValue, selectableTags, selectResultIndex]);

  return (
    <Wrapper>
      {hideLabel
        ? null
        : label && (
            <Label>
              <Body5>{label}</Body5>
            </Label>
          )}

      <InputStyling onClick={handleInputClick}>
        {tags?.map((t, i) => (
          <CompanyInputTag key={t} value={t} onRemoveTag={onRemoveTag} />
        ))}

        <InputWrapper>
          <input
            ref={inputRef}
            value={inputValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleInputChange}
          />
          <SelectableTags
            query={activeQuery}
            value={selectableTags}
            isQuerying={isQuerying}
            onClick={handleTagSelect}
            selectResultIndex={selectResultIndex}
            isFocused={isFocused}
          />
        </InputWrapper>
      </InputStyling>
    </Wrapper>
  );
};
