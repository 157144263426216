import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { Answer } from '../../DigitalAnalyst/components/AnswerLine';
import { AI_TOOLS } from '../../../constants';
import { Body5, Body7, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import ExecutionFlowSourceHandle from './components/handles/ExecutionFlowSourceHandle';
import ExecutionFlowTargetHandle from './components/handles/ExecutionFlowTargetHandle';
import InputSourceTargetHandle from './components/handles/InputSourceTargetHandle';
import { NODE_HANDLE_IDS } from './index';
import { NodeDebugger } from './components/NodeDebugger';

const ContentWrapper = styled(NodeContentWrapper)`
  width: 600px;
  min-height: 220px;

  padding-bottom: 122px;
`;

const Content = ({ id, data, value, onTextChange }) => {
  const { answer, error, isComplete } = data;
  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (answer === 'Working...') {
    return (
      <ContentWrapper>
        <Container>
          <Body7>{value}</Body7>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answer) {
    return (
      <ContentWrapper>
        <Container>
          <Body5>Question Asked: {value}</Body5>
        </Container>
        <Answer result={answer} userMode={AI_TOOLS.QUERY_ENGINE} isComplete={isComplete} />
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Enter your question" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export const IfElseNode = (props) => {
  const { data, id, isConnectable } = props;

  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'If / Else'} actions={data.actions} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <InputSourceTargetHandle
        {...props}
        handleActionId={NODE_HANDLE_IDS.DocumentList}
        isConnectable={isConnectable}
        index={2}
      />
      <InputSourceTargetHandle
        {...props}
        handleActionId={NODE_HANDLE_IDS.CompanyList}
        isConnectable={isConnectable}
        index={1}
      />
      <InputSourceTargetHandle
        {...props}
        handleActionId={NODE_HANDLE_IDS.AdvancedSettings}
        isConnectable={isConnectable}
        index={0}
      />
      <ExecutionFlowTargetHandle {...props} isConnectable={isConnectable} />

      <ExecutionFlowSourceHandle yes {...props} isConnectable={isConnectable} />
      <ExecutionFlowSourceHandle no {...props} isConnectable={isConnectable} />

      <NodeDebugger id={id} />
    </NodeWrapper>
  );
};
