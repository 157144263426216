import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import { calcSuggestedMinMax } from '../../utils/usefulFuncs';
import { useColorTheme } from '../../hooks';
import { TransparentFlatButton } from '../../components/buttons';

const ChartHeader = styled.div`
  padding: 0 0 16px 48px;
`;
const ChartAnalysisGlobalWrapper = styled.div`
  /* Customizes the whole scrollbar (including track and thumb) */

  .chart-wrapper {
    scrollbar-color: #222 #fff;
  }

  .chart-wrapper::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
    height: 12px; /* Height of the horizontal scrollbar */
  }

  /* Customizes the track (part the thumb slides within) */

  .chart-wrapper::-webkit-scrollbar-track {
    background: #fff; /* Color of the track */
  }

  /* Customizes the thumb (the draggable part of the scrollbar) */

  .chart-wrapper::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the thumb */
  }

  /* Changes the thumb color when hovered or clicked */

  .chart-wrapper::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
`;
const ChartAnalysisWrapper = styled.div`
  overflow: auto;
`;
const ChartWrapper = styled.div`
  height: 460px;

  width: ${({ DataPoints }) => Math.max(1000, DataPoints * 15) + 'px'};
  padding: 0 0 10px 0;

  canvas {
    display: block;
    height: 460px;
    width: ${({ DataPoints }) => Math.max(1000, DataPoints * 15) + 'px'};
  }
`;
const ChartExtraButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 12px 32px;

  button {
    padding: 4px 18px;
  }
`;

export const SpeakerDocSentimentChart = ({ data, activeKey }) => {
  const chartRef = React.useRef(null);
  const colorTheme = useColorTheme();
  const [hiddenIndexes, setHiddenIndexes] = React.useState([]);

  const generateOptions = (data) => {
    const { min, max } = calcSuggestedMinMax(data, 1);

    return {
      maintainAspectRatio: false,

      elements: {
        point: {
          radius: 3,
          hoverRadius: 10,
          pointStyle: 'circle',
        },
        line: {
          borderWidth: 2,
        },
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
      },

      plugins: {
        tooltip: {
          // enabled: false,
        },
        legend: {
          display: true,
          onClick: (event, legendItem) => {
            const datasetIndex = legendItem.datasetIndex;
            setHiddenIndexes((prev) =>
              prev.includes(datasetIndex) ? prev.filter((v) => v !== datasetIndex) : [...prev, datasetIndex]
            );
          },
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: false,
          min,
          max,
          ticks: {
            color: colorTheme.text,
            min,
            max,
            stepSize: 0.5,
          },
          title: {
            display: true,
            text: 'Dovish - Neutral - Hawkish',
          },
          grid: {
            color: (context) => (context.tick.major ? colorTheme.lowContrastBorder : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
        },
        x: {
          display: false,
          beginAtZero: false,
          grid: {
            tickWidth: 1,
            lineWidth: 1,
            color: (context) => (context.tick.major ? colorTheme.lowContrastBorder : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
          border: {
            display: true,
          },
          ticks: {
            color: colorTheme.text,
            font: {
              family: 'Bolton, Arial',
              size: 10,
              color: colorTheme.text,
            },
          },
        },
      },
    };
  };

  const [options, setOptions] = React.useState(generateOptions(data));

  const [chartData, setChartData] = React.useState({
    labels: data.labels,
    datasets: data.datasets,
    tension: 0.1,
  });

  React.useEffect(() => {
    setOptions(generateOptions(data));
  }, [data]);

  const handleSelectAll = () => {
    const nextIndexes = [];
    setHiddenIndexes(nextIndexes);
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        hidden: nextIndexes.includes(i),
      })),
      tension: 0.1,
    });
  };
  const handleUnselectAll = () => {
    function fillArrayWithIndexes(length) {
      return Array.from({ length }, (_, index) => index);
    }

    const datasetCount = data.datasets.length;
    const nextIndexes = fillArrayWithIndexes(datasetCount);
    setHiddenIndexes(nextIndexes);
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        hidden: nextIndexes.includes(i),
      })),
      tension: 0.1,
    });
  };

  React.useEffect(() => {
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        hidden: hiddenIndexes.includes(i),
      })),
      tension: 0.1,
    });
  }, [hiddenIndexes]);

  return (
    <ChartAnalysisGlobalWrapper>
      <ChartAnalysisWrapper className={'chart-wrapper'}>
        <ChartHeader>
          <Body5 bold>{`Speaker By Doc Sentiment Over Time (${activeKey})`}</Body5>
        </ChartHeader>
        <ChartExtraButtons>
          <TransparentFlatButton onClick={handleSelectAll}>Select All Speakers</TransparentFlatButton>
          <TransparentFlatButton onClick={handleUnselectAll}>Unselect All Speakers</TransparentFlatButton>
        </ChartExtraButtons>
        <ChartWrapper>
          <Line ref={chartRef} data={chartData} options={options} />
        </ChartWrapper>
      </ChartAnalysisWrapper>
    </ChartAnalysisGlobalWrapper>
  );
};
