import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logger, logNetRequest } from '../../utils/usefulFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { uploadStatusActions } from '../../constants/actionTypes';

export function isUrlIngestionComplete(url, completed) {
  let isComplete = false;
  completed.forEach((item) => {
    const { title } = item;
    if (title.includes(url)) {
      isComplete = true;
    }
  });
  return isComplete;
}

export function convertUrlToIngestionCompletedDocument(urls, completed) {
  const docs = [];
  urls.forEach((url) => {
    const ingestionData = completed.find((item) => item.title.includes(url));
    if (ingestionData) {
      docs.push(ingestionData);
    } else {
      logger('ingestion data was missing', { urls, completed });
    }
  });
  return docs;
}

function groupData(data) {
  const completed = [];
  const pending = [];
  const errored = [];
  data.forEach((d) => {
    const { title, status } = d;
    if (status === 'Completed') {
      completed.push(d);
    } else if (status === 'In progress') {
      pending.push(d);
    } else if (status === 'Errored') {
      errored.push(d);
    }
  });
  return { completed, pending, errored };
}

const POLL_DELAY = 10000;

export async function fetchUploadStatus() {
  const URL = `v1/genai_user_crawl_jobs_get`;
  const { status, data, error } = await sendApiRequest('get', URL);
  handle400Statuses(status);
  logNetRequest('genai_user_crawl_jobs_get', status, data);
  if (status === 200) {
    const groupedData = groupData(data);
    return {
      status,
      groupedData,
    };
  } else {
    return {
      status,
      error,
    };
  }
}

export async function fetchAndUpdateStoreUploadStatus(dispatch) {
  const URL = `v1/genai_user_crawl_jobs_get`;
  const { status, data } = await sendApiRequest('get', URL);
  handle400Statuses(status);
  logNetRequest(status, data);
  if (status === 200) {
    const groupedData = groupData(data);
    dispatch({
      type: uploadStatusActions.receiveData,
      payload: groupedData,
    });
  }
}

export function useUploadStatus() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.uploadStatus);

  React.useEffect(() => {
    fetchAndUpdateStoreUploadStatus(dispatch);

    let interval = setInterval(() => {
      fetchAndUpdateStoreUploadStatus(dispatch);
    }, POLL_DELAY);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return state;
}
