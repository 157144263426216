import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { Answer } from '../../DigitalAnalyst/components/AnswerLine';
import { AI_TOOLS } from '../../../constants';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { Loading } from '../../../components/UI/Loading';
import SuccessCheck from '../../../components/UI/SuccessCheck';
import { NodeDebugger } from './components/NodeDebugger';
import ExportDataHandle from './components/handles/ExportDataHandle';
import { NODE_HANDLE_IDS } from './index';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 300px;
  min-height: 120px;
`;
const CompanyAnswerWrapper = styled.div`
  padding-top: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const Content = (props) => {
  const { id, data } = props;
  const { answers, error, isComplete, isIngestingWebsitesComplete, isIngestingWebsites, wasIngestionError } = data;
  if (error || wasIngestionError) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        {error && <Body5>{error}</Body5>}
      </ContentWrapper>
    );
  }
  if (!!answers && Object.keys(answers).length === 0) {
    return (
      <ContentWrapper>
        <Container vertical={8}>
          <Working noVerticalPadding />
        </Container>
      </ContentWrapper>
    );
  }
  if (answers) {
    const companySymbols = Object.keys(answers);
    return (
      <ContentWrapper>
        {isIngestingWebsitesComplete ? (
          <Container bottom={8} top={8} row verticallyCenter>
            <SuccessCheck size={18} />
            <Container left={8} bottom={2}>
              <Body5 bold>Websites were successfully ingested. Documents are available for query.</Body5>
            </Container>
          </Container>
        ) : isIngestingWebsites ? (
          <Container bottom={8} top={8}>
            <Loading message={'Ingesting websites...'} noVerticalPadding />
          </Container>
        ) : null}
        {companySymbols.map((symbol, index) => (
          <CompanyAnswerWrapper key={index}>
            <Container bottom={0}>
              <Body5>{symbol}:</Body5>
            </Container>
            <Answer result={answers[symbol]} userMode={AI_TOOLS.QUERY_ENGINE} isComplete={isComplete} />
          </CompanyAnswerWrapper>
        ))}
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Container bottom={16}>
        <Body5>The company website will be found and ingested for queries.</Body5>
      </Container>
    </ContentWrapper>
  );
};

export default React.memo((props) => {
  const { data, id } = props;
  const forcedQuestion =
    ' get the company website and answer only with the company website, if none is found answer with N/A';
  const [value, setValue] = React.useState(forcedQuestion);

  React.useEffect(() => {
    data.actions.updateData({ question: forcedQuestion });
  }, []);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Ingest Company Website'} actions={data.actions} Left={8} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <ExportDataHandle {...props} handleActionId={NODE_HANDLE_IDS.ForEachAction} />

      <NodeDebugger id={id} />
    </NodeWrapper>
  );
});
