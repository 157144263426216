import { extractIntegerAfterHash, isUndefinedOrNull } from '../../../utils/usefulFuncs';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import { CitationIcon } from '../../../assets/icons/svgs/CitationIcon';
import React from 'react';
import styled from 'styled-components';
import { useColorTheme } from '../../../hooks';

const CitationWrapper = styled.span`
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  &:hover {
    svg {
      opacity: 0.6;
    }
  }
`;

function levenshteinDistance(a, b) {
  const matrix = [];

  // Increment along the first column of each row
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // Increment each column in the first row
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1 // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function findBestMatch(inputString, docs) {
  let bestMatch = null;
  let lowestDistance = Infinity;

  for (let doc of docs) {
    const str = doc.filename;
    const distance = levenshteinDistance(inputString, str);
    if (distance < lowestDistance) {
      lowestDistance = distance;
      bestMatch = doc;
    }
  }

  return bestMatch;
}

function altFindHeader(string) {
  const split = string.split('-');
  const possibleHeaderNumber = parseFloat(split[split.length - 1]);
  if (!isNaN(possibleHeaderNumber)) {
    return possibleHeaderNumber;
  } else {
    return null;
  }
}

function matchFilename(string, sourceDocs) {
  return sourceDocs.find((doc) => string.includes(doc.filename));
}

function parseChunkData(string, sourceDocs) {
  let matchedFile = matchFilename(string, sourceDocs);
  let matchedHeader = extractIntegerAfterHash(string);

  if (!matchedFile) matchedFile = findBestMatch(string, sourceDocs);
  if (!matchedHeader) matchedHeader = altFindHeader(string);

  if (isNaN(matchedHeader)) {
    return {
      doc: null,
      chunkId: null,
    };
  }

  if (sourceDocs.length === 1 && !isNaN(matchedHeader)) {
    return {
      doc: sourceDocs[0],
      chunkId: matchedHeader,
    };
  }

  return {
    doc: matchedFile,
    chunkId: matchedHeader,
  };
}

export function AddingCitations() {
  const colorTheme = useColorTheme();

  return (
    <Container row verticallyCenter bottom={8}>
      <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
      <Container left={8} bottom={8}>
        <Body5>Adding Citations...</Body5>
      </Container>
    </Container>
  );
}

export const Citation = ({ isShowingCitations, data, onClick, sourceDocs }) => {
  const debug = false;
  const { doc, chunkId } = parseChunkData(data, sourceDocs);

  const hc = (e) => {
    e.preventDefault();
    onClick(chunkId, doc);
  };

  if (isUndefinedOrNull(doc)) return null;
  if (!isShowingCitations) return null;
  return (
    <CitationWrapper onClick={hc}>
      {debug ? <Body5>{data}</Body5> : null}
      <CitationIcon />
    </CitationWrapper>
  );
};
