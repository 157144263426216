import React from 'react';
import { useSelector } from 'react-redux';
import { COLOR_THEME_NAMES, COLOR_THEMES } from '../../constants/app';
import { useScreenSize } from '../../libs/nvstr-common-ui.es';
import { breakpoints, colorPalette } from '../../libs/nvstr-utils.es';
import { logger } from '../../utils/usefulFuncs';
import { ROUTES } from '../../constants/routes';
import { usePathnameSafe } from './useLocationSafe';
import { useCurrentUser } from '../user/useCurrentUser';

export const useGlobalTheme = () => {
  const colorThemeName = useColorTheme(true);
  const colorTheme = useColorTheme();
  const screenSize = useScreenSize();

  const theme = React.useMemo(
    () => ({
      colorTheme,
      colorThemeName,
      apiName: colorThemeName,
      ...colorPalette,
      colorPalette,
      breakpoints,
      themeColors: colorTheme,
      screenSize,
      themes: COLOR_THEMES,
    }),
    [colorThemeName, colorTheme, colorPalette, screenSize]
  );
  logger(theme);
  return theme;
};

const forceLightThemeNonAuthRoutes = [ROUTES.splash];

const blue = '#1390e2';
const green = '#29E436';
const yellow = '#efed1b';
const grey = '#c6c6c6';

COLOR_THEMES[COLOR_THEME_NAMES.lightProfessional].documentListNode = blue;
COLOR_THEMES[COLOR_THEME_NAMES.darkProfessional].documentListNode = blue;

COLOR_THEMES[COLOR_THEME_NAMES.lightProfessional].companyListNode = green;
COLOR_THEMES[COLOR_THEME_NAMES.darkProfessional].companyListNode = green;

COLOR_THEMES[COLOR_THEME_NAMES.lightProfessional].actionNode = yellow;
COLOR_THEMES[COLOR_THEME_NAMES.darkProfessional].actionNode = yellow;

COLOR_THEMES[COLOR_THEME_NAMES.lightProfessional].settingsNode = grey;
COLOR_THEMES[COLOR_THEME_NAMES.darkProfessional].settingsNode = grey;

export const useColorTheme = (nameOnly) => {
  const currentUser = useCurrentUser();
  const pathname = usePathnameSafe();

  const forceLightTheme = currentUser === null && forceLightThemeNonAuthRoutes.includes(pathname);
  const state_colorThemeName = useSelector((state) => state.colorTheme.value);
  const customTheme = useSelector((state) => state.colorTheme.customTheme);

  if (nameOnly) return forceLightTheme ? COLOR_THEME_NAMES.lightProfessional : state_colorThemeName;
  if (forceLightTheme) return COLOR_THEMES[COLOR_THEME_NAMES.lightProfessional];
  if (state_colorThemeName === COLOR_THEME_NAMES.custom) return customTheme;
  return COLOR_THEMES[state_colorThemeName];
};
