import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import { createTimeInstance } from '../libs/nvstr-utils.es';
import { AdvancedSettingsFields } from '../hooks/application/useAdvancedSettings';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../constants/actionTypes';
import { CURRENT_DEFAULT_MODEL, FED_DOC_TYPE_MODES } from '../constants';
import { Container, H5, Page } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { FilterForm } from '../containers/DigitalAnalyst/components/DocFilters';
import { useGetRecentFedDocs } from '../hooks/features/useRecentFedDocs';
import { RecentFedDocsList } from '../containers/DigitalAnalyst/RecentFedDocsList';
import { FutureLiveFedEvents } from '../containers/DigitalAnalyst/LiveFedEventsList';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const defaultStartDate = createTimeInstance().subtract(2, 'months');

const advancedSettingsFields = [AdvancedSettingsFields.model];

const defaultDocTypes = FED_DOC_TYPE_MODES.sort();

export function FedRecentDocs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [DocListComponent] = React.useState(<RecentFedDocsList key={'doc-list'} />);
  const [FutureEventsComponent] = React.useState(<FutureLiveFedEvents key={'future-events'} />);

  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [filterDocTypes, setFilterDocTypes] = React.useState(defaultDocTypes);
  const [speakers] = React.useState([]);

  useGetRecentFedDocs(startDate, filterDocTypes, speakers);

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();

    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: {
        model: CURRENT_DEFAULT_MODEL,
      },
    });
  }, []);

  const onFiltersChange = (values) => {
    const { docTypes, startDate } = values;
    setFilterDocTypes(docTypes);
    setStartDate(startDate);
  };

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page width={'1400px'}>
          <BackArrowButton onClick={onGoBack} />
          <Container top={36}>{FutureEventsComponent}</Container>
        </Page>
        <Page width={'1400px'}>
          <AdvancedSettingsButton fields={advancedSettingsFields} top={8} />

          <Container top={0}>
            <H5>Sources</H5>
          </Container>
          <Container>
            <FilterForm docTypes={filterDocTypes} startDate={startDate} onFiltersChange={onFiltersChange} fedDocs />
          </Container>
          <Container top={24}>{DocListComponent}</Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}

export default FedRecentDocs;
