import React from 'react';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { convertHexToRGBA, createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import styled from 'styled-components';
import { FileDetail } from './FileDetail';

const FileRowWrapper = styled.div`
  padding: 0;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;
const FileRowHeaderWrapper = styled.div`
  padding: 13px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    margin-top: 2px;
    height: 14px;
    width: 14px;
  }

  button {
    padding: 2px 12px;
  }

  &:hover {
    border-radius: 2px;
    background-color: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.02)};
  }
`;
const FolderRow = styled.div`
  height: 45px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  &:hover {
    border-radius: 2px;
    background-color: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.02)};
  }

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

const FolderStateIconWrapper = styled.div`
  padding-top: 3px;
  padding-right: 2px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

const ExpandedFolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
  );
};
const CollapsedFolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </svg>
  );
};

const FolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z" />
    </svg>
  );
};

const FileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
    </svg>
  );
};

const FileRow = ({ data, onDeleteDoc }) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const [isDetailView, setIsDetailView] = React.useState(false);

  const { id, filename, created_at } = data;

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverEnd = () => {
    setIsHovering(false);
  };

  const handleDetailViewClick = () => {
    setIsDetailView(true);
  };

  const handleDetailViewCloseClick = () => {
    setIsDetailView(false);
  };

  const handleDelete = () => {
    const modalBody = (
      <Container centerAll>
        <Body5 center>Are you sure you wish to delete {filename}?</Body5>
      </Container>
    );
    onDeleteDoc(modalBody, data);
  };

  const createdAt = createTimeInstance(created_at);
  const fileCreatedAt = formatLocalizedDateTime('ll2', createdAt) + '  ' + formatLocalizedDateTime('LT', createdAt);

  return (
    <FileRowWrapper>
      <FileRowHeaderWrapper
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverEnd}
        onClick={isDetailView ? handleDetailViewCloseClick : handleDetailViewClick}
      >
        <Container row verticallyCenter>
          <FileIcon />
          <Container left={8}>
            <Body5>{filename}</Body5>
          </Container>
        </Container>

        <Container>
          <Body5 isLowContrast>{fileCreatedAt}</Body5>
        </Container>
      </FileRowHeaderWrapper>
      {isDetailView && <FileDetail data={data} onDeleteClick={handleDelete} />}
    </FileRowWrapper>
  );
};

const sortOrders = {
  ALPHA_ASC: 'aa',
  ALPHA_DESC: 'ad',
  MOST_RECENT: 'mr',
};

function _sortDocs(docs, sortOrder) {
  if (sortOrder === sortOrders.ALPHA_ASC) {
    return docs.sort((a, b) => {
      const afilename = a.filename.split('"').join('');
      const bfilename = b.filename.split('"').join('');
      if (afilename < bfilename) {
        return -1;
      }
      if (afilename > bfilename) {
        return 1;
      }
      return 0;
    });
  }
  if (sortOrder === sortOrders.ALPHA_DESC) {
    return docs.sort((a, b) => {
      const afilename = a.filename.split('"').join('').toLowerCase();
      const bfilename = b.filename.split('"').join('').toLowerCase();
      if (afilename < bfilename) {
        return 1;
      }
      if (afilename > bfilename) {
        return -1;
      }
      return 0;
    });
  }
  return docs;
}

export const DocumentTree = ({ folderName, docs, onDeleteDoc }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const [sortOrder, setSortOrder] = React.useState(sortOrders.ALPHA_ASC);
  const [sortedDocs, setSortedDocs] = React.useState(null);

  React.useEffect(() => {
    if (docs) {
      setSortedDocs(_sortDocs(docs, sortOrder));
    }
  }, [docs]);

  const expandRow = () => setIsExpanded(true);
  const collapseRow = () => setIsExpanded(false);

  if (isExpanded && sortedDocs) {
    return (
      <Container>
        <FolderRow onClick={collapseRow}>
          <Container row verticallyCenter>
            <FolderStateIconWrapper>
              {isExpanded ? <ExpandedFolderIcon /> : <CollapsedFolderIcon />}
            </FolderStateIconWrapper>
            <FolderIcon />
            <Container left={12}>
              <Body5>{folderName}</Body5>
            </Container>
          </Container>
        </FolderRow>
        <Container left={9}>
          {sortedDocs.map((d) => (
            <FileRow key={d?.id} data={d} onDeleteDoc={onDeleteDoc} />
          ))}
        </Container>
      </Container>
    );
  }

  return (
    <FolderRow onClick={expandRow}>
      <Container row verticallyCenter>
        <FolderStateIconWrapper>{isExpanded ? <ExpandedFolderIcon /> : <CollapsedFolderIcon />}</FolderStateIconWrapper>
        <FolderIcon />
        <Container left={12}>
          <Body5>{folderName}</Body5>
        </Container>
      </Container>
    </FolderRow>
  );
};
