import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components/navigation';
import { ExtraWideBody } from '../../components/layout/Body';
import { applicationActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { CURRENT_DEFAULT_MODEL } from '../../constants';
import TableAnalysisTool from '../../containers/TableAnalysis/TableAnalysisTool';
import { GRID_MODES } from '../../containers/gridMode';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const defaultAdvancedSettings = {
  // seeContext: true,
  includeInferredMetadata: true,
  model: CURRENT_DEFAULT_MODEL,
  maxContextLength: '32000',
  maxAnswerLength: '',
};

// const tool = AI_TOOLS.CREDIT_AGREEMENT_ANALYSIS;

function CreditAgreementAnalysis() {
  const dispatch = useDispatch();
  const [docMode] = React.useState(GRID_MODES.credit);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });

    return () => {
      // reset
    };
  }, []);

  return (
    <PageWrapper>
      <Header />
      <ExtraWideBody withFooter>
        <TableAnalysisTool docMode={docMode} />
      </ExtraWideBody>
    </PageWrapper>
  );
}

export default CreditAgreementAnalysis;
