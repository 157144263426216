import React from 'react';
import { useDispatch } from 'react-redux';
import { logNetRequest } from '../../../utils/usefulFuncs';
import { sendApiRequest } from '../../../services/api';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { IconButton } from '../../../components/buttons/IconButton';
import { Star } from '../../../assets/icons/svgs/Star';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { gridTableAnalysisActions } from '../../../constants/actionTypes';
import { Edit } from '../../../assets/icons/svgs/Edit';
import { AddPromptModal } from './AddPromptRow';
import styled from 'styled-components';
import TooltipModal from '../../../components/UI/TooltipModal';

function _createNullArray(length) {
  return Array.from({ length }, () => null);
}

const DiffModeFlagWrapper = styled.div`
  cursor: pointer;

  position: absolute;
  top: 0px;
  left: 3px;

  width: 13px;
  height: 14px;

  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  background: ${({ theme }) => theme.themeColors.primaryCtaButton};

  div {
    padding-top: 1px;
    padding-left: 1px;
    margin-top: 0px;
    margin-left: 0px;
  }

  svg {
    height: 11px;
    width: 11px;

    path {
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
const Cell = styled.td`
  position: relative;
`;
const DiffModeTooltipWrapper = styled.td`
  position: relative;
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  z-index: 100000;
  border-radius: 5px;
  padding: 20px;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;

  * {
    z-index: 6;
  }

  svg {
    height: 10px;
    width: 10px;
  }

  path {
    fill: ${({ theme }) => theme.themeColors.text} !important;
  }
`;

const DiffModeTooltip = ({ onDismiss }) => {
  return (
    <DiffModeTooltipWrapper>
      <CloseButtonWrapper>
        <CloseButton onClick={onDismiss} />
      </CloseButtonWrapper>
      <Container right={20}>
        <Body5>This question has "Include adjacent document, e.g. for comparisons and diffs" enabled</Body5>
      </Container>
    </DiffModeTooltipWrapper>
  );
};

const DiffModeFlag = () => {
  const [show, setShow] = React.useState(false);
  const ref = React.useRef();

  const onDismiss = () => {
    setShow(false);
  };

  return (
    <>
      {show && (
        <TooltipModal elementRef={ref} onDismiss={onDismiss} customRender={<DiffModeTooltip onDismiss={onDismiss} />} />
      )}
      <DiffModeFlagWrapper onClick={() => setShow(true)} ref={ref}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8,10V13H14V18H8V21L2,15.5L8,10M22,8.5L16,3V6H10V11H16V14L22,8.5Z" />
          </svg>
        </div>
      </DiffModeFlagWrapper>
    </>
  );
};

export const PromptRow = ({ index, prompt, numberOfColumns, docMode }) => {
  const dispatch = useDispatch();

  const [isEditting, setIsEditting] = React.useState(false);
  const [fillCells, setFillCells] = React.useState([]);

  const favId = prompt.id;
  const isFavorite = favId > 0;
  const isMyPrompt = !isFavorite || prompt.is_my_prompt;

  React.useEffect(() => {
    if (numberOfColumns !== fillCells.length) {
      const array = _createNullArray(numberOfColumns);
      setFillCells(array);
    }
  }, [numberOfColumns, fillCells]);

  const handleEditClick = () => {
    setIsEditting(true);
  };

  const onRemoveClick = () => {
    dispatch({
      type: gridTableAnalysisActions.removePrompt,
      payload: prompt,
    });
  };

  const onCreateFavoriteQuestion = async () => {
    const form = {
      ...prompt,
      display_label: prompt.prompt,
      user_mode: docMode,
      user_modes: [docMode],
    };
    const URL = `v1/genai_favorite_prompts`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.addFavoriteQuestion,
        payload: data,
      });
    }
  };

  const onUnFav = async () => {
    const URL = `v1/genai_favorite_prompts/${favId}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.removeFavoriteQuestion,
        payload: favId,
      });
    }
  };

  const defaultValues = {
    text: prompt.prompt,
    is_diff_mode: prompt.is_diff_mode,
  };

  return (
    <tr>
      {isEditting && (
        <AddPromptModal
          onDismiss={() => setIsEditting(false)}
          isEditMode
          defaultValues={defaultValues}
          favId={prompt.id || null}
        />
      )}
      <Cell>
        {prompt.is_diff_mode && <DiffModeFlag />}
        <Container row centerAll spaceBetween>
          <Container>
            <Body5>{prompt.block_editting ? prompt.display_label : prompt.display_label || prompt.prompt}</Body5>
          </Container>
          <Container row verticallyCenter left={8}>
            {prompt.block_editting ? null : (
              <Container left={8} top={1}>
                <IconButton onClick={handleEditClick}>
                  <Edit size={17} />
                </IconButton>
              </Container>
            )}

            {prompt.is_default || !isMyPrompt ? null : (
              <Container left={8}>
                <IconButton onClick={isFavorite ? onUnFav : onCreateFavoriteQuestion}>
                  <Star size={18} filled={isFavorite} />
                </IconButton>
              </Container>
            )}

            <Container left={8}>
              <CloseButton onClick={onRemoveClick} size={12} />
            </Container>
          </Container>
        </Container>
      </Cell>
      {fillCells.map((c, i) => (
        <td key={i} />
      ))}
    </tr>
  );
};
