import React from 'react';
import styled from 'styled-components';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { Body5, Container, H5, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { logNetRequest } from '../../utils/usefulFuncs';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { NewDocumentSelectionModal } from './components/DocumentSelectionModal';
import { filterDocDuplicates } from '../../reducers/docs';
import { DOC_SELECT_MODES } from '../../constants';
import { useColorTheme } from '../../hooks';
import { useDispatch } from 'react-redux';
import { fedSentimentActions } from '../../constants/actionTypes';
import { buildStoreDataFromResponse } from '../../utils/dataFormatters/sentiment';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }
`;
const Heading = styled.div`
  padding: 36px 0 0 0;

  h5 {
    padding-right: 8px;
  }
`;
const ActionWrapper = styled.div`
  text-align: center;
  padding: 36px 0 0 0;
`;
const SelectedDocsMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 18px;
    width: 18px;
  }
`;

export const useSentimentRecordsV2 = () => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const init = async () => {
      const URL = `v1/genai_fed_sentiment_docs_get`;
      const { status, data, error } = await sendApiRequest('get', URL);
      logNetRequest(URL, status, data);
      if (status === 200) {
        const { document_metadata } = data;
        setData(Object.values(document_metadata));
      } else {
        setError('Something went wrong, please try again.');
      }
    };
    init();
  }, []);
  return { data, error };
};

const SelectedDocsMessage = ({ selectedDocs, onChangeClick, error }) => {
  const colorTheme = useColorTheme();

  if (error) {
    return (
      <Container top={8}>
        <Body5>Error occurred while loading, please refresh and try again.</Body5>
      </Container>
    );
  }
  if (!selectedDocs)
    return (
      <Container row verticallyCenter>
        <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
        <Container left={12}>
          <Body5>Loading...</Body5>
        </Container>
      </Container>
    );
  return (
    <SelectedDocsMessageWrapper>
      <DocumentIcon />
      <Container left={8}>
        <Body5>{selectedDocs.length} Documents/Speeches Selected</Body5>
      </Container>
      <Container left={16}>
        <TransparentFlatButton onClick={onChangeClick}>Change Documents/Speeches</TransparentFlatButton>
      </Container>
    </SelectedDocsMessageWrapper>
  );
};

const docSelectModes = [DOC_SELECT_MODES.fedDoc];

const includes = (files, filename) => {
  let isFound = false;
  files.forEach((f) => {
    if (f.filename === filename) {
      isFound = true;
    }
  });
  return isFound;
};

function SentimentDocSelectTool({ onGoBack, onDocSelectComplete }) {
  const dispatch = useDispatch();
  const [showDocumentSelection, setShowDocumentSelection] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState(null);
  const [formError, setFormError] = React.useState(null);

  const { data: selectableDocuments, error: recordsError } = useSentimentRecordsV2();

  React.useEffect(() => {
    if (selectableDocuments) {
      setSelectedDocs(selectableDocuments);
    }
  }, [selectableDocuments]);

  const onReset = () => {
    setSelectedDocs(null);
  };
  const onClearSelected = () => {
    setSelectedDocs([]);
  };

  const onChangeDocuments = () => {
    setShowDocumentSelection(true);
  };
  const onDismissDocSelection = () => {
    setShowDocumentSelection(false);
  };

  const handleAddDocument = (v) => {
    if (Array.isArray(v)) {
      const docs = [...selectedDocs, ...v];
      setSelectedDocs(filterDocDuplicates(docs));
    } else {
      const docs = [...selectedDocs, v];
      setSelectedDocs(filterDocDuplicates(docs));
    }
  };
  const handleRemoveDocument = (filenameOrFilenames) => {
    if (Array.isArray(filenameOrFilenames)) {
      setSelectedDocs(selectedDocs.filter((d) => !includes(filenameOrFilenames, d.filename)));
    } else {
      setSelectedDocs(selectedDocs.filter((d) => d.filename !== filenameOrFilenames.filename));
    }
  };

  const onGetAnalysis = async () => {
    if (selectedDocs === null || selectedDocs.length === 0) {
      setFormError('Please select a document in the document selection dropdown');
      return;
    }

    setFormError(null);
    dispatch({
      type: fedSentimentActions.setIsGetting,
    });

    const docIds = selectedDocs.map((d) => d.id);
    const onlyFed = 'true';
    onDocSelectComplete({ onlyFed, docIds });

    const URL = `v1/genai_fed_sentiment_get?doc_ids=${docIds}&only_fed_speakers=${onlyFed}`;
    const { status, data, error } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    logNetRequest(URL, status, data);
    if (status === 200) {
      const payload = await buildStoreDataFromResponse(data, true);
      dispatch({
        type: fedSentimentActions.setResults,
        payload: {
          ...payload,
        },
      });
    } else {
      dispatch({
        type: fedSentimentActions.setError,
        payload: data?.error || error || 'Something went wrong, try again.',
      });
    }
  };

  return (
    <Wrapper>
      {showDocumentSelection && (
        <NewDocumentSelectionModal
          docSelectModes={docSelectModes}
          defaultActiveMode={docSelectModes[0]}
          availableDocs={selectableDocuments}
          selectedDocs={selectedDocs}
          handleAddDocument={handleAddDocument}
          handleRemoveDocument={handleRemoveDocument}
          onDismiss={onDismissDocSelection}
        />
      )}
      <Page width={'1400px'}>
        <BackArrowButton onClick={selectedDocs === null ? onGoBack : onReset} />
        <Heading>
          <H5>Fed Sentiment</H5>
          <Container top={8}>
            <Body5>
              Analyze speaker sentiment in any of the available Fed documents. Scores from dovish, neutral, hawkish are
              shown from sentence to sentence, along with a averaged time-series across each document/speech.
            </Body5>
          </Container>
        </Heading>
        <Container top={24} bottom={24}>
          <SelectedDocsMessage selectedDocs={selectedDocs} onChangeClick={onChangeDocuments} error={recordsError} />
          <ActionWrapper>
            {formError && (
              <Container bottom={16}>
                <Body5 error>{formError}</Body5>
              </Container>
            )}
            {recordsError || !selectedDocs ? null : (
              <>
                <FlatButton fullWidth onClick={onGetAnalysis}>
                  Analyze Sentiment
                </FlatButton>
                <Container top={8}>
                  <TransparentFlatButton onClick={onClearSelected}>Clear Selection</TransparentFlatButton>
                </Container>
              </>
            )}
          </ActionWrapper>
        </Container>
      </Page>
    </Wrapper>
  );
}

export default SentimentDocSelectTool;
