import React from 'react';
import styled from 'styled-components';
import { Body1, Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { Modal } from '../../../components/layout/Modal';
import { TrackingEvent } from '../../../libs/nvstr-utils.es';
import { CompanySelectionInput } from './CompanySelectionInput';

const CompanySelectionWrapper = styled.div`
  max-width: 600px;

  input {
    padding: 4px 0 !important;
  }
`;

const SelectedCompanyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 600px;

  padding: 12px;
  margin-top: 8px;

  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const useTrackView = () => {
  React.useEffect(() => {
    TrackingEvent.create('View Company Selection Modal', {});
  }, []);

  return null;
};

export const CompanySelectionModal = ({
  heading,
  selectedCompanies,
  handleAddCompany,
  handleRemoveCompany,
  onDismiss,
}) => {
  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: true });

  useTrackView();

  console.log({ selectedCompanies });

  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <Container row verticallyCenter spaceBetween>
        <Container row verticallyCenter spaceBetween fullWidth>
          <Container>
            <Body1 bold>{heading || 'Select Company'}</Body1>
          </Container>
        </Container>
        <Container top={4} left={16}>
          <CloseButton onClick={onDismiss} />
        </Container>
      </Container>
      <Container top={24}>
        <CompanySelectionWrapper>
          <CompanySelectionInput
            value={null}
            label={null}
            onAddCompany={handleAddCompany}
            onRemoveCompany={handleRemoveCompany}
            placeholder={'Search Companies...'}
            alwaysHideClear
            hideCompanyTags
          />
        </CompanySelectionWrapper>
        <Container top={32}>
          {selectedCompanies.length > 0 && (
            <Container bottom={16} left={3}>
              <Body5 bold>Selected Companies</Body5>
            </Container>
          )}
          {selectedCompanies.map((company, index) => (
            <SelectedCompanyWrapper key={index}>
              <Body5 truncate>{company.name}</Body5>
              <CloseButton onClick={() => handleRemoveCompany(company)} size={14} />
            </SelectedCompanyWrapper>
          ))}
        </Container>
      </Container>
    </Modal>
  );
};
