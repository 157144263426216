import { uploadStatusActions } from '../constants/actionTypes';

const defaultState = {
  isInitializing: true,
  data: null,
  newlyCompletedDocsCount: 0,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case uploadStatusActions.receiveData: {
      const data = action.payload;
      return {
        ...state,
        isInitializing: false,
        data,
        newlyCompletedDocsCount:
          state.newlyCompletedDocsCount + data.completed.length - (state.data?.completed?.length || 0),
      };
    }

    case uploadStatusActions.clearNewlyCompletedUploadDocsCount: {
      return {
        ...state,
        newlyCompletedDocsCount: 0,
      };
    }

    default:
      return state;
  }
}
