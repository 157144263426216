import React from 'react';
import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { addUrlParam } from '../../utils';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { docsActionTypes, newsActionTypes } from '../../constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useAdvancedSettings } from '../application/useAdvancedSettings';
import { logger, logNetRequest } from '../../utils/usefulFuncs';

function formatToSelectedDate(day) {
  let date;
  if (day === 'Recent') {
    date = createTimeInstance();
  } else {
    date = createTimeInstance(day);
  }
  return date;
}

function filterNews(news, _date) {
  const date = formatToSelectedDate(_date);
  const list = [];
  news.forEach((n) => {
    const { timestamp } = n;
    if (createTimeInstance(date).isSame(createTimeInstance(timestamp * 1000), 'day')) {
      list.push(n);
    }
  });
  return list;
}

function buildContext(news, dateFilter) {
  if (!news) return '';

  let context = '';
  const filteredNews = dateFilter ? filterNews(news, dateFilter) : news;
  filteredNews.forEach((n) => {
    const { id, timestamp, open_graph_object: ogo, security, source } = n;
    const date = createTimeInstance(timestamp * 1000).toString();
    const { description, title } = ogo;
    const string = `${security.name} ${security.symbol} ${title} ${source} ${date} ${description} /n/n`;
    context = context + string;
  });
  return context;
}

export function useRecentNewsContextData(dateFilter) {
  const [v, setV] = React.useState(null);
  const news = useSelector((state) => state.news.articles);
  const { enableHeadlines: isEnabled } = useAdvancedSettings();

  React.useEffect(() => {
    if (news && isEnabled) {
      setV(buildContext(news, dateFilter));
    } else {
      if (v !== null) {
        setV(null);
      }
    }
  }, [news, isEnabled, v, dateFilter]);
  return v;
}

export function useRecentDocsAndNewsData() {
  const isLoadingDocs = useSelector((state) => state.recentDocs.isLoading);
  const docs = useSelector((state) => state.recentDocs.byDay);
  const news = useSelector((state) => state.news.articles);

  return {
    isLoading: isLoadingDocs,
    docs,
    news,
  };
}

export function useGetRecentNews() {
  const dispatch = useDispatch();

  const getNewsData = async () => {
    let URL = `/notifications?types=news_da`;
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest('notifications?types=news_da', status, data);
    if (status === 200) {
      const articles = data.notifications.map((n) => n.data);
      dispatch({
        type: newsActionTypes.setNews,
        payload: articles,
      });
    } else {
      return { error: true };
    }
  };

  React.useEffect(() => {
    getNewsData();
  }, []);
}

function groupDocsByDayLookup(docs) {
  const days = {};
  docs.forEach((d) => {
    const { published_at: publishedAt } = d;
    if (publishedAt) {
      const date = formatLocalizedDateTime('api', createTimeInstance(publishedAt));
      if (days[date]) {
        days[date].push(d);
      } else {
        days[date] = [d];
      }
    }
  });

  return days;
}

function sortDates(dates) {
  return dates.sort((a, b) => new Date(b) - new Date(a));
}

function groupResponseToDocsList(lookupBySymbol) {
  const docs = [];
  const values = Object.values(lookupBySymbol);
  values.forEach((docsForStock) => {
    docsForStock.forEach((d) => docs.push(d));
  });
  return docs;
}

function buildDocsSortedByDayList(docsByDay) {
  const availableDatesSortedByMostRecent = sortDates(Object.keys(docsByDay));
  return availableDatesSortedByMostRecent.map((d) => ({
    day: d,
    docs: docsByDay[d],
  }));
}

function buildRecentDocs(docs) {
  const list = [];
  docs.forEach((d) => {
    const { published_at } = d;
    const cutoff = createTimeInstance().subtract(1, 'day').set('hour', '16').set('minute', '0').set('second', '0');
    const publishedAtDate = createTimeInstance(published_at);
    if (publishedAtDate && publishedAtDate.isAfter(cutoff)) {
      list.push(d);
    }
    if (!publishedAtDate) {
      logger('bad publish date', d);
    }
  });
  return list;
}

export function useGetRecentDocs(
  minMarketCap,
  maxMarketCap,
  startDate,
  filterDocTypes,
  tickers,
  ignoreTickers,
  watchlist
) {
  const dispatch = useDispatch();

  const getTickerDocData = async (params, isCancelled) => {
    const {
      symbols,
      minMarketCap,
      maxMarketCap,
      startDate: _params_startDate,
      filterDocTypes: _params_filterDocTypes,
    } = params;

    dispatch({
      type: docsActionTypes.setGettingRecentDocs,
    });

    let startDate = null;
    if (_params_startDate) {
      startDate = createTimeInstance(_params_startDate);
    }
    const dateFormat = '-L';
    const startDateApi = formatLocalizedDateTime(dateFormat, startDate);
    let URL = `v1/genai_tickers_filenames`;
    let URLParams = '?topic=company';

    if (_params_filterDocTypes && _params_filterDocTypes.length > 0) {
      URLParams = addUrlParam(URLParams, `doc_types=${_params_filterDocTypes}`);
    }
    if (watchlist) {
      if (startDate) {
        URLParams = addUrlParam(URLParams, `start_date=${startDateApi}`);
      }
      URLParams = addUrlParam(URLParams, `tickers=${watchlist.map((t) => t.symbol.toUpperCase())}`);
    } else {
      if (startDate) {
        URLParams = addUrlParam(URLParams, `start_date=${startDateApi}`);
      }
      if (minMarketCap) {
        URLParams = addUrlParam(URLParams, `market_cap_min=${minMarketCap}`);
      }
      if (maxMarketCap) {
        URLParams = addUrlParam(URLParams, `market_cap_max=${maxMarketCap}`);
      }
      if (symbols) {
        URLParams = addUrlParam(URLParams, `ticker=${symbols[0]}`);
      }
      if (tickers && !ignoreTickers) {
        URLParams = addUrlParam(
          URLParams,
          `tickers=${watchlist ? watchlist.map((t) => t.symbol.toUpperCase()) : tickers.toUpperCase()}`
        );
      }
    }

    URL += URLParams;
    const { status, data } = await sendApiRequest('get', URL);
    handle400Statuses(status);

    if (isCancelled) {
      return null;
    }

    if (status === 200) {
      const docs = groupResponseToDocsList(data);
      const docsByDay = groupDocsByDayLookup(docs);
      const docsSortedByDayDesc = buildDocsSortedByDayList(docsByDay);
      const recentDocs = buildRecentDocs(docs);
      const payload =
        recentDocs.length > 1
          ? [
              {
                day: 'Recent',
                docs: recentDocs,
              },
              ...docsSortedByDayDesc,
            ]
          : docsSortedByDayDesc;
      dispatch({
        type: docsActionTypes.setRecentDocs,
        payload,
      });
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    let isCancelled = false;
    getTickerDocData({ minMarketCap, maxMarketCap, startDate, filterDocTypes, watchlist }, isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [minMarketCap, maxMarketCap, startDate, filterDocTypes, tickers, ignoreTickers, watchlist]);

  return null;
}
