import React from 'react';
import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { addUrlParam } from '../../utils';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { docsActionTypes } from '../../constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { logNetRequest } from '../../utils/usefulFuncs';

export function useRecentFedDocsData() {
  const isLoadingDocs = useSelector((state) => state.recentDocs.isLoadingFed);
  const docs = useSelector((state) => state.recentDocs.fedRecent);

  return {
    isLoading: isLoadingDocs,
    docs,
  };
}

export function useGetRecentFedDocs(startDate, filterDocTypes, speaker) {
  const dispatch = useDispatch();

  const getTickerDocData = async (params, isCancelled) => {
    const { startDate: _params_startDate, filterDocTypes: _params_filterDocTypes } = params;

    dispatch({
      type: docsActionTypes.setGettingRecentFedDocs,
    });

    let startDate = null;
    if (_params_startDate) {
      startDate = createTimeInstance(_params_startDate);
    }
    const dateFormat = '-L';
    const startDateApi = formatLocalizedDateTime(dateFormat, startDate);
    let URL = `v1/genai_tickers_filenames`;
    let URLParams = '?topic=macro';
    if (_params_filterDocTypes && _params_filterDocTypes.length > 0) {
      URLParams = addUrlParam(URLParams, `doc_types=${_params_filterDocTypes}`);
    }
    if (startDate) {
      URLParams = addUrlParam(URLParams, `start_date=${startDateApi}`);
    }
    URL += URLParams;
    const { status, data } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    logNetRequest(status, data);

    if (isCancelled) {
      return null;
    }

    if (status === 200) {
      const fedDocs = data['0_FED'];
      const payload = fedDocs.sort((a, b) => {
        return createTimeInstance(a.published_at).isAfter(createTimeInstance(b.published_at)) ? -1 : 1;
      });
      dispatch({
        type: docsActionTypes.setRecentFedDocs,
        payload,
      });
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    let isCancelled = false;
    getTickerDocData({ startDate, filterDocTypes, speaker }, isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [startDate, filterDocTypes, speaker]);

  return null;
}
