import React from 'react';
import styled from 'styled-components';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { SkeletonButton, SkeletonButtonWrap } from '../buttons/SkeletonButton';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import ErrorText from './ErrorText';
import { FileUploader } from 'react-drag-drop-files';
import { getFileExtensionType, logger, stripFileExtension } from '../../utils/usefulFuncs';

const DragDropBodyWrapper = styled.div`
  position: relative;
  border: 2px dashed ${({ theme }) => theme.themeColors.primaryCtaButton};
  border-radius: 5px;
  min-height: 20vh;
  width: 100%;
  max-width: unset;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  ${({ IsDragging, theme }) =>
    IsDragging ? `background-color: ${convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.1)}` : ``}
`;

const DragDropWrapper = styled.div`
  label {
    border: none;
  }

  svg {
    height: 36px;
    width: 36px;

    fill: ${({ theme }) => theme.themeColors.primaryCtaButton};

    * {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }

  * {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
`;
const ErrorFileIconWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    fill: ${({ theme }) => theme.themeColors.error};

    * {
      fill: ${({ theme }) => theme.themeColors.error};
    }
  }

  ${SkeletonButtonWrap} {
    padding: 0;

    button {
      padding: 4px 16px;
    }
  }
`;

const FileErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M20 17H22V15H20V17M20 7V13H22V7M4 2C2.89 2 2 2.89 2 4V20C2 21.11 2.89 22 4 22H16C17.11 22 18 21.11 18 20V8L12 2M4 4H11V9H16V20H4Z" />
    </svg>
  );
};
const FileUploadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M23 18H20V15H18V18H15V20H18V23H20V20H23M6 2C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H13.81C13.45 21.38 13.2 20.7 13.08 20H6V4H13V9H18V13.08C18.33 13.03 18.67 13 19 13C19.34 13 19.67 13.03 20 13.08V8L14 2M8 12V14H16V12M8 16V18H13V16Z" />
    </svg>
  );
};

const DragDropError = ({ message, clearError }) => {
  return (
    <DragDropBodyWrapper>
      <ErrorFileIconWrapper>
        <Container fullWidth all={24} row verticallyCenter wrap>
          <FileErrorIcon />
          <Container left={16}>
            <ErrorText>{message}</ErrorText>
          </Container>
          <Container left={16}>
            <SkeletonButton onClick={clearError}>Clear</SkeletonButton>
          </Container>
        </Container>
      </ErrorFileIconWrapper>
    </DragDropBodyWrapper>
  );
};

const UploadDropZone = ({ isDragging }) => {
  return (
    <DragDropBodyWrapper IsDragging={isDragging}>
      <Container fullWidth all={24} row verticallyCenter wrap>
        <Container right={16}>
          <FileUploadIcon />
        </Container>
        {isDragging ? <Body5>Drop here</Body5> : <Body5>Drag and drop files, or click to open upload window.</Body5>}
      </Container>
    </DragDropBodyWrapper>
  );
};

function getFileTypes(params) {
  const { xls, csv, pptx, docx } = params;
  const files = ['PDF'];
  if (xls) {
    files.push('XLS');
    files.push('XLSX');
  }
  if (csv) {
    files.push('CSV');
  }
  if (docx) {
    files.push('DOC');
    files.push('DOCX');
  }
  if (pptx) {
    files.push('PPT');
    files.push('PPTX');
  }
  return files;
}

export const DragDrop = ({ onAddFiles, csv, xls, docx, pptx }) => {
  const [emptyFiles] = React.useState([]);
  const [fileTypes] = React.useState(getFileTypes({ csv, xls, docx, pptx }));

  const [isDragging, setIsDragging] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleTypeError = () => {
    setError(
      'File type is not currently supported, only ' + fileTypes.map((t) => '.' + t.toLowerCase()).join(' ') + ' files.'
    );
  };

  const handleChange = (fileOrFiles) => {
    setError(null);

    try {
      const filesToAdd = [];
      for (let i = 0; i < fileOrFiles.length; i++) {
        const file = fileOrFiles[i];
        logger('File name:', file.name);
        logger('File size (bytes):', file.size);
        filesToAdd.push({
          name: stripFileExtension(file),
          type: getFileExtensionType(file),
          file,
        });
      }
      onAddFiles(filesToAdd);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDraggingStateChange = (isHovering) => {
    setIsDragging(isHovering);
  };

  return (
    <DragDropWrapper>
      <FileUploader
        onDraggingStateChange={handleDraggingStateChange}
        handleChange={handleChange}
        onTypeError={handleTypeError}
        name="file"
        hoverTitle={''}
        dropMessageStyle={{ display: 'none' }}
        disabled={!!error}
        children={
          error ? (
            <DragDropError message={error} clearError={() => setError(null)} />
          ) : (
            <UploadDropZone isDragging={isDragging} />
          )
        }
        types={fileTypes}
        multiple={true}
        fileOrFiles={emptyFiles}
      />
    </DragDropWrapper>
  );
};
