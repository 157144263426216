import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Body5, Body7, CheckCircle, Container } from '../../../libs/nvstr-common-ui.es';
import ExecutionFlowSourceHandle from './components/handles/ExecutionFlowSourceHandle';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import { Working } from '../../../components/UI/Working';
import styled from 'styled-components';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 300px;
  min-height: 120px;

  button {
    width: 160px;
  }
`;
const CheckWrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

export default React.memo((props) => {
  const { data, id, isConnectable } = props;
  const { isComplete, didError } = data;
  const [isRunning, setIsRunning] = React.useState(false);

  const onRun = () => {
    setIsRunning(true);
    data.actions.onRunOutput();
  };

  const onClearAnswers = () => {
    setIsRunning(false);
    data.actions.onClearAnswers();
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Start'} Left={8} />
      <ContentWrapper>
        <Container bottom={24}>
          <Body7>Drag down to start and select your first node.</Body7>
        </Container>
        {didError ? (
          <Container>
            <Body5>An error occurred during output. Please try again.</Body5>
            <Container top={16}>
              <FlatButton onClick={onRun}>Run</FlatButton>
            </Container>
          </Container>
        ) : isComplete ? (
          <Container fullWidth>
            <Container top={8} left={2} row verticallyCenter>
              <CheckWrapper>
                <CheckCircle />
              </CheckWrapper>
              <Container left={8} bottom={3}>
                <Body5 bold>Completed</Body5>
              </Container>
            </Container>
            <Container top={30} centerAll>
              <FlatButton onClick={onRun}>Re-Run</FlatButton>
            </Container>
            <Container top={4} centerAll>
              <TransparentFlatButton onClick={onClearAnswers}>Clear Answers</TransparentFlatButton>
            </Container>
          </Container>
        ) : isRunning ? (
          <Container vertical={8}>
            <Working noVerticalPadding />
          </Container>
        ) : (
          <FlatButton onClick={onRun}>Run</FlatButton>
        )}
      </ContentWrapper>
      <ExecutionFlowSourceHandle {...props} isConnectable={isConnectable} />
    </NodeWrapper>
  );
});
