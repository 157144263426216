import { logger } from '../utils/usefulFuncs';
import ExecutionState from '../classes/ExecutionState';
import { ExecutionTree } from '../classes/ExecutionTree';
import { NodeData } from '../classes/NodeUtils'; // function buildNodeExecutionOrder(node, nodes, edges) {

function slowClone(obj) {
  const str = JSON.stringify(obj);
  return JSON.parse(str);
}

export const executeFlowQuery = async (startNode, nodes, edges) => {
  console.log('---------------------------------');
  console.log('run', { startNode, nodes, edges });
  console.log('---------------------------------');

  const executionState = new ExecutionState();

  try {
    startNode.data.actions.updateData({ isRunning: true, isComplete: false, didError: false });

    const state = {
      nodes,
      edges,
    };
    const mutableState = {
      nodes: slowClone(nodes),
      edges: slowClone(edges),
    };

    const startNodeData = new NodeData(startNode, mutableState.nodes, mutableState.edges);
    const { nextNodes } = startNodeData.data;
    nextNodes.forEach((nextNode) => {
      const executionTree = new ExecutionTree(nextNode, state, mutableState, executionState.addTree);
      executionState.addTree(executionTree);
      executionTree.run();
    });

    return new Promise((resolve) => {
      const onComplete = (resolve) => {
        return () => {
          startNode.data.actions.updateData({ isRunning: false, isComplete: true, didError: false });
          resolve(true);
        };
      };

      const onError = (resolve) => {
        return () => {
          logger('error while executing state');
          startNode.data.actions.updateData({ isRunning: false, didError: true });
          resolve(false);
        };
      };

      executionState.onComplete = onComplete(resolve);
      executionState.onError = onError(resolve);
    });
  } catch (e) {
    logger('error caught while running execution state', e);
    startNode.data.actions.updateData({ isRunning: false, didError: true });
    return false;
  }
};
