import React from 'react';
import { useConnection } from '@xyflow/react';
import { useColorTheme } from '../../../../hooks';
import { generateCustomInputNodeHandle } from '../index';

export default ({ fromX, fromY, toX, toY }) => {
  const colorTheme = useColorTheme();
  const { fromHandle } = useConnection();
  const nodeHandle = generateCustomInputNodeHandle(fromHandle.id);
  const color = nodeHandle ? colorTheme[nodeHandle.colorThemeName] : colorTheme.text;
  return (
    <g>
      <path
        fill="none"
        stroke={color}
        strokeWidth={1.5}
        className="animated"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle cx={toX} cy={toY} fill="#fff" r={3} stroke={color} strokeWidth={1.5} />
    </g>
  );
};
