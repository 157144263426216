import React from 'react';
import { BASEURL } from '../../services/network';
import { logError } from '../../utils/usefulFuncs';

const ACTIONS = {
  init: 'init',
  reset: 'reset',
  update: 'u',
  error: 'error',
  complete: 'complete',
};

function parseMessage(inputString) {
  if (inputString.startsWith('[')) {
    let endIndex = inputString.indexOf(']');
    if (endIndex !== -1 && endIndex < 4) {
      let numberString = inputString.substring(1, endIndex);
      if (/^\d+$/.test(numberString)) {
        let number = parseInt(numberString, 10);
        let restOfString = inputString.substring(endIndex + 2).trim();
        return [number, restOfString];
      }
    }
  }
  return [null, inputString];
}

function createAnswersLookupFromCompletePayload(payload) {
  const l = {};
  payload.forEach((obj, i) => {
    const { answer, context, question } = obj;
    l[i] = answer;
  });
  return l;
}

function createContextLookupFromCompletePayload(payload) {
  const l = {};
  payload.forEach((obj, i) => {
    const { answer, context, question } = obj;
    l[i] = context;
  });
  return l;
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.reset: {
      return initialState;
    }

    case ACTIONS.init: {
      return action.payload;
    }

    case ACTIONS.update: {
      const { payload } = action;
      const [id, message] = parseMessage(payload);
      return {
        ...state,
        answersLookup: {
          ...(state.answersLookup || {}),
          [id]: message,
        },
      };
    }

    case ACTIONS.error: {
      return { ...state, error: action.payload };
    }

    case ACTIONS.complete: {
      const { payload } = action;
      const contextLookup = createContextLookupFromCompletePayload(payload);
      return {
        ...state,
        streamEnd: true,
        answersLookup: createAnswersLookupFromCompletePayload(payload),
        contextLookup,
      };
    }

    default:
      throw new Error('Missing case');
  }
}

const getStreamingResponse = async (resultId, options, dispatch) => {
  if (!resultId) {
    console.warn('resultId was missing');
    return null;
  }

  var source = new EventSource(`${BASEURL}/api/v1/genai_qa/stream_events_lite?id=${resultId}&include_context=true`, {
    withCredentials: true,
  });

  const onMessage = function (event) {
    try {
      if (options.isClosed) {
        return;
      }

      const { data } = event;
      dispatch({
        type: ACTIONS.update,
        payload: data,
      });
    } catch (e) {
      logError('error caught in stream', e);
    }
  };
  const onOpen = function () {
    try {
      if (options.isClosed) {
        return null;
      }
    } catch (e) {
      logError('error caught in stream', e);
    }
  };
  const onError = function (event) {
    try {
      if (options.isClosed) {
        return;
      }

      options.isClosed = true;
      dispatch({
        type: ACTIONS.error,
        payload: 'Something went wrong.',
      });
    } catch (e) {
      logError('error caught in stream', e);
    }
  };
  const onComplete = function (event) {
    try {
      if (options.isClosed) {
        return;
      }
      options.isClosed = true;

      const { data } = event;
      const streamData = JSON.parse(data);
      const { response } = streamData;
      const { answers_with_contexts } = response;
      if (answers_with_contexts) {
        dispatch({
          type: ACTIONS.complete,
          payload: answers_with_contexts,
        });
      }

      source.removeEventListener('open', onOpen);
      source.removeEventListener('error', onError);
      source.removeEventListener('message', onMessage);
      source.close();
    } catch (e) {
      logError('error caught in stream', e);
    }
  };

  source.addEventListener('open', onOpen);
  source.addEventListener('error', onError);
  source.addEventListener('message', onMessage);
  source.addEventListener('complete', onComplete);

  return source;
};

const initialState = {
  contextLookup: null,
};

function convertQuestionListToQuestionListAndAnswerIds(questionList) {
  return questionList.map((q, i) => [q, i]);
}

export const useNewCompanySnapshotStreamingResult = (resultId, questionList) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  React.useEffect(() => {
    if (questionList) {
      dispatch({
        type: ACTIONS.init,
        payload: {
          streamEnd: false,
          questionList,
          questionListAndAnswerIds: convertQuestionListToQuestionListAndAnswerIds(questionList),
          answersLookup: {},
        },
      });
    }
  }, [questionList]);

  React.useEffect(() => {
    let options = { isClosed: false };
    if (resultId) {
      getStreamingResponse(resultId, options, dispatch);
    }
    return () => {
      options.isClosed = true;
      dispatch({
        type: ACTIONS.reset,
      });
    };
  }, [resultId]);

  return state;
};
