import React from 'react';
import { CompanySelectionModal } from '../DigitalAnalyst/components/CompanySelectionModal';

function filterDuplicates(arr) {
  const seenIds = new Set();
  return arr.filter((obj) => {
    if (!obj || typeof obj.company_id === 'undefined') {
      throw new Error('Each object must have a company_id property');
    }
    if (seenIds.has(obj.company_id)) {
      return false;
    } else {
      seenIds.add(obj.company_id);
      return true;
    }
  });
}

export const CompanySelectionModalController = ({ onInit, nodes, updateNodeData }) => {
  const [activeNodeId, setActiveNodeId] = React.useState(null);

  const showModal = (nodeId) => {
    setActiveNodeId(nodeId);
  };

  const hideModal = () => {
    setActiveNodeId(null);
  };

  React.useEffect(() => {
    onInit({ showModal, hideModal });
  }, []);

  if (!activeNodeId) {
    return null;
  }

  const nodeIndex = nodes.findIndex((node) => node.id === activeNodeId);
  const node = nodes[nodeIndex];
  const selectedCompanies = node.data.selectedCompanies;

  const onAddCompany = (nodeId, company) => {
    const data = {
      selectedCompanies: filterDuplicates([...selectedCompanies, company]),
    };
    updateNodeData(nodeId, data);
  };
  const onRemoveCompany = (nodeId, company) => {
    const data = {
      selectedCompanies: selectedCompanies.filter(
        (selectedCompany) => selectedCompany.company_id !== company.company_id
      ),
    };
    updateNodeData(nodeId, data);
  };

  const bindNodeIdToAddCompany = (nodeId) => (company) => onAddCompany(nodeId, company);
  const bindNodeIdToRemoveCompany = (nodeId) => (company) => onRemoveCompany(nodeId, company);

  return (
    <CompanySelectionModal
      selectedCompanies={selectedCompanies}
      handleAddCompany={bindNodeIdToAddCompany(activeNodeId)}
      handleRemoveCompany={bindNodeIdToRemoveCompany(activeNodeId)}
      onDismiss={hideModal}
    />
  );
};
