import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { getResultId } from '../hooks/features/useAskDigitalAnalyst';
import { logger } from '../utils/usefulFuncs';
import { streamResult } from '../hooks/features/useStreamingResult';
import {
  convertUrlToIngestionCompletedDocument,
  fetchUploadStatus,
  isUrlIngestionComplete,
} from '../hooks/features/useUploadStatus';
import { COMPANY_LIST_PROMPT_PREFIX, NODE_TYPES } from '../containers/flow/nodes';
import { sendApiRequest } from '../services/api';
import { NodeData } from './NodeUtils';

const executeQuestionForCompany = async (data, extraParams, node, nodeActions) => {
  const { company, question, extraContext } = data;
  const model = CURRENT_DEFAULT_MODEL;

  const params = {
    tickers: [company.symbol],
    tool: AI_TOOLS.QUERY_ENGINE,

    extraContext: extraContext || null,
    model,

    enableFollowUpQuestions: false,
    ...extraParams,
  };
  const { id: resultId, error } = await getResultId(question, params, {});

  if (error) {
    node.data.error = error;
    nodeActions.updateData({ error });
    logger('error getting result', node);
    return false;
  }

  const onOpen = () => {
    if (!('answers' in node.data)) {
      node.data.answers = {};
    }
    node.data.answers[company.symbol] = 'Working...';
    nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: 'Working...' });
  };
  const onMessage = (answer) => {
    node.data.answers[company.symbol] = answer;
    nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: answer });
  };
  const onRefiningStart = () => {
    node.data.isRefining = true;
    nodeActions.updateData({ isRefining: true });
  };
  const onError = (error) => {
    node.data.error = error;
    nodeActions.updateData({ error });
  };
  const onComplete = ({ answer }) => {
    node.data.answers[company.symbol] = answer;
    nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: answer });
  };
  const handlers = {
    onOpen,
    onMessage,
    onRefiningStart,
    onError,
    onComplete,
  };
  const options = { enableQualityCheck: true };
  return streamResult(resultId, handlers, options);
};

async function completeIngestionStatuses(websites) {
  let isComplete = false;
  let error = null;

  return new Promise((resolve) => {
    const delay = 10 * 1000;
    const interval = setInterval(async () => {
      const response = await fetchUploadStatus();
      const { groupedData } = response;

      if (groupedData) {
        const { completed, errored } = groupedData;
        const hasCompletedAllWebsites = websites.every((url) => isUrlIngestionComplete(url, completed));
        const wasError = websites.some((url) => isUrlIngestionComplete(url, errored));
        logger('ingestion check', { hasCompletedAllWebsites, wasError, completed, errored });
        if (wasError) {
          error = true;
          clearInterval(interval);
          resolve({
            isComplete,
            error,
          });
        }

        if (hasCompletedAllWebsites) {
          isComplete = true;
          clearInterval(interval);
          resolve({
            isComplete,
            error,
            documents: convertUrlToIngestionCompletedDocument(websites, completed),
          });
        }
      }
    }, delay);
  });
}

function addOptionalSettings(formData, settings) {
  const updatedFormData = formData;
  Object.entries(settings).forEach(([key, value]) => {
    if (value !== '') {
      updatedFormData[key] = value;
    }
  });
  return updatedFormData;
}

function buildNodeActionLookup(state) {
  const lookup = {};
  for (const node of state.nodes) {
    lookup[node.id] = node.data.actions;
  }
  return lookup;
}

export class NodeExecutor {
  constructor(node, state, mutableState) {
    this.node = node;
    this.state = state;
    this.mutableState = mutableState;

    this.muteableNode = this.mutableState.nodes.find((n) => n.id === node.id);

    this.nodeActionsLookup = buildNodeActionLookup(state);
    this.nodeActions = this.nodeActionsLookup[node.id];

    this.nextNodes = [];
    this.error = null;
  }

  execute = async () => {
    logger('execute node: ' + this.node.type, { node: this.muteableNode, state: this.state });

    switch (this.node.type) {
      case NODE_TYPES.IfElseNode: {
        const { nodes, edges } = this.mutableState;
        const nodeData = new NodeData(this.node, nodes, edges);
        const {
          children,
          actionNode,
          parentNodes,
          selectedDocs,

          advancedSettings,
          selectedCompanies,
          companies,

          nodeContext,
          extraContext,

          nextNodes,
        } = nodeData.data;
        const _companies = selectedCompanies || companies;

        const model = CURRENT_DEFAULT_MODEL;

        const questionPrefix =
          'Answer the following question with only one word "YES" or "NO" based on the context. Question: ';
        const userQuestion = this.muteableNode.data.question;
        const question = questionPrefix + userQuestion;

        const params = {
          selectedDocs,
          screen_mode: false,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,
          model,
          enableFollowUpQuestions: false,
        };
        if (_companies.length > 0) {
          params.tickers = _companies.filter((c) => c.symbol).map((c) => c.symbol);
        }
        addOptionalSettings(params, advancedSettings);

        const { id: resultId, error } = await getResultId(question, params, {});

        if (error) {
          this.muteableNode.data.error = error;
          this.nodeActions.updateData({ error });
          logger('error getting result', this.node);
          this.error = true;
          return false;
        }

        const onOpen = () => {
          this.muteableNode.data.answer = 'Working...';
          this.nodeActions.updateData({ answer: 'Working...' });
        };
        const onMessage = (answer) => {
          this.muteableNode.data.answer = answer;
          this.nodeActions.updateData({ answer });
        };
        const onRefiningStart = () => {
          this.muteableNode.data.isRefining = true;
          this.nodeActions.updateData({ isRefining: true });
        };
        const onError = (error) => {
          this.muteableNode.data.error = error;
          this.nodeActions.updateData({ error });
        };
        const onComplete = ({ answer }) => {
          this.muteableNode.data.answer = answer;
          this.nodeActions.updateData({ answer });
        };
        const handlers = {
          onOpen,
          onMessage,
          onRefiningStart,
          onError,
          onComplete,
        };
        const options = { enableQualityCheck: true };
        await streamResult(resultId, handlers, options);

        this.nextNodes = nodeData.getConditionalNextNodes(this.muteableNode, this.nodeActions);
        return true;
      }
      case NODE_TYPES.ForEachCompanyNode: {
        const { nodes, edges } = this.mutableState;
        const node = new NodeData(this.muteableNode, nodes, edges);
        const {
          children,
          actionNode,
          parentNodes,
          advancedSettings,
          companies,

          nodeContext,
          extraContext,

          nextNodes,
        } = node.data;

        const forEachNodeActions = this.nodeActionsLookup[this.node.id];

        logger('node params', {
          companies,
          actionNode,
          actionType: actionNode?.type,
          extraContext,
          advancedSettings,
          children,
          nodeContext,
          parentNodes,
        });

        const actionNodeActions = this.nodeActionsLookup[actionNode.id];

        let action;
        if (actionNode.type === NODE_TYPES.ForEachCompanyQuestionNode) {
          action = (company) => {
            const question = actionNode.data.question;
            const data = { question, company, extraContext };
            const extraParams = {};
            addOptionalSettings(extraParams, advancedSettings);
            return executeQuestionForCompany(data, extraParams, actionNode, actionNodeActions);
          };
        }
        if (actionNode.type === NODE_TYPES.ForEachIngestWebsiteNode) {
          action = async (company) => {
            const question = actionNode.data.question;
            const data = { question, company, extraContext };
            const extraParams = {};
            addOptionalSettings(extraParams, advancedSettings);
            return executeQuestionForCompany(data, extraParams, actionNode, actionNodeActions);
          };
        }
        const requests = companies.map((company) => {
          return action(company);
        });

        if (companies.length === 0) {
          const error = 'no companies were found in iterator';
          logger(error, companies);
          this.muteableNode.data.error = error;
          actionNode.data.error = error;
          this.nodeActions.updateData({ error });
          actionNodeActions.updateData({ error });
          this.error = true;
          return false;
        }
        await Promise.all(requests);

        if (actionNode.type === NODE_TYPES.ForEachIngestWebsiteNode) {
          let ingestAction;
          const answers = actionNode.data.answers;
          const companyWebsites = Object.values(answers).filter((url) => url !== 'N/A');

          ingestAction = async (website) => {
            const enableJS = true;
            const numOfPages = 20;
            const version = 2;
            const form = { url: website, run_js: enableJS, max_pages: numOfPages, crawl_version: version };
            const URL = `v1/genai_process_web_page`;
            const { status, data, error } = await sendApiRequest('post', URL, form);
            return { status, url: website };
          };

          const requests = companyWebsites.map((url) => ingestAction(url));
          actionNodeActions.updateData({ isIngestingWebsites: true });
          const responses = await Promise.all(requests);
          if (responses.every((r) => r.status === 200)) {
            const { error, documents } = await completeIngestionStatuses(companyWebsites, this.nodeActions);
            const selectedDocs = documents;
            logger('completeIngestion', error, documents);
            this.muteableNode.data.selectedDocs = selectedDocs;
            forEachNodeActions.updateData({ selectedDocs });
            actionNodeActions.updateData({ isIngestingWebsitesComplete: true });
            if (error) {
              actionNodeActions.updateData({ wasIngestionError: true });
            }
          } else {
            // fires if a request fails to start ingestion
            actionNodeActions.updateData({ wasIngestionError: true });
          }
        }

        this.nextNodes = nextNodes;
        return true;
      }
      case NODE_TYPES.GenerateCompanyListNode: {
        const { nodes, edges } = this.mutableState;
        const node = new NodeData(this.node, nodes, edges);
        const {
          children,
          actionNode,
          parentNodes,
          selectedDocs,

          advancedSettings,
          companies,

          nodeContext,
          extraContext,

          nextNodes,
        } = node.data;

        const model = CURRENT_DEFAULT_MODEL;

        const question = COMPANY_LIST_PROMPT_PREFIX + this.muteableNode.data.question;
        const params = {
          selectedDocs,
          screen_mode: selectedDocs.length === 0,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,

          model,
          enableContext: true,
        };
        addOptionalSettings(params, advancedSettings);

        // const answer =
        //   '```json\n[\n    {\n        "name": "JPMorgan Chase & Co.",\n        "symbol": "JPM"\n    }\n]\n```';
        // this.muteableNode.data.answer = answer;
        // this.nodeActions.updateData({ answer });
        // this.nextNodes = nextNodes;
        // return true;

        const { id: resultId, error } = await getResultId(question, params, {});

        if (error) {
          this.muteableNode.data.error = error;
          this.nodeActions.updateData({ error });
          logger('error getting result', node);
          this.error = true;
          return false;
        }

        const onOpen = () => {
          this.muteableNode.data.answer = 'Working...';
          this.nodeActions.updateData({ answer: 'Working...' });
        };
        const onMessage = (answer) => {
          this.muteableNode.data.answer = answer;
          this.nodeActions.updateData({ answer });
        };
        const onRefiningStart = () => {
          this.muteableNode.data.isRefining = true;
          this.nodeActions.updateData({ isRefining: true });
        };
        const onError = (error) => {
          this.muteableNode.data.error = error;
          this.nodeActions.updateData({ error });
        };
        const onComplete = ({ answer }) => {
          this.muteableNode.data.answer = answer;
          this.nodeActions.updateData({ answer });
        };
        const handlers = {
          onOpen,
          onMessage,
          onRefiningStart,
          onError,
          onComplete,
        };
        const options = { enableQualityCheck: true };
        await streamResult(resultId, handlers, options);

        this.nextNodes = nextNodes;
        return true;
      }
      case NODE_TYPES.PromptNode: {
        const { nodes, edges } = this.mutableState;
        const node = new NodeData(this.node, nodes, edges);
        const {
          children,
          actionNode,
          parentNodes,
          selectedDocs,

          advancedSettings,
          selectedCompanies,
          companies,

          nodeContext,
          extraContext,

          nextNodes,
        } = node.data;
        const _companies = selectedCompanies || companies;

        const model = CURRENT_DEFAULT_MODEL;

        const question = this.muteableNode.data.question;
        const params = {
          selectedDocs,
          screen_mode: selectedDocs.length === 0 && _companies.length === 0,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,
          model,
          enableFollowUpQuestions: false,
        };
        if (_companies.length > 0) {
          params.tickers = _companies.filter((c) => c.symbol).map((c) => c.symbol);
        }
        addOptionalSettings(params, advancedSettings);

        const { id: resultId, error } = await getResultId(question, params, {});

        if (error) {
          this.muteableNode.data.error = error;
          this.nodeActions.updateData({ error });
          logger('error getting result', node);
          this.error = true;
          return false;
        }

        const onOpen = () => {
          this.muteableNode.data.answer = 'Working...';
          this.nodeActions.updateData({ answer: 'Working...' });
        };
        const onMessage = (answer) => {
          this.muteableNode.data.answer = answer;
          this.nodeActions.updateData({ answer });
        };
        const onRefiningStart = () => {
          this.muteableNode.data.isRefining = true;
          this.nodeActions.updateData({ isRefining: true });
        };
        const onError = (error) => {
          this.muteableNode.data.error = error;
          this.nodeActions.updateData({ error });
        };
        const onComplete = ({ answer }) => {
          this.muteableNode.data.answer = answer;
          this.nodeActions.updateData({ answer });
        };
        const handlers = {
          onOpen,
          onMessage,
          onRefiningStart,
          onError,
          onComplete,
        };
        const options = { enableQualityCheck: true };
        await streamResult(resultId, handlers, options);

        this.nextNodes = nextNodes;
        return true;
      }

      default: {
        logger('missing node type handler for execution', this.node.type);
        this.error = true;
        return false;
      }
    }

    return false;
  };
}
