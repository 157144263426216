import { Header } from '../navigation';
import { Body } from '../layout/Body';
import { Container, Page } from '../../libs/nvstr-common-ui.es';
import React from 'react';
import { Loading } from './Loading';
import styled from 'styled-components';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;

export function PageLoading() {
  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <Container vertical={24}>
              <Loading />
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}
