import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOADED_DOC_TYPES } from '../../constants';
import { logger, logNetRequest, sleep } from '../../utils/usefulFuncs';
import { sendMultipartApiRequest } from '../../services/api';
import * as Sentry from '@sentry/react';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { uploadFilesActions } from '../../constants/actionTypes';

export const FILE_UPLOAD_STATUS = {
  uploading: 'up',
  failed: 'failed',
  complete: 'complete',
};

export const useUploadFormFiles = () => {
  const state = useSelector((state) => state.uploadFiles);
  return state.files;
};

const logUploadFile = (file) => {
  TrackingEvent.create('Uploaded Digital Analyst Content', {
    'File Type': file.type,
  });
};

const logSentryError = (response) => {
  const scope = new Sentry.Scope();
  scope.setTag('error', response?.status);
  scope.setTag('message', response?.data?.error);
  Sentry.captureException(new Error('Error uploading document'), scope);
};

export const useFileUploader = () => {
  const dispatch = useDispatch();

  const uploadSingleFile = async (file, index) => {
    let formData = new FormData();
    const { type, name, speakerTag, company, docType } = file;
    const ticker = company?.symbol || null;
    const companyTagId = company?.id || company?.doc_tag_id || null;

    logger('uploading', file, companyTagId);

    const tickerNormalized = ticker?.length === 0 ? null : ticker;
    if (tickerNormalized) {
      formData.append('ticker', tickerNormalized);
    }
    formData.append('name', name);
    if (companyTagId) {
      formData.append('doc_tag_id', companyTagId);
    }
    if (speakerTag && speakerTag.length > 0) {
      formData.append('speaker', speakerTag);
    }
    formData.append(['xls', 'xlsx'].includes(type) ? 'document' : type, file.file);
    if (docType !== null && docType !== '') {
      const formatDocType = (dt) => {
        if (UPLOADED_DOC_TYPES.includes(dt)) {
          return 'Uploaded ' + dt;
        }
        return dt;
      };
      formData.append('doc_type', formatDocType(docType));
    }

    let URL = `v1/genai_process_user_${type}`;
    if (['xls', 'xlsx'].includes(type)) {
      URL = 'v1/genai_process_user_xls';
    }
    if (['pptx', 'ppt', 'doc', 'docx'].includes(type)) {
      URL = 'v1/genai_process_user_ms_docs';
    }
    dispatch({
      type: uploadFilesActions.updateFiles,
      payload: [
        {
          prevName: name,
          changes: {
            status: FILE_UPLOAD_STATUS.uploading,
            error: null,
          },
        },
      ],
    });

    await sleep(100 * index + 1);
    logger(URL, formData);
    logUploadFile(file);

    const response = await sendMultipartApiRequest('post', URL, formData);
    logNetRequest(response?.status, response?.data, response?.error);
    response?.status !== 200 && logSentryError(response);

    dispatch({
      type: uploadFilesActions.updateFiles,
      payload: [
        {
          prevName: name,
          changes: {
            status: response?.status === 200 ? FILE_UPLOAD_STATUS.complete : FILE_UPLOAD_STATUS.failed,
            error: response?.status !== 200 ? response?.data?.error || 'Something went wrong, please try again.' : null,
          },
        },
      ],
    });
  };

  const uploadFiles = React.useCallback((fileOrFiles) => {
    if (Array.isArray(fileOrFiles)) {
      fileOrFiles.forEach((file, index) => {
        uploadSingleFile(file, index);
      });
    } else {
      uploadSingleFile(fileOrFiles, 0);
    }
  }, []);

  return uploadFiles;
};
