import { Header } from '../navigation';
import { Body } from '../layout/Body';
import { Body5, Container, Page } from '../../libs/nvstr-common-ui.es';
import React from 'react';
import styled from 'styled-components';
import BackArrowButton from '../buttons/BackArrowButton';
import { ROUTES } from '../../constants/routes';
import { useNavigate } from 'react-router';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;

export function PageError({ message }) {
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(ROUTES.home);
  };

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={36} bottom={24}>
              <Body5>{message || 'Something went wrong. Please try again.'}</Body5>
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}
