import React from 'react';
import { Body1, Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { Working } from '../../components/UI/Working';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { useFedSentiment, useFedSentimentCSV } from '../../hooks/features/useFedSentiment';
import styled from 'styled-components';
import { Modal } from '../../components/layout/Modal';
import { CloseButton } from '../../components/buttons/CloseButton';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { useHasBetaFeaturesEnabled } from '../../hooks/user/useCurrentUser';
import { SentimentDocumentSummary } from './SentimentDocumentSummary';
import { SentimentAggregations } from './SentimentAggregations';
import { sendApiRequest } from '../../services/api';

const Wrapper = styled.div`
  overflow: hidden;
  padding-top: 36px;
`;
const Heading = styled.div`
  h5 {
    padding-right: 8px;
  }
`;

const SelectedDocumentWrapper = styled.div``;
const IconWrapper = styled.div`
  margin-right: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 8;
`;

function SelectedDocuments() {
  const { docsInAnalysis } = useFedSentiment();

  const [showIncludedDocuments, setShowIncludedDocuments] = React.useState(false);
  const [sortedDocumentList, setSortedDocumentList] = React.useState(null);

  React.useEffect(() => {
    if (docsInAnalysis) {
      const sorted = docsInAnalysis.sort((a, b) => {
        const afilename = a.filename.split('"').join('');
        const bfilename = b.filename.split('"').join('');
        if (afilename < bfilename) {
          return -1;
        }
        if (afilename > bfilename) {
          return 1;
        }
        return 0;
      });
      setSortedDocumentList(sorted);
    }
  }, [docsInAnalysis]);

  if (!docsInAnalysis) return null;

  const displayName = `${docsInAnalysis.length} Selected Speeches/Documents`;

  const onViewIncludedDocs = () => {
    setShowIncludedDocuments(true);
  };
  const onClose = () => {
    setShowIncludedDocuments(false);
  };

  return (
    <SelectedDocumentWrapper>
      {showIncludedDocuments && (
        <Modal onDismiss={onClose} modalProps={{ noPadding: true }}>
          <CloseButtonWrapper>
            <CloseButton onClick={onClose} />
          </CloseButtonWrapper>
          <Container top={32}>
            {sortedDocumentList?.map((d) => (
              <Container key={d.id} bottom={12}>
                <Body5>{d.filename}</Body5>
              </Container>
            ))}
          </Container>
        </Modal>
      )}

      <Container row verticallyCenter>
        <IconWrapper>
          <DocumentIcon />
        </IconWrapper>
        <Container>
          <Body5>{displayName}</Body5>
        </Container>

        <Container>
          <TransparentFlatButton onClick={onViewIncludedDocs}>View Included Speeches/Documents</TransparentFlatButton>
        </Container>
      </Container>
    </SelectedDocumentWrapper>
  );
}

const DownloadCSV = ({ recordIds, docIds, onlyFedSpeakers }) => {
  const data = useFedSentimentCSV();

  const callAPI = () => {
    const URL = 'v1/fed_sentiment_csv_download';
    const form = {
      record_ids: recordIds,
      doc_ids: docIds,
      only_fed_speakers: onlyFedSpeakers,
    };
    sendApiRequest('post', URL, form);
  };

  const downloadCSV = () => {
    const filename = 'sentiment.csv';
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    callAPI();
  };
  return (
    <Container>
      <FlatButton onClick={downloadCSV}>Download CSV</FlatButton>
    </Container>
  );
};

export const SentimentAnalysisResult = ({ onGoBack, recordIds, docIds, onlyFedSpeakers }) => {
  const { isLoading, error } = useFedSentiment();

  const betaPermissions = useHasBetaFeaturesEnabled();

  if (isLoading) {
    return (
      <Page width={'1400px'}>
        <BackArrowButton onClick={onGoBack} />
        <Container vertical={16}>
          <Working />
        </Container>
      </Page>
    );
  }

  if (error) {
    return (
      <Page width={'1400px'}>
        <BackArrowButton onClick={onGoBack} />
        <Container top={36}>
          <Container vertical={16}>
            <Container>
              <Body1 bold>An error occurred analyzing this document(s).</Body1>
            </Container>
            <Container top={16} bottom={48}>
              <Body5>{error}</Body5>
            </Container>
          </Container>
        </Container>
      </Page>
    );
  }
  return (
    <Page width={'1400px'}>
      <BackArrowButton onClick={onGoBack} />
      <Wrapper>
        <div id={'question-answer'}></div>
        <Heading>
          <Container row verticallyCenter spaceBetween>
            <H5>Fed Sentiment</H5>
            {betaPermissions && <DownloadCSV recordIds={recordIds} docIds={docIds} onlyFedSpeakers={onlyFedSpeakers} />}
          </Container>
        </Heading>
        <Container top={12}>
          <SelectedDocuments />
        </Container>

        <Container top={24}>
          <SentimentAggregations />
        </Container>
        <Container top={48}>
          <H5>Document Analysis</H5>
        </Container>
        <Container top={24}>
          <SentimentDocumentSummary />
        </Container>
      </Wrapper>
    </Page>
  );
};
