import { useSelector } from 'react-redux';
import React from 'react';

export const useFavoritePrompt = (prompt) => {
  const favoriteQuestions = useSelector((state) => state.gridTableAnalysis.favoriteQuestions);
  const [fav, setFav] = React.useState(null);

  React.useEffect(() => {
    let found = null;
    favoriteQuestions.forEach((f) => {
      if (f.prompt === prompt.prompt) {
        found = f;
      }
    });
    if (found) {
      if (fav && found.id !== fav.id) {
        setFav(found);
      } else if (fav === null) {
        setFav(found);
      }
    }
    if (!found && fav?.id) {
      setFav(null);
    }
  }, [prompt, favoriteQuestions]);

  return fav;
};
