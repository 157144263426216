import React from 'react';
import { useStreamingResult } from '../../hooks/features/useStreamingResult';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logger, logNetRequest } from '../../utils/usefulFuncs';
import { Working } from '../../components/UI/Working';
import { CitationsModal } from '../../components/modals/CitationsModal';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import { Answer } from '../DigitalAnalyst/components/AnswerLine';
import styled from 'styled-components';

const AnswerWrapper = styled.div`
  margin-top: -16px;
`;
const CellWrapper = styled.td`
  width: ${({ cellWidth }) => cellWidth + 'px'};
`;

export function CellAnswer({ index, tool, cellResultId, answersLookup, cellWidth, showCitations, sourceDocs }) {
  const [shouldGetCitations, setShouldGetCitations] = React.useState(false);
  const [citationsResultId, setCitationsResultId] = React.useState(null);
  const [isGettingCitations, setIsGettingCitations] = React.useState(false);
  const [highlightContextItem, setHighlightContextItem] = React.useState(null);

  const {
    result: citationsStreamingResult,
    context: streamingCitationsContext,
    error: citationsStreamingError,
    streamEnd: citationsStreamEnded,
  } = useStreamingResult(citationsResultId, true);

  const onCitationClick = (chunkId, doc) => {
    logger({ chunkId, doc }, streamingCitationsContext);
    setHighlightContextItem({ chunkId, doc });
  };

  const onCitationDismiss = () => {
    setHighlightContextItem(null);
  };

  React.useEffect(() => {
    if (showCitations && !shouldGetCitations) {
      setShouldGetCitations(true);
    }
  }, [showCitations, shouldGetCitations]);

  React.useEffect(() => {
    const getCitations = async () => {
      const form = {
        record_id: cellResultId,
        user_mode: tool,
      };

      setIsGettingCitations(true);
      const URL = `v1/genai_qa_citation/stream`;
      const { status, data, error } = await sendApiRequest('post', URL, form);
      setIsGettingCitations(false);
      logNetRequest(status, data, error);
      handle400Statuses(status);
      if (status === 200) {
        const { id } = data;
        setCitationsResultId(id);
      } else {
        // setFormError(data?.error || error || 'Something went wrong, try again.');
      }
    };
    if (shouldGetCitations) {
      getCitations();
    }
  }, [shouldGetCitations]);

  if (!answersLookup || !answersLookup[index]) {
    return (
      <CellWrapper className={'response'} cellWidth={cellWidth}>
        <Working noVerticalPadding />
      </CellWrapper>
    );
  }
  if (isGettingCitations) {
    return (
      <CellWrapper className={'response'} cellWidth={cellWidth}>
        <Working noVerticalPadding />
      </CellWrapper>
    );
  }

  const answer = citationsResultId ? citationsStreamingResult : answersLookup[index];
  return (
    <CellWrapper className={'response'} cellWidth={cellWidth}>
      {highlightContextItem !== null && (
        <CitationsModal item={highlightContextItem} context={streamingCitationsContext} onDismiss={onCitationDismiss} />
      )}
      <Body5>
        <AnswerWrapper>
          <Answer
            isShowingCitations={citationsResultId}
            onCitationClick={onCitationClick}
            result={answer}
            sourceDocs={sourceDocs}
          />
        </AnswerWrapper>
      </Body5>
    </CellWrapper>
  );
}
