import React from 'react';
import { CheckCircle } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';

const CheckWrapper = styled.div`
  svg {
    height: ${({ Size }) => Size || '24px'};
    width: ${({ Size }) => Size || '24px'};

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

export default function SuccessCheck({ size }) {
  return (
    <CheckWrapper Size={size}>
      <CheckCircle />
    </CheckWrapper>
  );
}
