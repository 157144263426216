import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { useColorTheme } from '../../../../../hooks';
import { generateCustomInputNodeHandle } from '../../index';

const DEFAULT_HANDLE_STYLE = {};
export default React.memo(({ data, handleActionId, isConnectable }) => {
  const colorTheme = useColorTheme();
  const { name, colorThemeName } = generateCustomInputNodeHandle(handleActionId);

  return (
    <Handle
      type="source"
      id={handleActionId}
      position={Position.Right}
      style={{ ...DEFAULT_HANDLE_STYLE, background: colorTheme[colorThemeName] }}
      isConnectable={isConnectable}
    />
  );
});
