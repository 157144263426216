import React from 'react';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useDocs } from '../../hooks/features/useDocs';
import { useColorTheme } from '../../hooks';
import { DocumentTree } from '../UI/DocumentTree';
import { logNetRequest, removeElementFromArray } from '../../utils/usefulFuncs';
import { sendApiRequest } from '../../services/api';
import { useConfirmationModal } from '../../hooks/application/useConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { liveDocsActions, uploadStatusActions } from '../../constants/actionTypes';
import { useLiveDocs } from '../../hooks/features/useLiveDocs';
import { parseDocGroup, unassignedDocGroupName } from '../../utils/doc';
import styled from 'styled-components';

const RefreshButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  padding-top: 8px;

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

function _groupDocsIntoTickerAndTags(docs) {
  const untagged = [];
  const lookup = {};

  docs.forEach((d) => {
    const docGroup = parseDocGroup(d);
    if (lookup[docGroup]) {
      lookup[docGroup].push(d);
    } else {
      lookup[docGroup] = [d];
    }
  });

  const folderNames = Object.keys(lookup).sort();
  const tagged = {};
  folderNames.forEach((name) => {
    tagged[name] = lookup[name];
  });

  return {
    untagged,
    tagged,
  };
}

function createDocumentTreeOrder(liveDocs) {
  if (!liveDocs) return liveDocs;

  const names = Object.keys(liveDocs);

  const priorityOrder = [];

  if (names.includes(unassignedDocGroupName)) {
    priorityOrder.push(unassignedDocGroupName);
    removeElementFromArray(unassignedDocGroupName, names);
  }

  const sortedRest = names.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return [...priorityOrder, ...sortedRest];
}

const RefreshButton = ({ onClick }) => {
  return (
    <RefreshButtonWrapper onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
      </svg>
      <Container left={12}>
        <Body5 bold>Load Newly Processed Documents</Body5>
      </Container>
    </RefreshButtonWrapper>
  );
};

export function DocumentOrganizer() {
  const dispatch = useDispatch();
  const colorTheme = useColorTheme();

  const onAfterDocsReceived = () => {
    dispatch({
      type: uploadStatusActions.clearNewlyCompletedUploadDocsCount,
    });
  };

  const [filters] = React.useState({ isUserOnlyMode: true, userUploaded: true });
  const { docs, isLoading, fetchDocs } = useDocs(filters, onAfterDocsReceived);
  const liveDocs = useLiveDocs();

  const { newlyCompletedDocsCount } = useSelector((state) => state.uploadStatus);
  const areNewDocsNotLoaded = newlyCompletedDocsCount > 0;

  const documentTreeOrder = React.useMemo(() => {
    return createDocumentTreeOrder(liveDocs);
  }, [liveDocs]);

  React.useEffect(() => {
    if (docs) {
      const { untagged, tagged } = _groupDocsIntoTickerAndTags(docs);
      dispatch({
        type: liveDocsActions.addGroups,
        payload: {
          [unassignedDocGroupName]: untagged,
          ...tagged,
        },
      });
    }
  }, [docs]);

  const handleConfirmDelete = async (doc) => {
    const { id } = doc;
    const groupName = parseDocGroup(doc);
    const URL = `v1/genai_user_doc/${id}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: liveDocsActions.deleteDoc,
        payload: { id, groupName },
      });
    } else {
      // TODO: application error message
    }
  };

  const { ConfirmationModal, onShowModal } = useConfirmationModal(handleConfirmDelete);

  const onDeleteClick = (body, params) => {
    onShowModal(body, params);
  };

  const handleRefreshClick = () => {
    fetchDocs(filters, onAfterDocsReceived);
  };

  if (isLoading) {
    return (
      <Container>
        <Container top={46} bottom={49} row verticallyCenter>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={8}>
            <Body5>Loading Documents...</Body5>
          </Container>
        </Container>
      </Container>
    );
  }

  if (!docs || !liveDocs) {
    return (
      <Container top={46} bottom={49}>
        No files to manage.
      </Container>
    );
  }

  return (
    <Container bottom={24}>
      {ConfirmationModal}
      {areNewDocsNotLoaded ? <RefreshButton onClick={handleRefreshClick} /> : null}
      <Container top={24}>
        {documentTreeOrder.map((name) => {
          const docs = liveDocs[name];
          return <DocumentTree key={name} folderName={name} docs={docs} onDeleteDoc={onDeleteClick} />;
        })}
      </Container>
    </Container>
  );
}
