import React from 'react';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../hooks';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${({ noVerticalPadding }) => (noVerticalPadding ? '0px' : '64px 0')};
`;

export const Loading = ({ noVerticalPadding, message }) => {
  const colorTheme = useColorTheme();

  return (
    <Wrapper noVerticalPadding={noVerticalPadding}>
      <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
      <Container left={12}>
        <Body5>{message || 'Loading...'}</Body5>
      </Container>
    </Wrapper>
  );
};
