import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { getRelativePosition } from 'chart.js/helpers';
import { useColorTheme } from '../../hooks';

const ChartHeader = styled.div`
  padding: 0 0 16px 48px;
`;
const ChartAnalysisGlobalWrapper = styled.div`
  /* Customizes the whole scrollbar (including track and thumb) */

  .chart-wrapper {
    scrollbar-color: #222 #fff;
  }

  .chart-wrapper::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
    height: 12px; /* Height of the horizontal scrollbar */
  }

  /* Customizes the track (part the thumb slides within) */

  .chart-wrapper::-webkit-scrollbar-track {
    background: #fff; /* Color of the track */
  }

  /* Customizes the thumb (the draggable part of the scrollbar) */

  .chart-wrapper::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the thumb */
  }

  /* Changes the thumb color when hovered or clicked */

  .chart-wrapper::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
`;
const ChartAnalysisWrapper = styled.div`
  overflow: auto;
`;
const ChartWrapper = styled.div`
  height: 400px;
  width: ${({ $DatapointsLength }) => (isNaN($DatapointsLength) ? '6000px' : $DatapointsLength * 12 + 'px')};
`;

const buildTooltip = (context) => {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);
    const style = 'padding: 0 16px; margin-top: -8px; color: #fff; line-height: 1.2;';
    let innerHtml = `<div style="${style}">`;
    titleLines.forEach(function (title) {
      innerHtml += title;
    });
    innerHtml += '<div>';
    bodyLines.forEach(function (body, i) {});
    innerHtml += '</tbody>';

    let tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.background = 'rgba(0,0,0,.84)';
  tooltipEl.style.borderRadius = '5px';
  tooltipEl.style.fontSize = '13px';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.padding = '16px 8px';
  tooltipEl.style.pointerEvents = 'none';
};

const getClosestDatapoint = (chart, event) => {
  try {
    const BUFFER = 5;
    const canvasPosition = getRelativePosition(event, chart);
    const xAxis = chart.scales.x;
    const datapoints = xAxis.ticks;

    let statementIndex = null;
    let i = 0;
    while (i < datapoints.length && statementIndex === null) {
      const tick = datapoints[i];

      const tickPixel = xAxis.getPixelForTick(i);
      if (Math.abs(canvasPosition.x - tickPixel) < BUFFER) {
        statementIndex = tick.value;
      }
      i++;
    }

    return statementIndex;
  } catch (e) {
    return null;
  }
};

export const SentimentChart = ({ data, onHighlightStatement }) => {
  const colorTheme = useColorTheme();

  const generateOptions = (data) => {
    return {
      responsive: true,
      maintainAspectRatio: false,

      elements: {
        point: {
          radius: 3,
          hoverRadius: 10,
          pointStyle: 'circle',
        },
        line: {
          borderWidth: 3,
        },
      },
      layout: {
        padding: {
          top: 8,
          bottom: 8,
          left: 8,
          right: 8,
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: buildTooltip,
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          min: -1,
          max: 1,
          ticks: {
            color: colorTheme.text,
            stepSize: 0.2,
          },
          title: {
            display: true,
            text: 'Dovish - Neutral - Hawkish',
          },
          grid: {
            color: (context) => (context.tick.major ? colorTheme.lowContrastBorder : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
        },
        x: {
          grid: {
            tickWidth: 1,
            lineWidth: 1,
            color: (context) => (context.tick.major ? colorTheme.lowContrastBorder : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
            color: colorTheme.text,
            font: {
              family: 'Bolton, Arial',
              size: 10,
              color: colorTheme.text,
            },
            autoSkip: false,
          },
        },
      },
    };
  };

  const chartRef = React.useRef();
  const [options, setOptions] = React.useState(generateOptions(data));

  React.useEffect(() => {
    setOptions(generateOptions(data));
  }, [data]);

  const chartColor = colorTheme.text;

  const chartData = {
    labels: data?.labels,
    datasets: [
      {
        label: 'Statement Sentiment Over Time',
        data: data.datapoints,
        fill: false,
        backgroundColor: chartColor,
        borderColor: convertHexToRGBA(chartColor, 1),
        tension: 0.1,
      },
    ],
  };

  const onClick = (event) => {
    if (!onHighlightStatement) return;
    const chart = chartRef.current;
    const value = getClosestDatapoint(chart, event);
    onHighlightStatement(value);
  };

  return (
    <ChartAnalysisGlobalWrapper>
      <ChartAnalysisWrapper className={'chart-wrapper'}>
        <ChartWrapper $DatapointsLength={data.datapoints.length}>
          <Line ref={chartRef} data={chartData} options={options} onClick={onClick} />
        </ChartWrapper>
      </ChartAnalysisWrapper>
    </ChartAnalysisGlobalWrapper>
  );
};
