import styled from 'styled-components';
import { Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import React from 'react';
import { AI_TOOLS, DOC_SELECT_MODES } from '../../constants';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { FormState } from '../../components/UI/FormState';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { PROMPT_SERIES_MODES, usePromptSeries } from '../../hooks/features/usePromptSeries';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { NewDocumentSelectionModal } from './components/DocumentSelectionModal';
import { filterDocDuplicates } from '../../reducers/docs';
import { includes } from '../../utils/usefulFuncs';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { CloseButton } from '../../components/buttons/CloseButton';
import { GrowthEquityAnalysisResult } from './components/GrowthEquityAnalysisResult';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;
const SelectedDocumentsList = styled.div``;
const SelectedDocumentWrapper = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr 22px;
  width: max-content;
  grid-column-gap: 12px;
  margin-bottom: 8px;

  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 4px;

  padding: 4px 8px;
`;
const DocumentIconWrapper = styled.div`
  margin-top: 3px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CloseIconWrapper = styled.div`
  svg {
    height: 13px;
    width: 13px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const advancedSettingsFields = [
  // AdvancedSettingsFields.seeContext,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.smoothingToggle,
  AdvancedSettingsFields.includeUserDocsToggle,
  AdvancedSettingsFields.maxContextChunks,
];

const tool = AI_TOOLS.GROWTH_EQUITY_ANALYSIS;
const docSelectModes = [DOC_SELECT_MODES.companyTag, DOC_SELECT_MODES.docType];

const SelectedDocument = ({ doc, onRemove }) => {
  return (
    <SelectedDocumentWrapper>
      <DocumentIconWrapper>
        <DocumentIcon />
      </DocumentIconWrapper>
      <Container top={2}>
        <Body5>{doc.filename}</Body5>
      </Container>
      <CloseIconWrapper>
        <CloseButton onClick={onRemove} size={14} />
      </CloseIconWrapper>
    </SelectedDocumentWrapper>
  );
};

export default function GrowthEquityAnalysisTool({ onGoBack }) {
  const [docs, setDocs] = React.useState([]);
  const [showDocumentSelectModal, setShowDocumentSelectModal] = React.useState(false);

  const { resultId, resultIdTicker, isSubmittingToApi, formError, onSubmit, onClear, questionList } =
    usePromptSeries(tool);

  React.useEffect(() => {
    if (resultId) {
      const id = 'snapshot-answers-section';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [resultId]);

  const onGetResults = () => {
    onSubmit(
      null,
      PROMPT_SERIES_MODES.growthEquityAnalysis,
      docs.map((d) => d.id)
    );
  };

  const onSelectDocumentsClick = () => {
    setShowDocumentSelectModal(true);
  };

  const onDismissDocSelection = () => {
    setShowDocumentSelectModal(false);
  };

  const handleAddDocument = (v) => {
    if (Array.isArray(v)) {
      const selectedDocs = [...docs, ...v];
      setDocs(filterDocDuplicates(selectedDocs));
    } else {
      const selectedDocs = [...docs, v];
      setDocs(filterDocDuplicates(selectedDocs));
    }
  };

  const handleRemoveDocument = (filenameOrFilenames) => {
    if (Array.isArray(filenameOrFilenames)) {
      setDocs(docs.filter((d) => !includes(filenameOrFilenames, d.filename)));
    } else {
      setDocs(docs.filter((d) => d.filename !== filenameOrFilenames.filename));
    }
  };

  return (
    <PageWrapper>
      {showDocumentSelectModal && (
        <NewDocumentSelectionModal
          docSelectModes={docSelectModes}
          defaultActiveMode={docSelectModes[0]}
          selectedDocs={docs}
          handleAddDocument={handleAddDocument}
          handleRemoveDocument={handleRemoveDocument}
          onDismiss={onDismissDocSelection}
        />
      )}
      <Page width={resultId ? '1400px' : '800px'}>
        <BackArrowButton onClick={onGoBack} />
        {isSubmittingToApi ? (
          <Container top={80} bottom={80} centerAll>
            <Container>
              <Container centerAll>
                <Spinner />
              </Container>
              <Container top={24} left={12}>
                <Body5>Working...</Body5>
              </Container>
            </Container>
          </Container>
        ) : (
          <Container top={40}>
            <FormWrapper>
              <AdvancedSettingsButton fields={advancedSettingsFields} />
              <Container>
                <H5>Growth Equity Analysis</H5>
                <Container top={4}>
                  <Body5>Select the documents to be used for the report.</Body5>
                </Container>
              </Container>
              <Container top={24}>
                <FlatButton onClick={onSelectDocumentsClick}>+ Select Documents</FlatButton>
              </Container>
              <Container top={16}>
                <SelectedDocumentsList>
                  {docs.map((d) => (
                    <SelectedDocument key={d.id} doc={d} handleRemoveClick={handleRemoveDocument} />
                  ))}
                </SelectedDocumentsList>
              </Container>
              <Container top={48}>
                <Container centerAll>
                  <FormState error={formError} />
                </Container>
                <SubmitButtonWrapper>
                  <FlatButton disabled={docs.length === 0} onClick={onGetResults} fullWidth>
                    Get Analysis
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </FormWrapper>
          </Container>
        )}
      </Page>
      {resultId && (
        <GrowthEquityAnalysisResult resultId={resultId} docIds={docs.map((d) => d.id)} questionList={questionList} />
      )}
    </PageWrapper>
  );
}
