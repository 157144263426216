import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { Body1, Body5, Container, H5, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { createTimeInstance } from '../libs/nvstr-utils.es';
import { useColorTheme } from '../hooks';
import { docTypes } from '../constants';
import { LatestDocsList } from '../containers/DigitalAnalyst/DocumentList';
import { useLatestEarningsDocs } from '../hooks/features/useLatestEarningsDocs';
import { scrollToTop } from '../utils/usefulFuncs';
import { createRows } from '../utils';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { AdvancedSettingsFields, useAdvancedSettings } from '../hooks/application/useAdvancedSettings';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { useFilteredDocs } from '../hooks/features/useDocs';
import { GRID_MODES } from '../containers/gridMode';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SideBySide = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
`;

const defaultStartDate = createTimeInstance().subtract(10, 'days');

const advancedSettingsFields = [AdvancedSettingsFields.includeUserDocsToggle, AdvancedSettingsFields.maxContextChunks];

const defaultAdvancedSettings = {
  includeUserDocs: true,
};

const createUserDocRow = (userDocs) => {
  if (userDocs?.length === 0) return [];
  return [
    {
      day: 'Uploaded',
      docs: userDocs,
    },
  ];
};

function EarningsCallSummaries() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colorTheme = useColorTheme();

  const [wasError] = React.useState(false);
  const [marketCap] = React.useState(null);
  const [filterDocTypes] = React.useState(docTypes.EARNINGS);
  const [startDate] = React.useState(defaultStartDate);
  const [filters] = React.useState({ docMode: GRID_MODES.earnings });

  const [rows, setRows] = React.useState([]);

  const { includeUserDocs } = useAdvancedSettings();

  const {
    latestDocDays,
    latestDocsByDayLookup,
    isLoading: isLoadingDocs,
    getTickerDocData,
  } = useLatestEarningsDocs(marketCap);
  const { docs: userDocs } = useFilteredDocs(filters);
  const userDocsRows = createUserDocRow(userDocs);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  React.useEffect(() => {
    scrollToTop();

    getTickerDocData({
      startDate,
      marketCap,
      docTypes: docTypes.EARNINGS,
    });
  }, [marketCap]);

  React.useEffect(() => {
    if (Array.isArray(latestDocDays)) {
      setRows(createRows(latestDocDays, latestDocsByDayLookup, filterDocTypes));
    }
  }, [latestDocDays, latestDocsByDayLookup, filterDocTypes]);

  const onGoBack = () => navigate(ROUTES.home);

  if (isLoadingDocs) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={40} bottom={80}>
              <Container centerAll>
                <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
                <Container left={16}>
                  <Body1>Loading Recent Earnings Transcripts</Body1>
                </Container>
              </Container>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  if (wasError) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={40} bottom={144}>
              <Body5>Something went wrong, please try again later.</Body5>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <SideBySide>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <AdvancedSettingsButton fields={advancedSettingsFields} />
            <Container top={40}>
              <H5>Latest Earnings Calls</H5>
              <Container row top={8}>
                {isLoadingDocs && (
                  <Container left={16}>
                    <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
                  </Container>
                )}
              </Container>
            </Container>
            {includeUserDocs ? (
              userDocs.length > 0 ? (
                <Container>
                  <LatestDocsList rows={userDocsRows} enableSummaryMode />
                </Container>
              ) : (
                <Container top={8} bottom={24}>
                  <Body5>Earnings Calls you have uploaded will show here</Body5>
                </Container>
              )
            ) : null}
            {rows?.length === 0 ? (
              <Container bottom={24}>
                <Body5>No recent earnings calls are available at this time. Check back later.</Body5>
              </Container>
            ) : (
              <LatestDocsList rows={rows} enableSummaryMode />
            )}
          </Page>
        </SideBySide>
      </Body>
    </PageWrapper>
  );
}

export default EarningsCallSummaries;
