import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

const blankArray = [];

export function detectPlugins(result) {
  if (!result) return blankArray;

  const plugins = [];

  const hasTable = result.indexOf('|') > -1;
  const hasStrike = result.indexOf('~~') > -1;
  if (hasTable || hasStrike) {
    plugins.push(PLUGINS.gfm);
  }

  const hasHTMLMarkdown = result.indexOf('<b') > -1;
  if (hasHTMLMarkdown) {
    plugins.push(PLUGINS.html);
  }
  const hasCodeSnip = result.indexOf('<code') > -1;
  if (hasCodeSnip) {
    plugins.push(PLUGINS.codeSyntax);
  }

  // const hasMathSnip = result.indexOf('$') > -1;
  // if (hasMathSnip) {
  // }

  const hasKatexSnip = result.indexOf('$$') > -1 || result.indexOf('\\frac') > -1 || result.indexOf('\\[') > -1;
  if (hasKatexSnip) {
    plugins.push(PLUGINS.math);
    plugins.push(PLUGINS.katex);
  }
  return plugins;
}

const PLUGINS = {
  codeSyntax: 'rehypeHighlight',
  gfm: 'remarkGfm',
  html: 'rehypeRaw',
  math: 'remarkMath',
  katex: 'rehypeKatex',
};
const pluginLookup = {
  [PLUGINS.codeSyntax]: rehypeHighlight,
  [PLUGINS.gfm]: remarkGfm,
  [PLUGINS.html]: rehypeRaw,
  [PLUGINS.math]: remarkMath,
  [PLUGINS.katex]: rehypeKatex,
};

export function samePlugins(p1, p2) {
  if (p1.length !== p2.length) return false;
  return true;
}

export function convertPluginNamesToFunc(p) {
  return p.map((n) => pluginLookup[n]);
}
