import React from 'react';
import styled from 'styled-components';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { useSentimentRecords } from '../../hooks/features/useSentimentRecords';
import { isUndefinedOrNull, logNetRequest } from '../../utils/usefulFuncs';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { fedSentimentActions } from '../../constants/actionTypes';
import { buildStoreDataFromResponse } from '../../utils/dataFormatters/sentiment';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }
`;
const Heading = styled.div`
  padding: 36px 0 0 0;

  h5 {
    padding-right: 8px;
  }
`;
const ActionWrapper = styled.div`
  text-align: center;
  padding: 36px 0 0 0;
`;

const buildDisplayName = (doc) => {
  const docCount = doc.doc_ids.length;
  return docCount > 1 ? `${docCount} Documents/Speeches` : `${doc.filename}`;
};

function SentimentDocSelectTool({ onGoBack, onDocSelectComplete }) {
  const dispatch = useDispatch();
  const [selectedDoc, setSelectedDoc] = React.useState('');
  const [formError, setFormError] = React.useState(null);

  const { data: selectableDocuments, error: recordsError } = useSentimentRecords();

  const onChangeDocument = (e) => {
    const value = e.target.value;
    if (isUndefinedOrNull(value)) {
      return null;
    }
    setSelectedDoc(value || '');
  };

  const onGetAnalysis = async () => {
    if (selectedDoc === '') {
      setFormError('Please select a document in the document selection dropdown');
      return;
    }

    const selected = selectableDocuments.filter((d) => d.record_id == selectedDoc)[0];
    if (!selected) {
      return;
    }

    setFormError(null);
    dispatch({
      type: fedSentimentActions.setIsGetting,
    });

    const onlyFed = 'true';
    const { record_id: id, doc_ids } = selected;
    onDocSelectComplete({
      recordIds: id,
      docIds: doc_ids,
      onlyFed,
    });

    const URL = `v1/genai_fed_sentiment_get?record_ids=${id}&doc_ids=${doc_ids}&only_fed_speakers=${onlyFed}`;
    const { status, data, error } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    logNetRequest(URL, status, data);
    if (status === 200) {
      const payload = await buildStoreDataFromResponse(data, false);
      dispatch({
        type: fedSentimentActions.setResults,
        payload: {
          ...payload,
        },
      });
    } else {
      dispatch({
        type: fedSentimentActions.setError,
        payload: data?.error || error || 'Something went wrong, try again.',
      });
    }
  };

  return (
    <Wrapper>
      <Page width={'1400px'}>
        <BackArrowButton onClick={onGoBack} />
        <Heading>
          <H5>Fed Sentiment</H5>
          <Container top={8}>
            <Body5>
              Analyze speaker sentiment in any of the available Fed documents. Scores from dovish, neutral, hawkish are
              shown from sentence to sentence, along with a averaged time-series across each document/speech.
            </Body5>
          </Container>
        </Heading>
        <Container top={24} bottom={24}>
          <select value={selectedDoc?.record_id} onChange={onChangeDocument}>
            <option value={''}>Please select...</option>
            {selectableDocuments?.map((doc) => (
              <option key={doc.record_id} value={doc.record_id}>
                {buildDisplayName(doc)}
              </option>
            ))}
          </select>
          <ActionWrapper>
            {formError && (
              <Container bottom={16}>
                <Body5 error>{formError}</Body5>
              </Container>
            )}
            <FlatButton fullWidth onClick={onGetAnalysis}>
              Analyze Sentiment
            </FlatButton>
          </ActionWrapper>
        </Container>
      </Page>
    </Wrapper>
  );
}

export default SentimentDocSelectTool;
