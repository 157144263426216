import React from 'react';
import styled from 'styled-components';
import { DocumentSelection } from '../DocumentSelection';

const Wrapper = styled.div``;

const DocumentSelectionWrapper = styled.div`
  padding: 36px 0 0 0;
`;

export const CustomFedDocumentSelection = ({ availableDocs, selectedDocs, onSelect, onRemoveSelect }) => {
  return (
    <Wrapper>
      <DocumentSelectionWrapper>
        <DocumentSelection
          docs={availableDocs}
          onSelect={onSelect}
          onRemoveSelect={onRemoveSelect}
          selectedDocs={selectedDocs}
          callWithEntireDoc
        />
      </DocumentSelectionWrapper>
    </Wrapper>
  );
};
