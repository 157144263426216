import { useDispatch } from 'react-redux';
import { useAdvancedSettings } from '../../hooks/application/useAdvancedSettings';
import React from 'react';
import { useNewCompanySnapshotStreamingResult } from '../../hooks/features/useNewCompanySnapshotStreamingResult';
import { addOptionalField } from '../../utils/application';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { gridTableAnalysisActions } from '../../constants/actionTypes';
import { AddPromptModal } from '../TableAnalysis/tableComponents/AddPromptRow';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { IconButton } from '../../components/buttons/IconButton';
import { Edit } from '../../assets/icons/svgs/Edit';
import { Star } from '../../assets/icons/svgs/Star';
import { CitationIcon } from '../../assets/icons/svgs/CitationIcon';
import { Expand } from '../../assets/icons/svgs/Expand';
import { Collapse } from '../../assets/icons/svgs/Collapse';
import { CloseButton } from '../../components/buttons/CloseButton';
import { TransparentFlatButton } from '../../components/buttons';
import styled from 'styled-components';
import { useFavoritePrompt } from '../../hooks/features/useFavoritePrompt';
import { CellAnswer } from './CellAnswer';

function _createNullArray(length) {
  return Array.from({ length }, () => null);
}

const TableRowWrapper = styled.tr``;
const CellActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  padding-bottom: 12px;
`;
const CellWrapper = styled.td`
  width: ${({ cellWidth }) => cellWidth + 'px'};
`;

export function SeriesTableRow({ index, data, cellWidth, tool }) {
  const dispatch = useDispatch();
  const { model, maxContextLength, maxAnswerLength, maxContextChunks, seed, temperature, topP } = useAdvancedSettings();

  const { prompt, documents, cellCount } = data;

  const [sourceDocCells] = React.useState(documents.filter((d) => d !== null));

  const favoriteQuestionData = useFavoritePrompt(prompt);
  const [emptyCells, setEmptyCells] = React.useState(_createNullArray(cellCount));
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [resultId, setResultId] = React.useState(null);
  const [cells, setCells] = React.useState(null);

  const [queueGetCitations, setQueueGetCitations] = React.useState(false);
  const [shouldGetCitations, setShouldGetCitations] = React.useState(false);

  const { answersLookup, error: streamError, streamEnd } = useNewCompanySnapshotStreamingResult(resultId);
  const promptCheck =
    favoriteQuestionData === null ? true : !favoriteQuestionData.is_default && favoriteQuestionData.is_my_prompt;
  const showFavButton = !prompt.block_editting && promptCheck;
  const isFavorite = favoriteQuestionData?.id > 0;
  const displayPrompt = prompt.block_editting ? prompt.display_label : prompt.display_label || prompt.prompt;

  const init = async () => {
    const docIdLists = documents.filter((d) => d !== null).map((r) => r.map((d) => d.id));
    const URL = `v1/genai_query_grid`;
    let form = {
      prompts: [prompt],
      doc_id_lists: docIdLists,
      user_mode: tool,
      model,
      max_answer_tokens: maxAnswerLength || null,
      max_context_tokens: isNaN(maxContextLength) ? 32000 : parseInt(maxContextLength, 10),
    };
    addOptionalField('max_context_chunks', maxContextChunks, form);
    addOptionalField('seed', seed, form);
    addOptionalField('temperature', temperature, form, true);
    addOptionalField('top_p', topP, form, true);
    const { status, data, error } = await sendApiRequest('post', URL, form);
    handle400Statuses(status);
    logNetRequest(URL, status, data);

    if (status === 200) {
      const row = data.table[0];
      setCells(row.cells);
      setResultId(data.result_id);
    } else {
      setError('Something went wrong, please try again.');
    }
  };

  React.useEffect(() => {
    if (queueGetCitations && streamEnd && !shouldGetCitations) {
      setShouldGetCitations(true);
    }
  }, [queueGetCitations, shouldGetCitations, streamEnd]);

  React.useEffect(() => {
    setTimeout(() => {
      init();
    }, index * 100 || 1);

    return () => {
      setCells(null);
      setResultId(null);
    };
  }, [prompt]);

  const onRetry = () => {
    setError(null);
    setCells(null);
    setResultId(null);
    init();
  };

  React.useEffect(() => {
    if (cellCount !== emptyCells.length) {
      setEmptyCells(_createNullArray(cellCount));
    }
  }, [cellCount]);

  const handleCollapse = () => {
    setIsCollapsed(true);
  };
  const handleExpand = () => {
    setIsCollapsed(false);
  };
  const handleDeleteRow = () => {
    dispatch({
      type: gridTableAnalysisActions.deleteTableRow,
      payload: {
        index,
      },
    });
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleFav = async () => {
    const form = {
      ...prompt,
      display_label: prompt.display_label || prompt.prompt,
      user_mode: tool,
      user_modes: [tool],
    };
    const URL = `v1/genai_favorite_prompts`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.addFavoriteQuestion,
        payload: data,
      });
    }
  };
  const handleUnFav = async () => {
    const id = favoriteQuestionData.id;
    const URL = `v1/genai_favorite_prompts/${id}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.removeFavoriteQuestion,
        payload: id,
      });
    }
  };

  const handleGetCitations = () => {
    setQueueGetCitations(true);
  };

  const defaultValues = {
    text: prompt.prompt,
    is_diff_mode: prompt.is_diff_mode,
  };

  return (
    <TableRowWrapper>
      <CellWrapper className={'question'} cellWidth={cellWidth}>
        {isEditing && (
          <AddPromptModal
            onDismiss={() => setIsEditing(false)}
            isEditMode
            defaultValues={defaultValues}
            favId={favoriteQuestionData?.id || null}
          />
        )}
        <CellActionsWrapper>
          {prompt.block_editting ? null : (
            <Container>
              <IconButton onClick={handleEditClick}>
                <Edit size={17} />
              </IconButton>
            </Container>
          )}
          {showFavButton ? (
            <IconButton onClick={isFavorite ? handleUnFav : handleFav}>
              <Star size={20} filled={isFavorite} />
            </IconButton>
          ) : null}

          {queueGetCitations ? null : (
            <IconButton onClick={handleGetCitations}>
              <CitationIcon size={17} />
            </IconButton>
          )}

          <IconButton onClick={isCollapsed ? handleExpand : handleCollapse}>
            {isCollapsed ? <Expand size={19} /> : <Collapse size={20} />}
          </IconButton>
          <Container>
            <CloseButton size={15} onClick={handleDeleteRow} />
          </Container>
        </CellActionsWrapper>
        <Body5>{displayPrompt}</Body5>
      </CellWrapper>
      {(cells || emptyCells).map((a, i) =>
        error || streamError ? (
          <CellWrapper key={`${i}-error`} cellWidth={cellWidth}>
            <Container>
              <Body5>{error || streamError}</Body5>
            </Container>
            <Container top={16}>
              <TransparentFlatButton onClick={onRetry}>Reload</TransparentFlatButton>
            </Container>
          </CellWrapper>
        ) : isCollapsed ? (
          <CellWrapper key={`${i}-collapsed`} cellWidth={cellWidth}></CellWrapper>
        ) : (
          <CellAnswer
            key={i}
            tool={tool}
            cellResultId={cells ? cells[i]?.result_id || null : null}
            answersLookup={answersLookup}
            index={a?.answer_index}
            cellWidth={cellWidth}
            showCitations={shouldGetCitations}
            sourceDocs={sourceDocCells[i]}
          />
        )
      )}
    </TableRowWrapper>
  );
}
