import React from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { Body5 } from '../../../libs/nvstr-common-ui.es';
import { FOLLOW_UP_QUESTION_IDENTIFIER } from '../../../constants';
import { MoreDetailEnhanced } from './MoreDetail';
import { Working } from '../../../components/UI/Working';
import { convertPluginNamesToFunc, detectPlugins, samePlugins } from '../../../utils/plugins';
import { AddingCitations, Citation } from './Citation';
import { logger } from '../../../utils/usefulFuncs';
import { parseJSONResultCompanyList } from '../../../utils/result';

const MarkdownStyling = styled.span`
  pre {
    background-color: ${({ theme }) => theme.themeColors.lowContrastBorder};
    border-left: 3px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.text};
    border-radius: 2px;

    margin-bottom: 1.6em;
    padding: 1em 1.5em;

    overflow: scroll;
    overflow-x: scroll;
    page-break-inside: avoid;

    * {
      line-height: 1.6;
    }
  }

  code {
    padding: 0 0.2em;

    .comment {
      /* Gray */
      color: #708090;
    }

    .string {
      /* Green */
      color: #0f9d58;
    }

    .keyword {
      /* Blue */
      color: #0086b3;
    }

    .function {
      /* Orange */
      color: #f08c00;
    }
  }

  td,
  th {
    padding: 8px;
  }

  th {
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }

  td {
    border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  }

  ol,
  ul {
    padding-top: 8px;
    padding-bottom: 4px;
  }

  li {
    min-height: 28px;
    padding: 0 0 0 2px;
    margin-bottom: 12px;

    > span:first-child {
      display: block;
    }

    p {
      margin: 0;
    }
  }
`;

function removeFollowUpQuestions(result) {
  try {
    if (!result) return result;
    return result.split(FOLLOW_UP_QUESTION_IDENTIFIER)[0];
  } catch (e) {
    logger('could not remove follow-up questions', e, result);
    return result;
  }
}

// Function to escape dollar signs in currency amounts, but leave LaTeX math intact
const escapeDollarSigns = (markdownText) => {
  return markdownText.replace(/(\$\d+(\.\d+)?)/g, '\\$&');
};

function formatResult(result) {
  const v = removeFollowUpQuestions(result);
  return escapeDollarSigns(v);
}

export const ResultOnly = ({
  result,
  isShowingCitations,
  onCitationClick,
  enableMoreDetail,
  sourceDocs,
  selectedDocs,
  model,
  userMode,
  isComplete,
}) => {
  const [plugins, setPlugins] = React.useState([]);

  React.useEffect(() => {
    if (result) {
      const formatted = formatResult(result);
      const detectedPlugins = detectPlugins(formatted);

      if (!samePlugins(detectedPlugins, plugins)) {
        logger('setting plugins', detectedPlugins);
        setPlugins(detectedPlugins);
      }
    }
  }, [plugins, result]);

  if (!result) return null;

  const formattedResult = formatResult(result);

  return (
    <MarkdownStyling>
      <Body5>
        <Markdown
          rehypePlugins={convertPluginNamesToFunc(plugins)}
          components={{
            li(props) {
              const { node, ...rest } = props;

              if (enableMoreDetail) {
                return (
                  <MoreDetailEnhanced
                    {...rest}
                    selectedDocs={selectedDocs}
                    model={model}
                    userMode={userMode}
                    isBullet
                  />
                );
              }
              return <li {...rest} />;
            },
            a(props) {
              const { node, ...rest } = props;
              if (rest.href === 'https://example.com/citation') {
                return (
                  <Citation
                    isShowingCitations={isShowingCitations}
                    data={rest.children}
                    onClick={onCitationClick}
                    sourceDocs={sourceDocs}
                  />
                );
              }
              return null;
            },
          }}
        >
          {formattedResult}
        </Markdown>
      </Body5>
    </MarkdownStyling>
  );
};

export const JSONAnswer = ({ result, renderer }) => {
  const [prevValidResult, setPrevValidResult] = React.useState(null);

  React.useEffect(() => {
    const list = parseJSONResultCompanyList(result);
    if (list) {
      setPrevValidResult(list);
    }
  }, [result]);

  if (prevValidResult === null) {
    return <Working />;
  }

  return renderer(prevValidResult);
};

export const Answer = ({
  result,
  isAddingCitations,
  isShowingCitations,
  onCitationClick,
  enableMoreDetail,
  selectedDocs,
  sourceDocs,
  model,
  userMode,
  isComplete,
}) => {
  if (result === 'Working...' || !result || result === '') {
    return <Working />;
  }
  return (
    <>
      {isAddingCitations && <AddingCitations />}

      <ResultOnly
        result={result}
        isShowingCitations={isShowingCitations}
        onCitationClick={onCitationClick}
        enableMoreDetail={enableMoreDetail}
        selectedDocs={selectedDocs}
        sourceDocs={sourceDocs}
        model={model}
        userMode={userMode}
        isComplete={isComplete}
      />
    </>
  );
};
