import React from 'react';
import styled from 'styled-components';
import { Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL, DOC_SELECT_MODES, FOLLOW_UP_QUESTION_IDENTIFIER } from '../../constants';
import { sleep } from '../../libs/nvstr-utils.es';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { QuestionResult } from './components/QuestionResult';
import { SelectedDocuments } from './components/SelectedDocuments';
import { FormState } from '../../components/UI/FormState';
import { filterDocDuplicates } from '../../reducers/docs';
import InfoIconTooltip from '../../components/UI/InfoIconTooltip';
import { InfoIconTypes } from '../../constants/infoIcons';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { AdvancedSettingsFields, useAdvancedSettings } from '../../hooks/application/useAdvancedSettings';
import { applicationActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { useAskDigitalAnalyst } from '../../hooks/features/useAskDigitalAnalyst';
import { useDocs } from '../../hooks/features/useDocs';
import { includes, logError } from '../../utils/usefulFuncs';
import { TickerSelect } from './components/TickerSelect';
import { DocumentSelectionModal } from './components/DocumentSelectionModal';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';
import { useStreamingResult } from '../../hooks/features/useStreamingResult';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { PrefillQuestionModal } from './components/PrefillQuestionModal';
import { showErrorBanner } from '../../utils/application';

const defaultAdvancedSettingsFields = [
  AdvancedSettingsFields.seeContext,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.maxAnswerLength,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.includeInferredMetadata,
  AdvancedSettingsFields.followUpQuestionsToggle,
  AdvancedSettingsFields.maxContextChunks,
  AdvancedSettingsFields.enableMath,
  AdvancedSettingsFields.temperature,
  AdvancedSettingsFields.top_p,
];

const defaultAdvancedSettings = {
  enableFollowUpQuestions: true,
  enableMath: true,
  seeContext: true,
  includeInferredMetadata: true,
  model: CURRENT_DEFAULT_MODEL,
  maxContextLength: '',
  maxAnswerLength: '',
  temperature: '',
  topP: '',
};

function filterDuplicates(documents) {
  const uniqueDocuments = [];
  const ids = new Set();

  documents.forEach((doc) => {
    if (!ids.has(doc.id)) {
      ids.add(doc.id);
      uniqueDocuments.push(doc);
    }
  });

  return uniqueDocuments;
}

const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;
const Label = styled.div`
  padding-bottom: 8px;
`;
const FirstActionsRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 0 16px 0;
`;
const ActionsWrapper = styled.div`
  @media (max-width: 440px) {
    button {
      padding: 12px 12px !important;
    }
  }
`;
const Heading = styled.div`
  padding: 36px 0 0 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  h5 {
    padding-right: 8px;
  }
`;

function selectDocSelectModes({ isCompanyTaggingEnabled, isQueryMode, isFedMode, isWebMode, isFedGpt }) {
  if (isQueryMode) {
    const modes = [DOC_SELECT_MODES.docType];
    if (isCompanyTaggingEnabled) {
      modes.push(DOC_SELECT_MODES.companyTag);
    }
    return modes;
  }
  if (isFedMode || isFedGpt) {
    return [DOC_SELECT_MODES.docType, DOC_SELECT_MODES.speaker];
  }
  if (isWebMode) {
    return [DOC_SELECT_MODES.docType];
  }
  return [DOC_SELECT_MODES.docType];
}

const AddCitationsButton = ({ resultId, model, handleAskNewQuestion }) => {
  const [queueCitations, setQueueCitations] = React.useState(false);
  const [requestedCitations, setRequestedCitations] = React.useState(false);
  // const { result: answer, fullContext, streamEnd } = useStreamingResult(resultId);

  React.useEffect(() => {
    setRequestedCitations(false);
    setQueueCitations(false);
  }, [resultId]);

  const hc = () => {
    setRequestedCitations(true);

    const forceFollowUpQuestionOff = true;
    const isCitationsMode = true;
    handleAskNewQuestion('question in api', model, forceFollowUpQuestionOff, null, null, isCitationsMode, resultId);
  };

  if (requestedCitations) {
    return <TransparentFlatButton onClick={hc}>Re-run Citations</TransparentFlatButton>;
  }

  if (queueCitations) {
    return (
      <FlatButton disabled onClick={() => null}>
        Queued Citations
      </FlatButton>
    );
  }

  return <FlatButton onClick={hc}>Add Citations</FlatButton>;
};
const SearchForMoreDetailButton = ({ resultId, question, model, handleAskNewQuestion }) => {
  const { result: answer } = useStreamingResult(resultId);

  const handleSearchMoreDetail = () => {
    const newQuestion = `Improve this answer by including more details, previous question was: "${question}"; previous answer was: "${
      answer.split(FOLLOW_UP_QUESTION_IDENTIFIER)[0] || ''
    }"`;
    const forceFollowUpQuestionOff = true;
    const forceQuestionAsked = `Improve the answer to the question: ${question}`;
    handleAskNewQuestion(newQuestion, model, forceFollowUpQuestionOff, forceQuestionAsked);
  };

  return <FlatButton onClick={handleSearchMoreDetail}>Search for more detail</FlatButton>;
};
const ActionsComponent = ({ resultId, question, model, handleReviseQuestion, handleAskNewQuestion }) => {
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isCitationsEnabled = toolsAvailable.includes(AI_TOOLS.CITATIONS);

  return (
    <ActionsWrapper>
      <FirstActionsRowWrapper>
        <SearchForMoreDetailButton
          resultId={resultId}
          question={question}
          model={model}
          handleAskNewQuestion={handleAskNewQuestion}
        />

        {isCitationsEnabled && (
          <AddCitationsButton
            resultId={resultId}
            question={question}
            model={model}
            handleAskNewQuestion={handleAskNewQuestion}
          />
        )}
      </FirstActionsRowWrapper>
      <Container>
        <SkeletonButton onClick={(e) => handleReviseQuestion()}>Revise Question</SkeletonButton>
      </Container>
    </ActionsWrapper>
  );
};

export default function NewQueryTool({
  preSelectedDocs,
  preSelectedTickers,
  onGoBack,
  isResearchMode,
  isQueryMode,
  isFedMode,
  isWebMode,
  isFedGpt,
  advancedSettingsFields,
  advancedSettings,
  tool,
}) {
  const dispatch = useDispatch();
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isCompanyTaggingEnabled = toolsAvailable.includes(AI_TOOLS.COMPANY_TAGGING);

  const [docSelectModes] = React.useState(
    selectDocSelectModes({ isCompanyTaggingEnabled, isResearchMode, isQueryMode, isFedMode, isWebMode, isFedGpt })
  );

  const [isSelectingPrefillQuestion, setIsSelectingPrefillQuestion] = React.useState(false);
  const [isSelectingDocs, setIsSelectingDocs] = React.useState(false);
  const [tickers, setTickers] = React.useState(isQueryMode ? preSelectedTickers || [] : null);
  const [selectedDocs, setSelectedDocs] = React.useState(preSelectedDocs || []);
  const [question, setQuestion] = React.useState('');

  const showViewDocsMessage = isFedMode;

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: advancedSettings || defaultAdvancedSettings,
    });
  }, []);

  const onDismiss = () => setIsSelectingDocs(false);

  const onAfterDocReceived = (docs) => {
    if (!docs) return;
    if (isQueryMode) {
      const autoSelectedDocs = docs.filter((d) => d.is_recent && d.added_by_user_id === null);
      const nextDocs = filterDuplicates([...selectedDocs, ...autoSelectedDocs]);
      setSelectedDocs(nextDocs);
      return;
    }
    if (isFedMode || isFedGpt) {
      // const autoSelectedDocs = docs.filter((d) => d.is_recent && d.added_by_user_id === null);
      if (preSelectedDocs?.length === 0) {
        setSelectedDocs(filterDuplicates([...selectedDocs, ...preSelectedDocs]));
      }
      return;
    }

    const autoSelectedDocs = docs.filter((d) => d.is_recent);
    setSelectedDocs([...selectedDocs, ...autoSelectedDocs]);
  };

  const [filters, setFilters] = React.useState({ tickers, isQueryMode, isFedMode, isResearchMode, isWebMode });
  const { docs, isLoading: isLoadingDocs } = useDocs(filters, onAfterDocReceived);

  const {
    prevUsedModel,
    onSubmit,
    onClearResult,

    resultId,
    citationsResultId,
    sourceDocs,

    questionAsked,
    formError,
    isSubmittingToApi,
  } = useAskDigitalAnalyst(tool);

  const { enableFollowUpQuestions, enableTextSearch } = useAdvancedSettings();

  const onClearAnswer = () => {
    onClearResult();
    onReset();
  };

  const onReset = () => {
    onClearResult();
    setSelectedDocs([]);
    setQuestion('');
  };

  const onSelectedDocsChange = (t) => setSelectedDocs(t);

  const onQuestionChange = (e) => {
    const {
      target: { value },
    } = e;
    setQuestion(value);
  };

  const onSelectDocumentsClick = () => setIsSelectingDocs(true);

  const onAddTicker = async (t) => {
    if (!t) return;
    if (tickers.length >= 3) {
      showErrorBanner('A maximum of 3 tickers is supported at this time', null, dispatch);
      return;
    }
    const nextTickers = [...tickers];
    if (!tickers.includes(t)) {
      nextTickers.push(t);
      setTickers(nextTickers);
      setFilters({
        ...filters,
        tickers: nextTickers,
      });
    }
  };

  const onRemoveTicker = (ticker) => {
    const docs = selectedDocs.filter((doc) => {
      const { security_symbol } = doc;
      return security_symbol !== ticker;
    });
    const nextTickers = tickers.filter((t) => t !== ticker);
    setTickers(nextTickers);
    setSelectedDocs(docs);
    setFilters({
      ...filters,
      tickers: nextTickers,
    });
  };

  const handleAddDocumentSpeakerSearch = (v) => {
    if (Array.isArray(v)) {
      const docs = [...selectedDocs, ...v];
      onSelectedDocsChange(filterDocDuplicates(docs));
    } else {
      const docs = [...selectedDocs, v];
      onSelectedDocsChange(filterDocDuplicates(docs));
    }
  };

  const handleAddDocument = (v) => {
    if (Array.isArray(v)) {
      onSelectedDocsChange((prevState) => filterDocDuplicates([...prevState, ...v]));
    } else {
      onSelectedDocsChange((prevState) => filterDocDuplicates([...prevState, v]));
    }
  };

  const handleRemoveDocument = (filenameOrFilenames) => {
    if (Array.isArray(filenameOrFilenames)) {
      onSelectedDocsChange((prevState) => prevState.filter((d) => !includes(filenameOrFilenames, d.filename)));
    } else {
      onSelectedDocsChange((prevState) => prevState.filter((d) => d.filename !== filenameOrFilenames));
    }
  };

  const handleReviseQuestion = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      try {
        const textarea = document.querySelector('textarea[name="question"]');
        textarea?.focus();
      } catch (e) {
        logError(e);
      }
    }, 100);
  };

  const handleAskNewQuestion = async (
    question,
    model,
    forceFollowUpQuestionOff,
    forceQuestionAsked,
    forceContext,
    isCitationsMode,
    resultId
  ) => {
    if (question) {
      const isAPICitationsMode = isCitationsMode;

      await sleep(100);
      if (!isAPICitationsMode) {
        setQuestion(forceQuestionAsked ? forceQuestionAsked : question);
      }
      const form = {
        question,
        model,
        forceFollowUpQuestionOff,
        forceQuestionAsked,
        selectedDocs: forceContext ? [] : selectedDocs,
        enableTextSearch,
        isCitationsMode,
        isAPICitationsMode,
      };
      if (isAPICitationsMode) {
        form.record_id = resultId;
      }
      if (forceContext) {
        form.extra_context = forceContext;
      }
      onSubmit(form);
    } else {
      handleReviseQuestion();
      setQuestion('');
    }
  };

  const handleSelectPrefillQuestion = (v) => setQuestion(v);

  return (
    <Container>
      {isSelectingDocs && (
        <DocumentSelectionModal
          docSelectModes={docSelectModes}
          onDismiss={onDismiss}
          docs={docs}
          isLoadingDocs={isLoadingDocs}
          tickers={tickers}
          selectedDocs={selectedDocs}
          handleAddDocumentSpeakerSearch={handleAddDocumentSpeakerSearch}
          handleAddDocument={handleAddDocument}
          handleRemoveDocument={handleRemoveDocument}
        />
      )}
      {isSelectingPrefillQuestion && (
        <PrefillQuestionModal
          onSelect={handleSelectPrefillQuestion}
          onDismiss={() => setIsSelectingPrefillQuestion(false)}
        />
      )}
      <Page width={'1000px'}>
        <BackArrowButton onClick={resultId === null ? onGoBack : onClearAnswer} />
        <Heading>
          <H5>{isFedGpt ? 'Ask FedGPT' : 'Ask Digital Analyst'}</H5>
          <InfoIconTooltip type={isFedGpt ? InfoIconTypes.FED_GPT : InfoIconTypes.QUERY_TOOL} />
          <AdvancedSettingsButton fields={advancedSettingsFields || defaultAdvancedSettingsFields} />
        </Heading>
        <FormWrapper>
          <Container top={24}>
            {isQueryMode ? (
              <Container bottom={16}>
                <TickerSelect
                  onAddTicker={onAddTicker}
                  onRemoveTicker={onRemoveTicker}
                  tickers={tickers}
                  isResearchMode={isResearchMode}
                  multipleTickersEnabled
                />
              </Container>
            ) : null}
            <Container row verticallyCenter spaceBetween>
              <Label>
                <Body5>Question</Body5>
              </Label>
              {isQueryMode ? (
                <Container bottom={8}>
                  <TransparentFlatButton onClick={() => setIsSelectingPrefillQuestion(true)}>
                    Use Pre-filled Questions
                  </TransparentFlatButton>
                </Container>
              ) : null}
            </Container>
            <Container>
              <textarea name={'question'} onChange={onQuestionChange} value={question} />
            </Container>
          </Container>
          {showViewDocsMessage && (
            <Container top={16}>
              <Body5>Click any document name below to open the source file</Body5>
            </Container>
          )}
          <Container top={16}>
            <SelectedDocuments
              onSelectDocumentsClick={onSelectDocumentsClick}
              selectedDocs={selectedDocs}
              onSelectedDocsChange={onSelectedDocsChange}
              showAddAllDocumentsAction={false}
            />
          </Container>
          <Container top={24}>
            <Container centerAll>
              <FormState error={formError} />
            </Container>
            <SubmitButtonWrapper>
              <FlatButton
                onClick={() => onSubmit({ question, selectedDocs, isResearchMode, enableTextSearch })}
                fullWidth
              >
                Submit
              </FlatButton>
            </SubmitButtonWrapper>
            <Container top={8} centerAll>
              <TransparentFlatButton onClick={onReset} fullWidth>
                Reset
              </TransparentFlatButton>
            </Container>
          </Container>
        </FormWrapper>
      </Page>
      <div id={'question-answer'}></div>
      {resultId || isSubmittingToApi ? (
        <Page width={'1000px'}>
          <Container>
            <QuestionResult
              isSubmittingToApi={isSubmittingToApi}
              resultId={resultId}
              citationsResultId={citationsResultId}
              model={prevUsedModel}
              userMode={tool}
              question={questionAsked}
              sourceDocs={sourceDocs}
              selectedDocs={selectedDocs.map((d) => d.id)}
              handleAskQuestion={handleAskNewQuestion}
              enableMoreDetail
              enableQualityCheck
              enableFollowUpQuestions={enableFollowUpQuestions}
              ActionsComponent={
                <Container top={16}>
                  <ActionsComponent
                    resultId={resultId}
                    question={questionAsked}
                    handleReviseQuestion={handleReviseQuestion}
                    handleAskNewQuestion={handleAskNewQuestion}
                  />
                </Container>
              }
            />
          </Container>
        </Page>
      ) : null}
    </Container>
  );
}
