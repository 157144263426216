import React from 'react';
import { NewDocumentSelectionModal } from '../DigitalAnalyst/components/DocumentSelectionModal';
import { DOC_SELECT_MODES } from '../../constants';
import { filterDocDuplicates } from '../../reducers/docs';

const docSelectModes = [
  DOC_SELECT_MODES.ticker,
  DOC_SELECT_MODES.speaker,
  DOC_SELECT_MODES.docType,
  DOC_SELECT_MODES.companyTag,
];

export const DocSelectionModalController = ({ onInit, nodes, updateNodeData }) => {
  const [activeNodeId, setActiveNodeId] = React.useState(null);

  const showModal = (nodeId) => {
    setActiveNodeId(nodeId);
  };

  const hideModal = () => {
    setActiveNodeId(null);
  };

  React.useEffect(() => {
    onInit({ showModal, hideModal });
  }, []);

  if (!activeNodeId) {
    return null;
  }

  const nodeIndex = nodes.findIndex((node) => node.id === activeNodeId);
  const node = nodes[nodeIndex];
  const selectedDocs = node.data.selectedDocs;

  const onAddDocument = (nodeId, docOrDocs) => {
    const docs = Array.isArray(docOrDocs) ? docOrDocs : [docOrDocs];
    const data = {
      selectedDocs: filterDocDuplicates([...selectedDocs, ...docs]),
    };
    updateNodeData(nodeId, data);
  };
  const onRemoveDocument = (nodeId, docOrDocs) => {
    const docs = Array.isArray(docOrDocs) ? docOrDocs : [docOrDocs];
    const docIds = docs.map((d) => d.id);
    const data = {
      selectedDocs: selectedDocs.filter((selectedDoc) => !docIds.includes(selectedDoc.id)),
    };
    updateNodeData(nodeId, data);
  };

  const bindNodeIdToAddDocument = (nodeId) => (docOrDocs) => onAddDocument(nodeId, docOrDocs);
  const bindNodeIdToRemoveDocument = (nodeId) => (docOrDocs) => onRemoveDocument(nodeId, docOrDocs);

  return (
    <NewDocumentSelectionModal
      docMode={docSelectModes[0]}
      defaultActiveMode={docSelectModes[0]}
      docSelectModes={docSelectModes}
      selectedDocs={selectedDocs}
      handleAddDocument={bindNodeIdToAddDocument(activeNodeId)}
      handleAddDocumentSpeakerSearch={bindNodeIdToAddDocument(activeNodeId)}
      handleRemoveDocument={bindNodeIdToRemoveDocument(activeNodeId)}
      onDismiss={hideModal}
    />
  );
};
