import React from 'react';
import styled from 'styled-components';
import { Body3, Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { CollapseButton } from '../../../components/buttons/CollapseButton';
import { ResultOnly } from './AnswerLine';
import { useNewCompanySnapshotStreamingResult } from '../../../hooks/features/useNewCompanySnapshotStreamingResult';

const BulletWrapper = styled.div`
  padding: 15px 0 0 0;
`;

const ReportWrapper = styled.div`
  padding: 16px 0 16px 8px;

  ol li {
    padding: 0 0 8px 0;

    ul {
      padding: 16px 0 0 24px;

      li {
        list-style-type: disc;
      }
    }

    margin: 0 0 16px 0;
  }

  ul li {
    margin: 0 0 4px 0;
  }
`;

const EvalAnswer = ({ bullet, data, index }) => {
  const answer = data[index];
  if (!answer)
    return (
      <BulletWrapper>
        <Body3>Working...</Body3>
      </BulletWrapper>
    );

  return (
    <BulletWrapper>
      <Body3 bold>{bullet} </Body3>
      <ResultOnly result={answer} />
    </BulletWrapper>
  );
};

export const EvaluationTemplateResponseSummary = ({ resultId, questionList, Heading }) => {
  const [collapseAnswer, setCollapseAnswer] = React.useState(false);
  const state = useNewCompanySnapshotStreamingResult(resultId, questionList);
  const {
    answersLookup,
    questionListAndAnswerIds,
    error: streamingError,
    // context: streamingContext,
    // streamEnd: didStreamEnd,
  } = state;

  if (collapseAnswer) {
    return null;
  }

  return (
    <Container>
      <Container row spaceBetween>
        {Heading}
        <CollapseButton isCollapsed={collapseAnswer} setCollapsed={setCollapseAnswer} />
      </Container>

      <Container top={24}>
        {!answersLookup || !questionListAndAnswerIds ? (
          <Container>
            <Body5 bold>Working...</Body5>
          </Container>
        ) : (
          <Container>
            {streamingError && (
              <Container bottom={24}>
                <Body5>An unexpected error occurred. Please try again.</Body5>
              </Container>
            )}
            <ReportWrapper>
              <ol>
                <li>
                  <Container>
                    <Body3>Executive Summary</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Fund Name:'} index={0} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Date of Memorandum:'} index={1} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Fund Objective:'} index={2} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Fund Strategy:'} index={3} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Organization Structure</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Legal Structure and Jurisdiction:'} index={4} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Management Team:'} index={5} />
                    </li>

                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Directors:'} index={27} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Investment Strategy</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Risk Strategy:'} index={6} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Leverage Risk Strategy:'} index={7} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Concentration Risk:'} index={8} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Fees and Expenses</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Management Fees:'} index={9} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Performance Fees:'} index={10} />
                    </li>

                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Allocation by Class:'} index={28} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Other Fees:'} index={11} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Subscription and Redemption</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Minimum Investment:'} index={12} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Lock-Up Periods:'} index={13} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Notice Periods:'} index={14} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Redemption Limitations:'} index={15} />
                    </li>

                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Redemption Suspension:'} index={29} />
                    </li>

                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Side Pockets:'} index={16} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Liquidating Fund:'} index={30} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Currency Considerations</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Currency Risk:'} index={17} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Accounting and Reporting Standards</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Accounting Standards:'} index={18} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Reporting Frequency:'} index={19} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Tax Implications:'} index={20} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Share Classes:'} index={21} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Share Class Tax Implications:'} index={22} />
                    </li>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Governing Regulatory Authority:'} index={23} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Conflicts of Interest</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Summary:'} index={24} />
                    </li>
                  </ul>
                </li>

                <li>
                  <Container>
                    <Body3>Risks</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Fund Specific Risks:'} index={31} />
                    </li>
                  </ul>
                </li>

                <li>
                  <Container>
                    <Body3>Additional Information</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Investor Communications:'} index={25} />
                    </li>
                  </ul>
                </li>
                <li>
                  <Container>
                    <Body3>Key Service Providers</Body3>
                  </Container>
                  <ul>
                    <li>
                      <EvalAnswer data={answersLookup} bullet={'Legal Advisors:'} index={26} />
                    </li>
                  </ul>
                </li>
              </ol>
            </ReportWrapper>
          </Container>
        )}
      </Container>
    </Container>
  );
};
