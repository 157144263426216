import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { logFormSubmit, logNetRequest, scrollToTop } from '../../utils/usefulFuncs';
import { useAdvancedSettings } from '../application/useAdvancedSettings';
import { addOptionalField } from '../../utils/application';

export const PROMPT_SERIES_MODES = {
  companySnapshot: 'cs',
  warningIndicators: 'wi',
  bullBear: 'bb',
  evaluationTool: 'evalTool',
  growthEquityAnalysis: 'gea',
};

const prefixChartQuestion =
  'Using JSON and answering within an object where the key is the year (FY20, FY21, FY22, FY23, and FY24) and the value ';
const growthEquityAnalysis = [
  'Provide the company’s name. If no name is provided, in bold, state “CONFIDENTIAL”',
  'Highlight the WIP Goal in 10 words. If no goal is provided, in bold, state “NOT PROVIDED”',

  // Pg - 1: Left Column Data:
  // Overview:  index-2
  'List the Deal Team. Only include names, separated by commas, and no other verbiage. If no deal team is listed, in bold, state “NOT PROVIDED”',
  'In one word state the sector the company operates in. If there is a subsector of the main sector, list that after the main sector with a hyphen in between. If no sector is specified, in bold, state “NOT PROVIDED”',
  'List the city and state or country the company operates in. Include no other verbiage.If no location is listed, in bold, state “NOT PROVIDED”',
  'State in one word the type of financing the transaction involves (e.g. equity, credit, debt, mezzanine, etc.). If more than one type of financing is being considered, list each, followed by a comma. If the type of financing is not known, in bold, state "NOT PROVIDED"',
  'Using only numbers, state the financing size or range. Use the $ sign, whole numbers and M for millions. If the size is not known, in bold, state "NOT PROVIDED"',
  'State the lead source using just the company name and division, if applicable. If no lead source is mentioned, in bold, state "NOT PROVIDED"',
  'Highlight whether the company is currently banked by stating either "Yes" or "No". If Yes, follow with a hyphen and the name of the bank. If no, state "No". If  unknown, in bold, state “NOT PROVIDED”.',

  // Overview of Cap Table  index -9
  'Highlight in 12 words or less, the most recent financing round (e.g. Seed, Series A, B, C, etc.) followed by a colon. Then list the size of the financing in whole dollars, using M for millions followed by a backslash. Next include the valuation (in $ M) and state whether it was pre or post money followed by a backslash. Then list the date in abbreviated month and year. Lastly, in parenthesis, state who led the most recent round. If no information is found, in bold, state “NOT PROVIDED”',
  'Highlight in 10 words or less, the amount of Equity Raised to date. State in whole dollars, using $ sign and M for million. Lastly, in parenthesis, state the key investors. Include "key investors include" in the parenthesis. If no equity has been raised, in bold, state “NOT PROVIDED”',
  'Highlight in 12 words or less, the amount of debt outstanding. Use the $ sign, whole numbers and M for millions followed by a backslash. Next list the maturity date of the debt using abbreviated month hyphen year followed by a space and "Maturity" followed by a backslash. Next list the date the debt begins to amortize in abbreviated month hyphen year format followed by a space and "Amortization" followed by a backslash. Lastly, list the cost of the debt followed in parenthesis by who banked it. Include a space before and after each backslash. If no debt is listed on the balance sheet, simply state "$0". If no balance sheet is provided, in bold, state “NOT PROVIDED”.',

  // Right Column: index -12
  'Highlight in 15 words how the investor sourced this deal. If no sourcing information was provided, in bold, state “Sourcing Background - NOT PROVIDED”.',
  'Provide a 30 word overview of the proposed transaction capital raise.',
  'Detail in 30 words the key process dynamics to be contemplated for the deal. Do not include any financial highlights. Start the sentence with "The key process dynamics include".',
  'Highlight in 25 words the how the company funds its business. Include ownership details, if provided. Detail any prior equity raises, VC funding or debt outstanding.',

  // Business Overview: index - 16
  'Provide a 45 word description of the company.',
  'Summarize in 25 words how the company derives its revenues.',
  "In 35 words, describe the company's current customer base.",

  // Preliminary View, Key Areas of Diligence & Next Steps: index -19
  "Highlight in 20 words why we like this investment opportunity. Follow that with 25 words on the benefits to the investor now and potentially in the future as the company's banker.",
  'Detail in 35 words the key areas of diligence that need to be performed for the transaction.',
  'Detail in 30 words, the next steps for the investor.',

  // Summary Financials: // index -22
  'Answer with a JSON object where the key is the Year (FY20, FY21, FY22, FY23, and FY24) and the value is the Revenue for that Year. Round to one decimal, and use the abbreviation M for millions eg $X.XM. If the value is less than $1,000,000 round to two decimals. For years where a Revenue figure is not available the value should be "N/A".',
  prefixChartQuestion +
    'is the value of the percentage change in year of year growth for FY20, FY21, FY22, FY23 and FY24. Use only whole numbers, no decimals. Include no additional verbiage, just the year and accompanying growth rate %. For years where no calculation is available, state "N/A"',
  'Answer with a JSON object where the key is the Year (FY20, FY21, FY22, FY23, and FY24) and the value is the Gross Profit for that Year. Round to one decimal, and use the abbreviation M for millions eg $X.XM. If the value is less than $1,000,000 round to two decimals. For years where a Gross Profit figure is not available the value should be "N/A".',
  prefixChartQuestion +
    'is the value of the Margin % for FY20, FY21, FY22, FY23 and FY24. State in whole numbers, up to one decimal place. Calculate by dividing the gross profit for each by the corresponding revenue for that year. Include no additional verbiage just the years and accompanying %\'s. For years where no calculation is available, state "N/A".',
  'Answer with a JSON object where the key is the Year (FY20, FY21, FY22, FY23, and FY24) and the value is the Net Profit for that Year. Round to one decimal, and use the abbreviation M for millions eg $X.XM.  If the value is less than $1,000,000 round to two decimals.  For years where a Net Profit figure is not available the value should be "N/A".',
  prefixChartQuestion +
    'is the value of the Net Profit margin % for FY20, FY21, FY22, FY23 and FY24. State in whole numbers, up to one decimal place. Include no additional verbiage just the years and accompanying %\'s. For years where no calculation is available, state "N/A"',
];

const chartSettings = {
  max_context_tokens: 20000,
  temperature: 0,
};
const growthEquityAnalysisIndividualSettings = [
  null, // 0
  null,
  null, // 2
  null,
  null,
  null,
  null,
  null,
  null,
  null, // 9
  null,
  null,
  null, //12
  null,
  null,
  null, //16
  null,
  null,
  null, //19
  null,
  null,
  chartSettings, //22
  chartSettings,
  chartSettings,
  chartSettings,
  chartSettings,
  chartSettings, //27
];

/*
Company Name -
WIP Goal -

Pg - 1: Right Hand Bullets

Deal Synopsis:
    Sourcing background -
    Overview of capital raise -

    Key process dynamics -
    Relevant company/financing history -

Business Overview:
    Brief Company Description -
    Revenue/business model -
    Description of customer -

Preliminary View, Key Areas of Diligence & Next Steps:
    Why we like the opportunity / our angle -
Key areas of diligence -
    Next steps -

Pg - 1: Left Column Data:

    Overview:
      Deal Team -
Sector/Industry -
       Location -
 Financing Type -
 Financing Size -
    Lead Source -
         Banked -

Summary Financials:
     Revenue -
YoY growth % -
Gross Profit -
    Margin % -
      EBITDA -
    Margin % -

Overview of Cap Table
    Most Recent Round -
Equity Raised to Date -
     Debt Outstanding -
*/

const companySnapshotQuestions = [
  "What is this company's business?",
  'How does it generate revenue?',
  'What products / services does it sell?',
  'What are its target markets and how large are they?',
  'Who are its main competitors?',
  'How is it differentiated from its competitors?',
  'Who are its target customers?',
  'What are its reported operating segments?',
  'How else does the company break down revenue and earnings (such as by product and/or service, by geography)?',
  'What is the breakdown of revenue and operating income by segment?',
  'How does it generate earnings?',
  'What are recent trends in sales and profitability, overall and by segment?',
  "What's its strategy to grow sales and earnings?",
  'How does this company fund its growth?',
  'What are its long term financial targets?',
  'How much debt does this company have?',
  "What are the risks to the company's business?",
];
const bullBearQuestions = [
  'What is the bull case for this stock, explain in detail.',
  'What is the bear case for this stock, explain in detail.',
];
const warningIndicatorsQuestions = [
  'Did the company lower its forward guidance? If so, provide details.',
  'Did management note a negative impact from macroeconomic conditions on their business?  If so, provide details.',
  'Did management note an increase in competition? If so, highlight what they said',
  'Did management highlight any regions or divisions that are underperforming? If so, what did they say?',
  'Did the company note any change to its business model or strategy? If yes, what were the changes?',
  'Did management highlight any change in demand or softer end markets? If so, provide details.',
  'Has there been any negative impact from supply chain issues? If so, provide details.',
  'Are costs rising faster than sales? If so, did management offer a reason?',
  'Is inflation negatively impacting operations or results? If so, provide details.',
  'Has the company had to roll-back prior price hikes? If so, how come?',
  'Did gross margins decline vs the prior year or the prior quarter? If so, provide details.',
  'Is inventory growth outpacing sales growth? If so, by how much?',
  'Have the CEO, President, CFO or COO resigned recently? If so, when?',
  'Has there been any change in how management is compensated? If so, provide details',
  'Has the company changed auditors recently? If so, why?',
  'Did the company suspend or lower its dividend? If so, please explain.',
  'Did the company suspend its stock buyback or share repurchase? If so, what was their reasoning?',
];

const ppmSuffix =
  ' If there is a Side Letter, its terms should supersede the PPM, so use the answer from the Side Letter.';
const evalTemplateQuestions = [
  'What is the fund name?', // 1
  'What is the date of the memorandum?',
  'Provide a one sentence summary of the fund’s investment objective and/or investing philosophy.',

  "Provide a three sentence highlight of the fund's investment strategy (i.e long/short equity, macro, etc) including asset classes traded and geographies it invests in.",
  'Provide a two sentence description of the investment vehicle (e.g. limited partnership, corporation, etc), the legal entity and the jurisdiction.',
  'List in bullets the key personnel (e.g. investment manager, CEO, CIO, portfolio manager, etc) for the fund and a 4 sentence description of their relevant investment experience for each. Do not include Directors of the fund.',

  "Provide a five sentence summary highlighting the fund's specific strategy to mitigate risk.",
  'Does the fund use leverage? If so, in five sentences, detail how they intend to manage leverage risk. (If leverage risk is addressed in the prior risk management question, skip this question)',
  'Does the fund take concentrated positions? If so, is their maximum size allowed? Detail in 4 sentences, how they intend to manage concentration risk.' +
    ppmSuffix,

  'What is the funds management fee, when is it payable and in what currency?' + ppmSuffix, // 10
  'In five sentences, detail the rate and calculation methods for performance fees, include notable details, but exclude examples.' +
    ppmSuffix,
  'Excluding management and performance fees, in 8 sentences, detail any other fees that could be charged to investors in the fund.' +
    ppmSuffix,

  'What is the minimum amount required for initial and subsequent investments?' + ppmSuffix,
  'Does the fund have a lock-up period? If yes, provide details. If not explicitly stated, skip this question.' +
    ppmSuffix,
  'Is advance notice required for redemptions? If so, detail the notice period, frequency and minimum redemption amount allowed.' +
    ppmSuffix,
  'Summarize the redemption restrictions in seven sentences. Exclude references to minimum redemption, dates and frequency.' +
    ppmSuffix, // 16
  'Can the fund use side pockets for illiquid investments, if so, detail the terms for their use. If not explicitly mentioned, skip this question.',

  'Does the fund have currency risk, if so, detail how it manages currency risk in five sentences. If there is no currency risk, skip this question.',

  'If stated, what accounting standards (e.g. GAAP, IFSR, FASB, etc) does the fund use? Summarize in two sentences.  If not stated, skip this question.',
  'What is the accounting Reporting Frequency?',
  'Provide a twelve sentence summary of key tax implications for investors.', // 21

  "If there is more than one share class offered, detail what they are and how they differ in 8 sentences. Do not include any similarities such as fee's or minimum subscription if they are the same.  (If there is only one share class, skip this question)", // 22
  'If there is more than one share class, compare and contrast the tax considerations for investors in the different share classes in under 10 sentences.',
  'What is the Governing regulatory authority and compliance measures? Provide a one sentence summary for each jurisdiction.',

  'Summarize any potential conflicts of interest and how they are managed.',
  'Highlight the format and details of communications with investors in 6 sentences.', // 26
  'List in bullets the Administrator, Auditor, Custodian, Legal advisors and prime broker(s) and their contact information. List each in one line.',

  'Does the fund have a Board of Directors or outside Directors? If so, list in bullets them and provide a two sentence summary for each of their experience and background. If no directors are listed, skip this question.' +
    ppmSuffix,
  'If the fund has multiple classes, detail how it allocates the performance fee among classes in 5 sentences. If it does not have multiple classes, skip this question.' +
    ppmSuffix,
  'Does the fund have the ability to suspend redemptions or valuations? If so, provide a 5 sentence summary of the details. If no ability exists, skip this question.' +
    ppmSuffix,
  'Does this particular fund have the ability to create a new fund to hold illiquid securities for redemption? If so, what is the fund called and summarize the details in 5 sentences. If no ability exists, skip this question.',
  'Summarize any other risks of investing in the fund, not including currency, leverage, concentration or those mentioned in conflicts of interest',
  // 'Respond with a brief description of the fund’s goals, size, and unique selling points.',
  // 'Provide a description of the legal entity and jurisdiction.',
  // 'Provide a summary of key personnel, including backgrounds and roles.',
  // 'Provide the details on ownership percentages and key stakeholders.',
  // 'What is the type of investment vehicle, (for example: Limited Partnership, Offshore Corporation, etc...)?',
  // 'What is the legal domicile of the investment vehicle.',
  // 'Respond with a detailed description of the investment strategy, including asset classes, geographic focus, and style (e.g., long/short, global macro)',
  // 'Provide an outline of risk management practices and measures.',
  // 'What is the structure and rate for the management fees',
  // 'What is the structure, rate, and calculation method. for the performance fees',
  // 'What are the other fees, excluding management fees and performance fees, Including, but not limited to, administrative fees, audit fees, and legal fees.',
  // 'What is the amount required for initial and subsequent investments.',
  // 'Is there an advance notice required for subscriptions and redemptions.',
  // 'Are there any caps, gates, or other restrictions on redemptions.',
  // 'Are there use of side pockets for illiquid investments and conditions for their use.',
  // 'What are the durations and conditions of the lock-up periods, if any?',
  // 'What are the Frequency of Redemptions? (Quarterly, monthly, etc., including any specific dates or conditions.)',
  // 'What is The main currency of the fund’s operations?',
  // 'What are the Practices and policies for managing currency risk.',
  // 'What accounting standards are used, (list GAAP, IFRS, or other standards used.)',
  // 'What is the Name and contact information of the auditor.',
  // 'What is the accounting Reporting Frequency? (Monthly, quarterly, and annual reporting practices.)',
  // 'What is are all the share classes listed in the document?',
  // 'Provide a Description of how the fund structures different series or classes of shares, if applicable.',
  // 'What is the Methodology used for allocating fees and expenses among different share classes?',
  // 'What is the Approach to calculating performance for each share class, including any specific benchmarks or hurdles?',
  // 'Provide Details on how currency hedging is applied to each share class, if relevant.',
  // 'Provide an Overview of tax considerations for investors in different share classes.',
  // 'What is the administrator Details and contact information?',
  // 'What is the Custodian Details and contact information?',
  // 'What are the Prime Broker(s) Details and contact information?',
  // 'What are the Legal Advisors Details and contact information?',
  // 'What is the Governing regulatory authority and compliance measures?',
  // 'What is the Compliance Officer Contact details and role description.',
  // 'Provide a Description of potential conflicts of interest and how they are managed.',
  // 'Provide a Summary of historical performance, if available',
  // 'Provide a Description of the frequency and format of communications with investors.',
  // 'Provide an Overview of key tax implications for investors.',
];

export const formattedQuestionsLookup = [
  ['What is the bull case for this stock, explain in detail.', 'What is the bull case for this stock?'],
  ['What is the bear case for this stock, explain in detail.', 'What is the bear case for this stock?'],
  [
    'Did the company lower its forward guidance? If so, provide details.',
    'Did the company lower its forward guidance?',
  ],
  [
    'Did management note a negative impact from macroeconomic conditions on their business in the recent earnings call?  If so, provide details.',
    'Did management note a negative impact from macroeconomic conditions on their business?',
  ],
  [
    'Did management note an increase in competition in the recent earnings call? If so, highlight what they said',
    'Did management note an increase in competition?',
  ],
  [
    'Did management highlight any regions or divisions that are underperforming? If so, what did they say?',
    'Did management highlight any regions or divisions that are underperforming?',
  ],
  [
    'Did the company note any change to its business model or strategy? If yes, what were the changes?',
    'Did the company note any change to its business model or strategy?',
  ],
  [
    'Did management highlight any change in demand or softer end markets? If so, provide details.',
    'Did management highlight any change in demand or softer end markets?',
  ],
  [
    'Has there been any negative impact from supply chain issues? If so, provide details.',
    'Has there been any negative impact from supply chain issues?',
  ],
  ['Are costs rising faster than sales? If so, did management offer a reason?', 'Are costs rising faster than sales?'],
  [
    'Is inflation negatively impacting operations or results? If so, provide details.',
    'Is inflation negatively impacting operations or results?',
  ],
  [
    'Has the company had to roll-back prior price hikes? If so, how come?',
    'Has the company had to roll-back prior price hikes?',
  ],
  [
    'Did gross margins decline vs the prior year or the prior quarter? If so, provide details.',
    'Did gross margins decline vs the prior year or the prior quarter?',
  ],
  ['Is inventory growth outpacing sales growth? If so, by how much?', 'Is inventory growth outpacing sales growth?'],
  [
    'Have the CEO, President, CFO or COO resigned recently? If so, when?',
    'Have the CEO, President, CFO or COO resigned recently?',
  ],
  [
    'Has there been any change in how management is compensated? If so, provide details',
    'Has there been any change in how management is compensated?',
  ],
  ['Has the company changed auditors recently? If so, why?', 'Has the company changed auditors recently?'],
  [
    'Did the company suspend or lower its dividend? If so, please explain.',
    'Did the company suspend or lower its dividend?',
  ],
  [
    'Did the company suspend its stock buyback or share repurchase? If so, what was their reasoning?',
    'Did the company suspend its stock buyback or share repurchase?',
  ],
];

function enhanceQuestion(question, index, settingsList) {
  const settings = settingsList[index];
  if (settings) {
    return { question, ...settings };
  }
  return { question };
}

function enhanceQuestionList(questionList, mode) {
  console.log({ questionList, mode });
  if (mode === PROMPT_SERIES_MODES.growthEquityAnalysis) {
    const a = questionList.map((q, i) => enhanceQuestion(q, i, growthEquityAnalysisIndividualSettings));
    console.log('enhance', a);
    return a;
  }

  return questionList;
}

const convertModeToQuestionList = (mode) => {
  if (PROMPT_SERIES_MODES.companySnapshot === mode) {
    return companySnapshotQuestions;
  }
  if (PROMPT_SERIES_MODES.warningIndicators === mode) {
    return warningIndicatorsQuestions;
  }
  if (PROMPT_SERIES_MODES.bullBear === mode) {
    return bullBearQuestions;
  }
  if (PROMPT_SERIES_MODES.evaluationTool === mode) {
    return evalTemplateQuestions;
  }
  if (PROMPT_SERIES_MODES.growthEquityAnalysis === mode) {
    return growthEquityAnalysis;
  }
  return null;
};
const ACTIONS = {
  init: 'init',
  clear: 'clear',
  update: 'u',
};

const initState = {
  resultId: null,
  resultIdTicker: null,
  isSubmittingToApi: false,
  formError: null,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.init: {
      return { ...state, ...action.payload };
    }
    case ACTIONS.clear: {
      return { ...state, ...initState };
    }

    case ACTIONS.update: {
      return { ...state, ...action.payload };
    }

    default:
      throw new Error();
  }
}

const sendTracking = ({ mode, ticker, model }) => {
  if (mode === PROMPT_SERIES_MODES.companySnapshot) {
    TrackingEvent.create('Asked Digital Analyst For Company Summary', {
      Tickers: [ticker],
      Model: model,
    });
  }
  if (mode === PROMPT_SERIES_MODES.warningIndicators) {
    TrackingEvent.create('Asked Digital Analyst For Warning Indicators', {
      Tickers: [ticker],
      Model: model,
    });
  }
  if (mode === PROMPT_SERIES_MODES.bullBear) {
    TrackingEvent.create('Asked Digital Analyst For Bull/Bear Cases', {
      Tickers: [ticker],
      Model: model,
    });
  }
  if (mode === PROMPT_SERIES_MODES.evaluationTool) {
    TrackingEvent.create('Asked Digital Analyst For PPM Eval', {
      Tickers: [ticker],
      Model: model,
    });
  }
};

export const usePromptSeriesSubmit = (tool) => {
  const { model, enableSmoothing, maxAnswerLength, includeUserDocs, maxContextChunks } = useAdvancedSettings();

  const onSubmit = React.useCallback(
    async ({ prompt, tickers, docIds }) => {
      const form = {
        question_list: [prompt],
        model,
        user_mode: tool,
        streaming: true,
        enable_smoothing: enableSmoothing,
        include_user_docs: includeUserDocs,
        include_context: true,
      };
      if (tickers) {
        form.ticker = tickers;
      }
      if (docIds) {
        form.doc_list = docIds;
      }

      addOptionalField('max_context_chunks', maxContextChunks, form);
      addOptionalField('max_answer_tokens', maxAnswerLength, form);

      const URL = `v1/genai_default_questions`;
      const { status, data, error } = await sendApiRequest('post', URL, form);
      handle400Statuses(status);
      logNetRequest(URL, status, data);
      if (status === 200) {
        const { id } = data;
        return { resultId: id };
      } else {
        return {
          error: true,
        };
      }
    },
    [model, enableSmoothing, maxAnswerLength, includeUserDocs, maxContextChunks]
  );

  return onSubmit;
};

export const usePromptSeries = (tool) => {
  const [state, dispatch] = React.useReducer(reducer, initState, () => ({}));

  const { model, enableSmoothing, maxContextLength, includeUserDocs, maxContextChunks } = useAdvancedSettings();

  const onClear = () => {
    dispatch({
      type: ACTIONS.clear,
    });
  };

  const onSubmit = async (tickerOrCompany, modeOrQuestionList, docList) => {
    const questionList = Array.isArray(modeOrQuestionList)
      ? modeOrQuestionList
      : convertModeToQuestionList(modeOrQuestionList);
    dispatch({
      type: ACTIONS.update,
      payload: { questionList },
    });

    const form = {
      question_list: enhanceQuestionList(questionList, modeOrQuestionList),
      model,
      user_mode: tool,
      streaming: true,
      enable_smoothing: enableSmoothing,
      include_user_docs: includeUserDocs,
      include_context: true,
    };
    let isUsingCompany = false;
    if (tickerOrCompany) {
      if (tickerOrCompany?.doc_tag_id) {
        isUsingCompany = true;
        form.doc_tag_id = tickerOrCompany.doc_tag_id;
      } else {
        form.ticker = tickerOrCompany.symbol;
      }
    }
    if (docList) {
      form.doc_list = docList;
    }

    if (isUsingCompany) {
      const URL = `v1/genai_doc_search?doc_tag_id=${form.doc_tag_id}`;
      const { status, data } = await sendApiRequest('get', URL);

      logNetRequest(URL, status, data);
      if (status === 200) {
        const docs = data;
        form.doc_list = docs.map((doc) => doc.id);
      }
    }

    addOptionalField('max_context_chunks', maxContextChunks, form);
    addOptionalField('max_context_tokens', maxContextLength, form);

    sendTracking({ mode: modeOrQuestionList, ticker: tickerOrCompany, model });
    logFormSubmit(form);
    scrollToTop();

    dispatch({
      type: ACTIONS.update,
      payload: {
        isSubmittingToApi: true,
        resultId: null,
        resultIdTicker: null,
        formError: null,
      },
    });

    const URL = `v1/genai_default_questions`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    handle400Statuses(status);
    logNetRequest(URL, status, data);
    if (status === 200) {
      const { id } = data;
      dispatch({
        type: ACTIONS.update,
        payload: {
          isSubmittingToApi: false,
          resultId: id,
          resultIdTicker: tickerOrCompany?.symbol || tickerOrCompany?.name,
          formError: null,
        },
      });
      return true;
    } else {
      dispatch({
        type: ACTIONS.update,
        payload: {
          isSubmittingToApi: false,
          resultId: null,
          resultIdTicker: null,
          formError: data?.error || error || 'Something went wrong, try again.',
        },
      });
      return false;
    }
  };

  React.useEffect(() => {
    dispatch({
      type: ACTIONS.init,
      payload: {
        onClear,
        onSubmit,
      },
    });
  }, [model, enableSmoothing, maxContextLength, includeUserDocs]);

  return state;
};
