import { moment } from '../libs/nvstr-utils.es';

export const roll = (odds) => {
  const value = Math.random();
  return value <= odds;
};
export const coinFlip = (selection) => {
  if (selection === 'heads') {
    const value = Math.random();
    return value < 0.5;
  }

  const value = Math.random();
  return value >= 0.5;
};

export const includes = (files, filename) => {
  let isFound = false;
  files.forEach((f) => {
    if (f.filename === filename) {
      isFound = true;
    }
  });
  return isFound;
};

export function isValidUrl(string) {
  try {
    const regex = /^(https:\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
    return regex.test(string);
  } catch (e) {
    return false;
  }
}

export const parseToSearchParamsLookup = (searchParams) => {
  const lookup = {};
  for (let key of searchParams.keys()) {
    lookup[key] = searchParams.get(key);
  }
  return lookup;
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 */
export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  return 'unknown';
};

export const doArraysContainSameElementValues = (arr1, arr2, config = {}) => {
  const { checkIndexOrderMatches } = config;

  if (arr1.length !== arr2.length) {
    return false;
  }

  if (checkIndexOrderMatches) {
    return arr1.every((el, i) => el === arr2[i]);
  } else {
    return arr1.every((el) => arr2.includes(el));
  }
};

export const filterDuplicates = (array) => array.filter((el, i) => array.indexOf(el) === i);

export const returnDifferentElements = (arr1, arr2) => {
  const diffEls = [];
  arr1.forEach((el) => {
    if (!arr2.includes(el)) {
      diffEls.push(el);
    }
  });
  return diffEls;
};

export const separateIntoAddedAndRemovedElements = (currentArray, prevArray) => {
  const added = returnDifferentElements(currentArray, prevArray);
  const removed = returnDifferentElements(prevArray, currentArray);
  return [added, removed];
};

export const pickOne = (availablePicks = []) => {
  const countOfAvailablePicks = availablePicks.length;
  if (countOfAvailablePicks === 0) {
    return null;
  }

  const randomSelection = Math.floor(Math.random() * countOfAvailablePicks);
  return availablePicks[randomSelection];
};

export const pluralize = (count, string, config = {}) => {
  const { prefixCount } = config;

  if (string === 'have') {
    return `${prefixCount ? `${count} ` : ''}${count === 1 ? 'has' : 'have'}`;
  }

  if (string === 'need') {
    return `${prefixCount ? `${count} ` : ''}${count === 1 ? 'needs' : 'need'}`;
  }

  return `${prefixCount ? `${count} ` : ''}${count === 1 ? string : `${string}s`}`;
};

export const isInArray = (item, array) => {
  const [key, value] = Object.entries(item)[0];
  array.forEach((el) => {
    const isItem = el[key] === value;
    if (isItem) {
      return true;
    }
  });
  return false;
};

export const getRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isUndefined = (v) => v === undefined;
export const isNull = (v) => v === null;

export const isUndefinedOrNull = (v) => isUndefined(v) || isNull(v);

export const convertArrayToDisplayString = (array, limit = 0) => {
  if (limit === 1 || array.length === 1) {
    return array[0];
  } else if (limit > 0) {
    const limitedArray = array.slice(0, limit);
    const lastItem = limitedArray.splice(-1, 1);
    return `${limitedArray.join(', ')}, and ${lastItem}`;
  } else {
    const lastItem = array.splice(-1, 1);
    return `${array.join(', ')}, and ${lastItem}`;
  }
};

export const doXTimes = (x, func) => {
  for (let i = 0; i < x; i++) {
    func(i);
  }
};

export const mapXTimes = (x, func) => {
  const a = [];
  for (let i = 0; i < x; i++) {
    a.push(func(i));
  }
  return a;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const scrollToTopPageLoad = () => {
  try {
    document.addEventListener('DOMContentLoaded', function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  } catch (e) {
    logError(e);
  }
};

export const scrollToTop = (timeout) => {
  try {
    setTimeout(
      () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      isNaN(timeout) || timeout === undefined ? 200 : timeout
    );
  } catch (e) {
    logError(e);
  }
};

export function isEarningsFilename(filename) {
  if (!filename) return false;
  const lowerCasedFilename = filename.toLowerCase();
  return (
    lowerCasedFilename.indexOf('earnings transcript') > -1 ||
    lowerCasedFilename.indexOf('earnings call') > -1 ||
    lowerCasedFilename.indexOf('earnings-call') > -1
  );
}

export const stripFileExtension = (file) => {
  const extension = getFileExtensionType(file);
  return file.name.split('.' + extension)[0];
};

export const getFileExtensionType = (file) => {
  const splitOnPeriod = file.name.split('.');
  const extension = splitOnPeriod[splitOnPeriod.length - 1];
  return extension;
};

export function parseDateString(inputString) {
  // Define a regular expression pattern for "yyyy-mm-dd" format
  const datePattern = /\b(\d{4})-(\d{2})-(\d{2})\b/;

  // Use RegExp.exec to search for the pattern in the inputString
  const match = datePattern.exec(inputString);

  // Check if a match was found
  if (match) {
    // Extract year, month, and day from the match
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const day = parseInt(match[3], 10);

    // Check if the extracted values form a valid date
    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      // Create a Date object
      const parsedDate = new Date(year, month - 1, day); // Month is 0-based

      // Check if the Date object is valid
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    }
  }

  // If no valid date was found, return null
  return null;
}

export function findFirstLetterIndices(words) {
  const indices = new Array(26).fill(-1); // Array to store first index of each letter, initialized with -1
  const seen = new Set(); // Set to track letters we've already found

  // Iterate over each word
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word) {
      const firstLetter = word.charAt(0).toLowerCase(); // Get the first letter of the word and ensure it's lowercase

      // Calculate the position in the alphabet (0 for 'a', 1 for 'b', etc.)
      const index = firstLetter.charCodeAt(0) - 'a'.charCodeAt(0);

      // Check if this letter has been seen before
      if (!seen.has(firstLetter)) {
        seen.add(firstLetter);
        indices[index] = i; // Set the index where this letter first appears
      }
    }
  }

  return indices;
}

export function extractDomain(url) {
  try {
    // Create a new URL object
    const parsedUrl = new URL(url);
    // Return the domain (hostname) part of the URL
    return parsedUrl.hostname.split('www.')[1] || parsedUrl.hostname;
  } catch (error) {
    // console.error('Invalid URL:', error.message);
    return null;
  }
}

export function docListSort(docs, docType) {
  if (docType === 'unknown') {
    return docs?.sort((aDoc, bDoc) => {
      const { filename: aFileName } = aDoc;
      const { filename: bFileName } = bDoc;
      if (aFileName < bFileName) {
        return -1;
      }
      if (aFileName > bFileName) {
        return 1;
      }
      return 0;
    });
  }
  return docs?.sort((aDoc, bDoc) => {
    const { filename: aFileName, doc_type } = aDoc;
    const { filename: bFileName } = bDoc;
    if (doc_type === 'Website') {
      const aDomain = extractDomain(aFileName.split(' ')[0]);
      const bDomain = extractDomain(bFileName.split(' ')[0]);
      if (aDomain < bDomain) {
        return -1;
      }
      if (aDomain > bDomain) {
        return 1;
      }
      return 0;
    } else {
      const aDate = parseDateString(aFileName);
      const bDate = parseDateString(bFileName);
      return moment(bDate).valueOf() - moment(aDate).valueOf();
    }
  });
}

export const calcSuggestedMinMax = (data, defaultValue) => {
  if (!data) {
    return {
      min: defaultValue * -1,
      max: defaultValue,
    };
  }

  try {
    let min = 0;
    let max = 0;
    const { datasets, datapoints } = data;

    if (datasets) {
      datasets.forEach((d) => {
        const { data } = d;
        data.forEach((point) => {
          const { y } = point;
          if (y < min) {
            min = y;
          }
          if (y > max) {
            max = y;
          }
        });
      });
    } else {
      datapoints.forEach((point) => {
        const { y } = point;
        if (y < min) {
          min = y;
        }
        if (y > max) {
          max = y;
        }
      });
    }

    const absMax = Math.max(Math.abs(min), max);
    const padded = Math.min(1, 1.1 * absMax);
    return {
      min: padded * -1,
      max: padded,
    };
  } catch (e) {
    return {
      min: defaultValue * -1,
      max: defaultValue,
    };
  }
};

export const logError = (d, p) => {
  console.log('--ERROR--', d, p);
};
export const logNetRequest = (n, s, d) => {
  console.log(n, s, d);
};
export const logger = (n, s, d) => {
  console.log(n, s, d);
};
export const logFormSubmit = (n, s, d) => {
  console.log(n, s, d);
};

export const uniqueIdGenerator = () => {
  const generatedIds = new Set();

  function generateId() {
    // Define the characters to be used in the ID
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8; // Length of the unique ID
    let id;

    // Generate a unique ID
    do {
      id = '';
      for (let i = 0; i < length; i++) {
        id += characters.charAt(Math.floor(Math.random() * characters.length));
      }
    } while (generatedIds.has(id)); // Ensure the ID is unique

    // Store the generated ID
    generatedIds.add(id);
    return id;
  }

  return generateId;
};

export function removeElementFromArray(elementToRemove, array) {
  const index = array.findIndex((element) => element === elementToRemove);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function extractIntegerAfterHash(inputString) {
  if (!inputString) return null;
  const match = inputString.match(/#(\d+)/);
  return match ? parseInt(match[1], 10) : null;
}

export function extractDateFromString(inputString) {
  const dateRegex =
    /\b(\d{4}[-\/.]\d{1,2}[-\/.]\d{1,2}|\d{1,2}[-\/.]\d{1,2}[-\/.]\d{4}|\d{1,2}[-\/.]\d{1,2}[-\/.]\d{2}|\d{1,2} (?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{4}|\d{1,2} (?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{2}|\d{4} (?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{1,2})\b/i;
  const dateMatch = inputString.match(dateRegex);
  if (!dateMatch) {
    return null;
  }
  return dateMatch[0];
}

export function truncateStringWithDate(inputString, maxLength) {
  if (!inputString) return inputString;

  if (inputString.length <= maxLength) {
    return inputString;
  }

  const dateMatch = extractDateFromString(inputString);
  if (dateMatch === null) {
    return inputString.length > maxLength ? inputString.slice(0, maxLength) + '...' : inputString;
  }

  const truncateLength = inputString.length - maxLength;
  const dateLength = dateMatch[0].length;
  const adjustedTruncateLength = Math.max(truncateLength, dateLength);

  const datePosition = inputString.indexOf(dateMatch);
  const firstPart = inputString.slice(0, datePosition - adjustedTruncateLength - '...'.length);
  return `${firstPart.trim()}...${dateMatch}`;
}
