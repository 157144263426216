import { fedSentimentActions } from '../constants/actionTypes';

const defaultState = {
  isLoading: false,
  docsInAnalysis: null,
  csv: null,
  parsedCsv: null,
  documentTranscriptLookup: null,
  documentLookup: null,
  chartMetadata: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case fedSentimentActions.setIsGetting: {
      return {
        ...defaultState,
        isLoading: true,
      };
    }
    case fedSentimentActions.setResults: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case fedSentimentActions.setError: {
      return {
        ...defaultState,
        error: action.payload,
      };
    }
    case fedSentimentActions.clear: {
      return defaultState;
    }

    default:
      return state;
  }
}
