import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { Body1, Body3, Container } from '../../libs/nvstr-common-ui.es';
import { convertHexToRGBA, createTimeInstance, formatLocalizedDateTime, truncate } from '../../libs/nvstr-utils.es';
import React from 'react';
import styled from 'styled-components';
import { docTypes } from '../../constants';
import { ROUTES } from '../../constants/routes';

const Wrapper = styled.div`
  padding-top: 8px;
`;

const DayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-top: 16px;
  margin-bottom: 8px;
`;
const IconWrapper = styled.div`
  padding-right: 16px;

  svg {
    height: 16px;
    width: 16px;
  }
`;
const DocItemWrapper = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  text-decoration: none;

  padding: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 0;
  transition: background-color 0.3s;

  cursor: pointer;

  &:hover {
    border-radius: 5px;
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.1)};
  }

  svg {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export function DocRow({ doc, isOneStock }) {
  const {
    id,
    doc_type,
    security_symbol,
    security_name,
    filename,
    is_transcript_available,
    document_url,
    added_by_user_id,
  } = doc;

  let docText = [
    docTypes.MELIUS_COMPANY_REPORT,
    docTypes.MELIUS_INDUSTRY_REPORT,
    docTypes.MELIUS_WEEKLY_VIDEO_SLIDES,
    docTypes.MELIUS_INDUSTRIAL_STRATEGY,
  ].includes(doc_type)
    ? `${doc_type} - ${filename}`
    : isOneStock
    ? `${doc_type}`
    : `${security_symbol} - ${truncate(security_name, 25)} - ${doc_type}`;

  if (added_by_user_id) {
    docText = filename;
  }

  let summaryUrl = `${ROUTES.docSummary}?id=${id}`;
  return (
    <DocItemWrapper href={summaryUrl} target={'_blank'}>
      <IconWrapper>
        <DocumentIcon />
      </IconWrapper>
      <Body3>{docText}</Body3>
    </DocItemWrapper>
  );
}

const formatDayString = (day) => {
  const date = createTimeInstance(day);

  const isToday = date.isSame(createTimeInstance(), 'day');
  const isYesterday = date.isSame(createTimeInstance().subtract(1, 'day'), 'day');
  const isWithinOneWeek = date.isSame(createTimeInstance(), 'week');

  if (isToday) {
    return 'Today';
  } else if (isYesterday) {
    return 'Yesterday';
  } else if (isWithinOneWeek) {
    return formatLocalizedDateTime('day-date', date);
  } else {
    return formatLocalizedDateTime('day-date', date);
  }
};

function DocDay({ day, docs, isOneStock }) {
  const dayDisplay =
    day === 'Uploaded' ? 'Uploaded' : day === 'null' ? 'Unknown Publication Date' : formatDayString(day);
  return (
    <Container>
      <DayWrapper>
        <Body1>{dayDisplay}</Body1>
      </DayWrapper>
      {docs.map((d) => (
        <DocRow key={d.id} doc={d} isOneStock={isOneStock} />
      ))}
    </Container>
  );
}

export const LatestDocsList = ({ rows, isOneStock }) => {
  return (
    <Wrapper>
      {rows.map((data, i) => (
        <Container key={data.day} top={i === 0 ? 0 : 16}>
          <DocDay day={data.day} docs={data.docs} isOneStock={isOneStock} />
        </Container>
      ))}
    </Wrapper>
  );
};
