import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { Page } from '../libs/nvstr-common-ui.es';
import { scrollToTop } from '../utils/usefulFuncs';
import { DocumentUploadStatus } from '../containers/application/DocumentUploadStatus';
import { NewDocUploader } from '../containers/DigitalAnalyst/NewDocUploader';
import { DocumentOrganizer } from '../containers/application/DocumentOrganizer';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const WIDE_WIDTH = 1400;
const NARROW_WIDTH = 800;

function DocumentManagement() {
  const [pageWidth, setPageWidth] = React.useState(NARROW_WIDTH + 'px');

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const onShowWidePage = (isWide) => {
    setPageWidth(isWide ? WIDE_WIDTH + 'px' : NARROW_WIDTH + 'px');
  };
  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <NewDocUploader onShowWidePage={onShowWidePage} />
        <DocumentUploadStatus pageWidth={pageWidth} />
        <Page width={pageWidth}>
          <DocumentOrganizer />
        </Page>
      </Body>
    </PageWrapper>
  );
}

export default DocumentManagement;
