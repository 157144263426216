import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { useColorTheme } from '../../../../../hooks';
import { NODE_HANDLE_IDS } from '../../index';
import { Body5 } from '../../../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';

const DEFAULT_HANDLE_STYLE = {
  top: '13px',
  right: '4px',
};
const DEFAULT_YES_NO_HANDLE_STYLE = {
  right: '4px',
};

const Wrapper = styled.div``;
const NameWrapper = styled.div`
  padding-bottom: 4px;

  * {
    color: ${({ theme, ColorName }) => theme.themeColors[ColorName]};
  }
`;
const PositionName = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 8px;

  top: auto;
  right: 16px;
  bottom: ${({ IndexPosition }) => 8 + (34 * IndexPosition || 0)}px;

  height: 34px;
`;

export default React.memo(({ data, isConnectable, yes, no }) => {
  const colorTheme = useColorTheme();
  const id = yes
    ? NODE_HANDLE_IDS.executionFlowSourceTrue
    : no
    ? NODE_HANDLE_IDS.executionFlowSourceFalse
    : NODE_HANDLE_IDS.executionFlowSource;

  if (yes || no) {
    const name = yes ? 'Yes' : 'No';
    const index = yes ? 1 : 0;
    return (
      <Wrapper>
        <PositionName IndexPosition={index}>
          <NameWrapper>
            <Body5 bold>{name}</Body5>
          </NameWrapper>
        </PositionName>
        <Handle
          type="source"
          id={id}
          position={Position.Right}
          style={
            yes || no
              ? {
                  ...DEFAULT_YES_NO_HANDLE_STYLE,
                  top: 'auto',
                  bottom: 6 + 34 * (index || 0) + 'px',
                }
              : DEFAULT_HANDLE_STYLE
          }
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </Wrapper>
    );
  }
  return (
    <Handle
      type="source"
      id={id}
      position={Position.Right}
      style={DEFAULT_HANDLE_STYLE}
      onConnect={(params) => console.log('handle onConnect', params)}
      isConnectable={isConnectable}
    />
  );
});
