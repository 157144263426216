import { convertHexToRGBA, tornadoColorPalette } from '../libs/nvstr-utils.es';

export const LOG_AMPLITUDE_EVENTS = false;
export const PRODUCT_NAMES = {
  Tornado: 'Tornado',
};

export const PRODUCT_NAME = PRODUCT_NAMES.Tornado;

export const DOMAIN = 'https://tornado.com';

export const PRODUCT_FONTS = {
  [PRODUCT_NAMES.Tornado]: {
    normal: 'Bolton, Arial',
    bold: 'Bolton, Arial',
    thin: 'Bolton, Arial',
  },
};

export const COLOR_THEME_NAMES = {
  lightProfessional: 'light-professional',
  darkProfessional: 'dark-professional',
  custom: 'custom',
};

export const COLOR_THEMES = {
  [COLOR_THEME_NAMES.lightProfessional]: {
    header: tornadoColorPalette.secondary.white,
    appBackground: tornadoColorPalette.primary.oak,
    component: convertHexToRGBA(tornadoColorPalette.secondary.white, 0.9),
    componentNoOpacity: tornadoColorPalette.secondary.white,
    disabled: convertHexToRGBA(tornadoColorPalette.primary.charcoal, 0.05),
    overlay: convertHexToRGBA(tornadoColorPalette.primary.charcoal, 0.6),
    border: convertHexToRGBA(tornadoColorPalette.primary.charcoal, 0.2),
    lowContrastBorder: convertHexToRGBA(tornadoColorPalette.primary.charcoal, 0.05),
    text: tornadoColorPalette.primary.charcoal,
    secondaryText: convertHexToRGBA(tornadoColorPalette.primary.charcoal, 0.3),
    buttonText: tornadoColorPalette.secondary.white,
    proColor: tornadoColorPalette.secondary.brightGreen,
    conColor: tornadoColorPalette.secondary.brightRed,
    neutral: tornadoColorPalette.secondary.lightCharcoal,
    negative: tornadoColorPalette.secondary.red,
    positive: tornadoColorPalette.secondary.brightGreen,
    inputBg: 'transparent',
    avatarBackgroundColor: tornadoColorPalette.secondary.purple,
    buttonColor: tornadoColorPalette.primary.charcoal,
    primaryCtaButton: tornadoColorPalette.primary.charcoal,
    primaryEyeCatchingCtaButton: tornadoColorPalette.secondary.lightBlue,
    badge: tornadoColorPalette.secondary.purple,
    error: tornadoColorPalette.secondary.red,
    warning: tornadoColorPalette.secondary.orange,
    hoverComponentBackground: convertHexToRGBA(tornadoColorPalette.secondary.purple, 0.1),
  },
  [COLOR_THEME_NAMES.darkProfessional]: {
    header: tornadoColorPalette.primary.charcoal,
    appBackground: tornadoColorPalette.primary.charcoal,
    component: tornadoColorPalette.secondary.lightCharcoal,
    componentNoOpacity: convertHexToRGBA(tornadoColorPalette.secondary.lightCharcoal, 1),
    disabled: convertHexToRGBA(tornadoColorPalette.secondary.white, 0.05),
    overlay: convertHexToRGBA(tornadoColorPalette.secondary.white, 0.4),
    border: convertHexToRGBA(tornadoColorPalette.secondary.white, 0.2),
    lowContrastBorder: convertHexToRGBA(tornadoColorPalette.secondary.white, 0.05),
    text: tornadoColorPalette.secondary.white,
    secondaryText: convertHexToRGBA(tornadoColorPalette.secondary.white, 0.4),
    buttonText: tornadoColorPalette.primary.charcoal,
    proColor: tornadoColorPalette.secondary.brightGreen,
    conColor: tornadoColorPalette.secondary.red,
    neutral: tornadoColorPalette.secondary.white,
    negative: tornadoColorPalette.secondary.red,
    positive: tornadoColorPalette.secondary.brightGreen,
    inputBg: 'transparent',
    badge: tornadoColorPalette.secondary.yellow,
    avatarBackgroundColor: tornadoColorPalette.secondary.yellow,
    buttonColor: tornadoColorPalette.secondary.yellow,
    primaryCtaButton: tornadoColorPalette.secondary.yellow,
    primaryEyeCatchingCtaButton: tornadoColorPalette.secondary.lightBlue,
    error: tornadoColorPalette.secondary.red,
    warning: tornadoColorPalette.secondary.orange,
    hoverComponentBackground: convertHexToRGBA(tornadoColorPalette.secondary.yellow, 0.1),
  },
};

export const DEFAULT_COLOR_THEME = COLOR_THEME_NAMES.lightProfessional;
export const FONT = PRODUCT_FONTS[PRODUCT_NAME];
