import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import SentimentDocSelectTool from '../containers/DigitalAnalyst/SentimentDocSelectTool';
import SentimentDocSelectToolV2 from '../containers/DigitalAnalyst/SentimentDocSelectToolV2';
import { useIsV2SentimentEnabled } from '../hooks/user/useCurrentUser';
import { SentimentAnalysisResult } from '../containers/DigitalAnalyst/SentimentAnalysisResult';
import { fedSentimentActions } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const MODES = {
  docSelect: 'ds',
  results: 'results',
};

function DocSelector({ onGoBack, onDocSelectComplete }) {
  const usingV2 = useIsV2SentimentEnabled();

  return usingV2 ? (
    <SentimentDocSelectToolV2 onGoBack={onGoBack} onDocSelectComplete={onDocSelectComplete} />
  ) : (
    <SentimentDocSelectTool onGoBack={onGoBack} onDocSelectComplete={onDocSelectComplete} />
  );
}

function FedSentiment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mode, setMode] = React.useState(MODES.docSelect);
  const [params, setParams] = React.useState({});

  const onGoBackDocSelect = () => navigate(ROUTES.home);
  const onGoBackAnalysisResults = () => setMode(MODES.docSelect);

  const onDocumentSelectComplete = (params) => {
    setParams(params);
    setMode(MODES.results);
  };

  React.useEffect(() => {
    scrollToTop();

    return () => {
      dispatch({
        type: fedSentimentActions.clear,
      });
    };
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        {mode === MODES.docSelect ? (
          <DocSelector onDocSelectComplete={onDocumentSelectComplete} onGoBack={onGoBackDocSelect} />
        ) : (
          <SentimentAnalysisResult
            onGoBack={onGoBackAnalysisResults}
            recordIds={params.recordIds}
            docIds={params.docIds}
            onlyFedSpeakers={params.onlyFed}
          />
        )}
      </Body>
    </PageWrapper>
  );
}

export default FedSentiment;
