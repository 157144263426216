import { useSelector } from 'react-redux';

export const useFedSentiment = () => {
  const store = useSelector((state) => state.fedSentiment);
  return store;
};

export const useFedSentimentCSV = () => {
  return useFedSentiment().csv;
};
