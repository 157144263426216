import React from 'react';
import styled from 'styled-components';
import { Body1, Body5, Container } from '../../libs/nvstr-common-ui.es';
import { logger } from '../../utils/usefulFuncs';
import { TransparentFlatButton } from '../../components/buttons';
import { CloseButton } from '../../components/buttons/CloseButton';
import { DOVISH_COLOR, HAWKISH_COLOR } from '../../constants';
import { SentimentSentence } from './components/SentimentSentence';
import { useFedSentiment } from '../../hooks/features/useFedSentiment';

const ColorWrapper = styled.div`
  background: ${({ Value }) => Value};
  height: 15px;
  width: 40px;
`;
const Color = ({ value }) => {
  return <ColorWrapper Value={value} />;
};
const Legend = () => {
  return (
    <Container row verticallyCenter>
      <Container row verticallyCenter>
        <Color value={HAWKISH_COLOR} />
        <Container left={16}>
          <Body5>Hawkish</Body5>
        </Container>
      </Container>
      <Container left={16} row verticallyCenter>
        <Color value={DOVISH_COLOR} />
        <Container left={16}>
          <Body5>Dovish</Body5>
        </Container>
      </Container>
    </Container>
  );
};
const SpeakerWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 12px;
`;
const StatementsWrapper = styled.div`
  padding-left: 8px;
`;
const TextAnalysisWrapper = styled.div`
  padding-top: 8px;
`;
const HighlightStatsWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  left: 50px;

  padding: 16px 32px;
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};

  color: ${({ theme }) => theme.themeColors.buttonText} !important;
  z-index: 4;

  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 1000;

  * {
    z-index: 6;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  path {
    fill: #fff !important;
  }
`;

const HighlightStats = ({ statements, highlightStatementIndex, onClearHighlight }) => {
  const [show, setShow] = React.useState(false);
  const [statement, setStatement] = React.useState(null);

  React.useEffect(() => {
    if (highlightStatementIndex !== null) {
      setShow(true);
      const s = statements[highlightStatementIndex];
      if (s) {
        setStatement(s);
      } else {
        logger('missing statement');
      }
    } else {
      if (show) {
        setShow(false);
        setStatement(null);
      }
    }
  }, [show, highlightStatementIndex, statements]);

  const handleScrollTo = () => {
    const yOffset = -16;
    const id = 'statement-' + highlightStatementIndex;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    setTimeout(() => {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }, 100);
  };

  const onCloseClick = () => {
    onClearHighlight();
  };

  if (!show || !statement) {
    return null;
  }
  return (
    <HighlightStatsWrapper>
      <CloseButtonWrapper>
        <CloseButton onClick={onCloseClick} />
      </CloseButtonWrapper>
      <Body1 bold>Sentence:</Body1>
      <Container top={8}>
        <Body1 bold>{statement.statement}</Body1>
      </Container>
      <Container top={8}>
        <Body1 bold>Score: </Body1>
        <Body1 bold>{statement.score}</Body1>
      </Container>
      <Container centerAll>
        <TransparentFlatButton onClick={handleScrollTo}>Scroll To Statement</TransparentFlatButton>
      </Container>
    </HighlightStatsWrapper>
  );
};

const SpeakerParagraph = ({ lines, highlightStatementIndex, onClearHighlight }) => {
  const { speaker } = lines[0];
  return (
    <>
      <SpeakerWrapper>
        <Body5>{speaker}</Body5>
      </SpeakerWrapper>
      <HighlightStats
        statements={lines}
        highlightStatementIndex={highlightStatementIndex}
        onClearHighlight={onClearHighlight}
      />
      <StatementsWrapper>
        {lines.map((d, i) => (
          <SentimentSentence
            key={i}
            index={i}
            data={d}
            enableHoverStats={highlightStatementIndex === null}
            isHighlighted={i === highlightStatementIndex}
          />
        ))}
      </StatementsWrapper>
    </>
  );
};

export const SentimentTranscript = ({ docId, highlightStatementIndex, onClearHighlight }) => {
  const { documentTranscriptLookup, documentLookup } = useFedSentiment();
  const data = documentTranscriptLookup[docId];

  if (!docId || !data) {
    return null;
  }
  return (
    <Container>
      <Legend />
      <TextAnalysisWrapper>
        {data?.groupedLinesBySpeaker?.map((d, i) => (
          <SpeakerParagraph
            key={i}
            lines={d}
            highlightStatementIndex={highlightStatementIndex}
            onClearHighlight={onClearHighlight}
          />
        ))}
      </TextAnalysisWrapper>
    </Container>
  );
};
