import React from 'react';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { SearchAutocomplete } from './components/SearchAutocomplete';
import styled from 'styled-components';
import { FooterSeparator } from '../../components/layout/Body';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { useColorTheme } from '../../hooks';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { TransparentFlatButton } from '../../components/buttons';

const CompanyTagSearchInputWrapper = styled.div`
  max-width: 500px;
`;

const SelectableDocumentsRowWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  width: max-content;
`;
const ResultWrapper = styled.div`
  display: inline-block;

  &:last-child {
    margin-bottom: 0px;
  }

  margin-bottom: 8px;
  margin-right: 16px;

  padding: 12px 16px;
  background: ${({ theme }) => convertHexToRGBA(theme.themeColors.lowContrastBorder, 1)};
  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.14s;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.05)};
    border: 1px solid ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.2)};

    color: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
  }

  &.selected {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.2)};

    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }

  svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    margin-left: -4px;
  }
`;

const Result = ({ data, onSelect, onRemoveSelect, selectedDocs }) => {
  const { id, filename } = data;
  const isSelected = selectedDocs?.filter((d) => d.id === id).length > 0;

  const handleClick = () => {
    isSelected ? onRemoveSelect(data) : onSelect(data);
  };

  return (
    <ResultWrapper className={isSelected ? 'selected' : ''} onClick={handleClick}>
      <Body5>{filename}</Body5>
    </ResultWrapper>
  );
};

const useTagSearch = (tag) => {
  const [results, setResults] = React.useState(null);

  const getResults = React.useCallback(async (params) => {
    const { tag, startDate, endDate } = params;
    // const dateFormat = '-L';
    // const startDateApi = formatLocalizedDateTime(dateFormat, startDate);
    // const endDateApi = formatLocalizedDateTime(dateFormat, endDate);
    const URL = `v1/genai_doc_search?doc_tag_id=${tag.id}`;
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data, URL);
    if (status === 200) {
      setResults(data);
    }
  }, []);

  React.useEffect(() => {
    setResults(null);
    getResults({ tag });
  }, [tag]);

  return { results, getResults };
};

const DocSelection = ({ tag, selectedDocs, onSelect, onRemoveSelect }) => {
  const colorTheme = useColorTheme();

  const { results } = useTagSearch(tag);

  const selectAll = () => {
    onSelect(results);
  };

  const unselectAll = () => {
    onRemoveSelect(results);
  };

  return (
    <Container>
      <Container>
        <Body5>Results for "{tag.name}"</Body5>
      </Container>
      <Container>
        {!results && (
          <Container top={48} left={8}>
            <Container row verticallyCenter>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
              <Container left={16}>
                <Body5 bold>Searching...</Body5>
              </Container>
            </Container>
          </Container>
        )}
        {!results ? null : results.length === 0 ? (
          <Container top={16}>
            <Body5>No documents match the filter criteria</Body5>
          </Container>
        ) : (
          <Container top={24}>
            <Container bottom={28} row>
              <TransparentFlatButton onClick={unselectAll}>Unselect All</TransparentFlatButton>
              <Container left={8}>
                <TransparentFlatButton onClick={selectAll}>Select All</TransparentFlatButton>
              </Container>
            </Container>
            <SelectableDocumentsRowWrapper>
              {results.map((r, i) => (
                <Result
                  key={i}
                  data={r}
                  onSelect={onSelect}
                  onRemoveSelect={onRemoveSelect}
                  selectedDocs={selectedDocs}
                />
              ))}
            </SelectableDocumentsRowWrapper>
          </Container>
        )}
      </Container>
    </Container>
  );
};

export const CompanyTagDocumentSelection = ({ selectedDocs, onSelect, onRemoveSelect }) => {
  const [companyTag, setCompanyTag] = React.useState(null);

  const handleCompanyTagSelect = (v) => {
    setCompanyTag(v);
  };

  return (
    <Container>
      <CompanyTagSearchInputWrapper>
        <Container bottom={8}>
          <Body5>Search Company Tags</Body5>
        </Container>
        <SearchAutocomplete onSelect={handleCompanyTagSelect} />
      </CompanyTagSearchInputWrapper>
      <Container top={24}>
        <FooterSeparator></FooterSeparator>
      </Container>

      {companyTag !== null && (
        <DocSelection
          tag={companyTag}
          selectedDocs={selectedDocs}
          onSelect={onSelect}
          onRemoveSelect={onRemoveSelect}
        />
      )}
    </Container>
  );
};
