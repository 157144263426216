import { handle400Statuses, sendApiRequest } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { docsActionTypes } from '../../constants/actionTypes';
import { useCurrentUser } from '../user/useCurrentUser';
import { docTypes } from '../../constants';
import { addUrlParam } from '../../utils';
import { handleTickerGrouping } from '../../utils/mutations';

export function groupDocsByType(docs) {
  if (!docs) return {};

  const grouped = {};
  docs.forEach((d) => {
    const { doc_type: docType } = d;
    if (grouped[docType]) {
      grouped[docType].push(d);
    } else {
      grouped[docType] = [d];
    }
  });
  return grouped;
}

function groupAutoSelectedSECDocsByTicker(docs) {
  if (!docs) return {};

  const lookup = {};

  const groupedDocsByTicker = Object.values(docs);
  groupedDocsByTicker.forEach((g) =>
    g.forEach((doc) => {
      const { security_symbol: ticker, is_recent: autoSelect } = doc;
      if (autoSelect) {
        if (lookup[ticker]) {
          lookup[ticker].push(doc);
        } else {
          lookup[ticker] = [doc];
        }
      }
    })
  );
  return lookup;
}

function groupResearchModeDocsByTicker(docs) {
  const lookup = {};
  if (!docs) return lookup;

  docs.forEach((doc) => {
    const { security_symbol: ticker, doc_type, is_recent } = doc;
    const isResearchModeDoc = [
      docTypes.MELIUS_COMPANY_REPORT,
      docTypes.MELIUS_INDUSTRY_REPORT,
      docTypes.MELIUS_WEEKLY_VIDEO_SLIDES,
      docTypes.MELIUS_INDUSTRIAL_STRATEGY,
    ].includes(doc_type);
    if (isResearchModeDoc) {
      if (lookup[ticker]) {
        lookup[ticker].push(doc);
      } else {
        lookup[ticker] = [doc];
      }
    }
  });
  return lookup;
}

function groupUserUploadedDocsByTicker(data) {
  if (!data) return {};

  const d = {};
  data.forEach((doc) => {
    const { security_symbol } = doc;
    if (security_symbol) {
      if (!d[security_symbol]) {
        d[security_symbol] = [];
      }

      d[security_symbol].push(doc);
    }
  });
  return d;
}

function groupDocsWithNoTicker(docs) {
  if (!docs || !Array.isArray(docs)) {
    return [];
  }
  const noTickerDocs = docs.filter((d) => d.security_symbol === null || d.security_symbol === '');
  return noTickerDocs;
}

function parseUserUploadedGenericDocs(data) {
  if (!data) return [];

  const d = [];
  data.forEach((doc) => {
    const { security_symbol } = doc;
    if (!security_symbol) {
      d.push(doc);
    }
  });
  return d;
}

function groupDocsById(groupedDocsByTicker) {
  if (!groupedDocsByTicker) return [];

  const d = {};
  const arraysTickerDocs = Object.values(groupedDocsByTicker);
  arraysTickerDocs.forEach((tickerDocs) =>
    tickerDocs.forEach((doc) => {
      const { id } = doc;
      d[id] = doc;
    })
  );
  return d;
}

export const parseResponseData = (data, userId) => {
  const docLookupById = groupDocsById(data);

  const userFilesByTicker = groupUserUploadedDocsByTicker(data[userId]);
  const userGenericFiles = parseUserUploadedGenericDocs(data[userId]);
  const autoSelectedDocs = groupAutoSelectedSECDocsByTicker(data);
  const researchModeDocs = groupResearchModeDocsByTicker(data[userId]);
  const miscDocs = groupDocsWithNoTicker(data[userId]);
  const tickerFiles = {};

  Object.keys(data).forEach((ticker) => {
    // don't add to doc lookup if only user generated content
    if (ticker !== userId) {
      const docs = data[ticker];
      // add all user uploaded docs
      const userDocsForTicker = userFilesByTicker[ticker];
      if (userDocsForTicker?.length > 0) {
        userDocsForTicker.forEach((d) => docs.push(d));
      }
      // add all no assigned ticker docs
      if (miscDocs?.length > 0) {
        // HACK: auto set is_recent to false, so they don't auto select
        miscDocs.map((d) => ({ ...d, is_recent: false })).forEach((d) => docs.push(d));
      }
      tickerFiles[ticker] = docs;
    }
  });

  return {
    researchModeDocs,
    docLookupById,
    userGenericFiles,
    userFilesByTicker,
    autoSelectedDocs,
    tickerFiles,
    miscDocs,
  };
};

export const useGPTEmbeddingDocs = () => {
  const currentUserId = useCurrentUser().id;

  const dispatch = useDispatch();
  const { tickerFiles, docLookupById, miscDocs, userFilesByTicker, userGenericFiles, macroDocs } = useSelector(
    (state) => state.docs
  );

  const getTickerDocData = async (ticker, isResearchMode) => {
    let URL = `v1/genai_tickers_filenames`;
    let URLParams = '';

    if (ticker === undefined || ticker === null) {
      const errorMessage = 'missing ticker';
      return Promise.reject(errorMessage);
    }

    if (ticker === '') {
      URLParams = addUrlParam(URLParams, 'user_uploaded=true');
    } else {
      URLParams = addUrlParam(URLParams, `ticker=${ticker}`);
    }
    if (isResearchMode) {
      URLParams = addUrlParam(URL, `user_mode=research_query`);
    }
    URL += URLParams;

    const { status, data } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    if (status === 200) {
      const mutatedData = handleTickerGrouping(data, ticker, currentUserId);
      const {
        researchModeDocs,
        autoSelectedDocs,
        userFilesByTicker,
        userGenericFiles,
        tickerFiles,
        docLookupById,
        miscDocs,
      } = parseResponseData(mutatedData, currentUserId);
      dispatch({
        type: docsActionTypes.addDocs,
        payload: {
          tickerFiles,
          miscDocs,
          userFilesByTicker,
          docLookupById,
          userGenericFiles,
          researchModeDocs,
        },
      });
      return { autoSelectedDocs, userFilesByTicker, docLookupById, tickerFiles, miscDocs, researchModeDocs };
    } else {
      return null;
    }
  };

  return {
    docLookupById,
    docsLookupByTicker: tickerFiles,
    miscDocs,
    macroDocs,
    userFilesByTicker,
    userGenericFiles,
    getTickerDocData,
  };
};
