import React from 'react';
import styled from 'styled-components';
import { Body5, Container, Page } from '../../libs/nvstr-common-ui.es';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { DragDrop } from '../../components/UI/DragAndDropUploader';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';
import { AI_TOOLS } from '../../constants';
import { useDispatch } from 'react-redux';
import { uploadFilesActions } from '../../constants/actionTypes';
import { UploadFilesList } from './UploadFilesList';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  h3 {
    text-transform: none;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 300px;
    width: 100%;
    padding: 16px;
  }
`;
const EnterManuallyBtnWrapper = styled.div`
  button {
    padding: 6px 12px;
  }
`;
const Label = styled.div`
  padding-bottom: 8px;
`;
const CheckWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;
const FileUploadProgressWrapper = styled.div`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.proColor};
    }
  }

  .warning-icon {
    path {
      fill: ${({ theme }) => theme.themeColors.error};
    }
  }
`;
const RetryButtonWrapper = styled.div`
  button {
    padding: 4px 12px;
  }
`;
const SmallSpinner = styled.div`
  svg {
    height: 28px;
    width: 28px;
  }
`;

function addFileDefaults(fileOrFiles) {
  if (Array.isArray(fileOrFiles)) {
    return fileOrFiles.map((f) => ({
      ...f,
      docType: null,
      company: null,
    }));
  } else {
    return {
      ...fileOrFiles,
      docType: null,
      company: null,
    };
  }
}

export const FileUploader = ({ onGoBack }) => {
  const dispatch = useDispatch();

  const toolsAvailable = useCurrentUserToolsAvailable();
  const isExcelUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.EXCEL_UPLOAD);
  const isMsDocUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.MS_DOC_UPLOAD);
  const isPPTUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.PPT_UPLOAD);

  const handleAddFiles = (fileOrFiles) => {
    dispatch({
      type: uploadFilesActions.addFiles,
      payload: addFileDefaults(fileOrFiles),
    });
  };

  return (
    <Page width={'1400px'}>
      <BackArrowButton onClick={onGoBack} />
      <Container>
        <FormWrapper>
          <Container top={40}>
            <h3>Upload Files</h3>
            <Container top={8}>
              <Body5>
                Drag and drop files. You can change the file name, assign a company, and assign document type for
                document organization.
              </Body5>
            </Container>
          </Container>
          <Container top={32}>
            <DragDrop
              onAddFiles={handleAddFiles}
              docx={isMsDocUploadToolAvailable}
              pptx={isPPTUploadToolAvailable}
              xls={isExcelUploadToolAvailable}
              // csv={isCsvEnabled}
            />
          </Container>
          <Container top={16}>
            <UploadFilesList />
          </Container>
        </FormWrapper>
      </Container>
    </Page>
  );
};
