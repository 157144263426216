import React from 'react';
import styled from 'styled-components';
import { GRID_MODES } from './index';
import { Body5, H5 } from '../../libs/nvstr-common-ui.es';
import InfoIconTooltip from '../../components/UI/InfoIconTooltip';
import { InfoIconTypes } from '../../constants/infoIcons';

const Heading = styled.div`
  padding: 0 0 4px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  h5 {
    padding-right: 8px;
  }
`;

export const Header = ({ docMode: mode }) => {
  if (mode === GRID_MODES.fed) {
    return (
      <>
        <Heading>
          <H5>Fed Release Analysis</H5>
          <InfoIconTooltip type={InfoIconTypes.FED_RELEASE} />
        </Heading>
        <Body5>
          Select documents and questions for analysis. Click the document name to view the original document.
        </Body5>
      </>
    );
  }
  if (mode === GRID_MODES.liquidityTerms) {
    return (
      <>
        <Heading>
          <H5>Liquidity Terms</H5>
        </Heading>
        <Body5>
          Select documents and questions for analysis. Click the document name to view the original document.
        </Body5>
      </>
    );
  }
  if (mode === GRID_MODES.credit) {
    return (
      <>
        <Heading>
          <H5>Credit Agreement Analysis</H5>
          {/*<InfoIconTooltip type={InfoIconTypes.FED_RELEASE} />*/}
        </Heading>
        <Body5>Add a new column for each document to analyze, and a new row for each question to ask</Body5>
      </>
    );
  }
  if (mode === GRID_MODES.ppm) {
    return (
      <>
        <Heading>
          <H5>PPM Analysis</H5>
          {/*<InfoIconTooltip type={InfoIconTypes.FED_RELEASE} />*/}
        </Heading>
        <Body5>Add a new column for each document to analyze, and a new row for each question to ask</Body5>
      </>
    );
  }

  return (
    <>
      <Heading>
        <H5>Document Table Analysis</H5>
        {/*<InfoIconTooltip type={InfoIconTypes.FED_RELEASE} />*/}
      </Heading>
      <Body5>Add a new column for each document to analyze, and a new row for each question to ask</Body5>
    </>
  );
};
