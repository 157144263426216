import React from 'react';
import styled from 'styled-components';
import { Body5 } from '../../../libs/nvstr-common-ui.es';
import { ROUTES } from '../../../constants/routes';
import useWindowSize from '../../../hooks/application/useWindowSize';
import { SeriesTableRow } from '../../gridMode/SeriesTableRow';
import { AddPromptCell } from '../../gridMode/AddPromptCell';

const Wrapper = styled.div`
  //
`;
const TableWrapper = styled.table`
  width: 100%;
  margin-top: 24px;

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }

  .question-column {
    //min-width: 299px;
  }

  th,
  td {
    vertical-align: top;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  }
`;
const DocLink = styled.tr`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
const TableHeadingCellWrapper = styled.th`
  width: ${({ cellWidth }) => cellWidth + 'px'};
`;

const calcTableWidth = (windowWidth) => windowWidth * 0.95 - 48 - 16;
const calcCellWidth = (windowWidth, cellCount) => Math.floor(calcTableWidth(windowWidth) / cellCount);

function DocHeading({ data: docs, cellWidth }) {
  const onViewOriginalDoc = (doc) => {
    const { document_url, id } = doc;

    if (document_url?.length > 0) {
      window.open(document_url, '_blank');
    } else {
      window.open(ROUTES.docViewer + `?id=${id}`, '_blank');
    }
  };

  return (
    <TableHeadingCellWrapper>
      {docs.map((doc) => (
        <DocLink key={doc.id} onClick={() => onViewOriginalDoc(doc)}>
          <Body5 bold>{doc.filename}</Body5>
        </DocLink>
      ))}
    </TableHeadingCellWrapper>
  );
}

export const SeriesTable = ({ tableRows, docColumns, tool, docMode }) => {
  const { width: windowWidth } = useWindowSize();
  const cellCount = docColumns.length;
  const cellWidth = calcCellWidth(windowWidth, cellCount);
  return (
    <Wrapper>
      <TableWrapper>
        <thead>
          <tr>
            <th className={'question-column'}>
              <Body5 bold>Question</Body5>
            </th>
            {docColumns.map((d, i) =>
              d === null ? null : <DocHeading key={i} data={d} index={i} cellWidth={cellWidth} />
            )}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((r, i) => (
            <SeriesTableRow
              key={r.prompt.prompt}
              index={i}
              data={r}
              cellWidth={cellWidth}
              tool={tool}
              docMode={docMode}
            />
          ))}
          <tr>
            <td colSpan={docColumns.length}>
              <AddPromptCell />
            </td>
          </tr>
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};
