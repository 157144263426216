import React from 'react';
import { includes, logger, scrollToTop } from '../utils/usefulFuncs';
import { Body1, Body5, Container, H5, Page } from '../libs/nvstr-common-ui.es';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import styled from 'styled-components';
import { FlatButton, TransparentFlatButton } from '../components/buttons';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { filterDocDuplicates } from '../reducers/docs';
import { Modal } from '../components/layout/Modal';
import { CloseButton } from '../components/buttons/CloseButton';
import { SpeakerDocumentSelection } from '../containers/DigitalAnalyst/SpeakerDocumentSelection';
import { DocumentSelection } from '../containers/DigitalAnalyst/DocumentSelection';
import { SelectedDocuments } from '../containers/DigitalAnalyst/components/SelectedDocuments';
import { useDocs } from '../hooks/features/useDocs';
import { TextSearchResults } from '../containers/DigitalAnalyst/components/TextSearchResults';
import ErrorText from '../components/UI/ErrorText';
import { DocumentProvider } from '../providers/DocumentProvider';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
const SearchTermInputWrapper = styled.div`
  padding: 8px 0 0 0;

  display: grid;
  grid-template-columns: 1fr 108px; /* Adjust sizes as needed */

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    height: 40px;

    padding: 9px 16px;
    resize: none;
  }

  button {
    margin-left: 24px;
    padding: 11px 14px;
  }
`;

const defaultAdvancedSettings = {
  seeContext: false,
};

const searchDocTypes = {
  DOC: 'doc',
  SPEAKER: 'speaker',
};

const SearchTermInput = ({ index, savedValue, onSearchTermEnter, onDeleteTerm }) => {
  const ref = React.useRef(null);
  const [value, setValue] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  React.useEffect(() => {
    function handleKeyPress(event) {
      if (!isFocused) {
        return;
      }
      if (event.key === 'Enter') {
        onEnter();
      }
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFocused, value]);

  const hc = (e) => setValue(e.target.value);

  const hf = () => setIsFocused(true);
  const hb = () => {
    setIsFocused(false);
    onEnter();
  };

  const onDelete = () => {
    onDeleteTerm(index);
  };
  const onEnter = () => {
    if (value === '') {
      return;
    }

    onSearchTermEnter(value, index);
    if (index === null) {
      setValue('');
      ref?.current?.focus();
    }
  };

  return (
    <SearchTermInputWrapper>
      <input ref={ref} value={value} onChange={hc} onFocus={hf} onBlur={hb} />
      {isFocused && <FlatButton onClick={onEnter}>+ Enter</FlatButton>}
      {!isFocused && index !== null && <TransparentFlatButton onClick={onDelete}>Delete</TransparentFlatButton>}
    </SearchTermInputWrapper>
  );
};

export function TextSearch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: true });
  const [filters] = React.useState({ isFedMode: true });

  const [searchTerms, setSearchTerms] = React.useState([]);
  const [selectedDocs, setSelectedDocs] = React.useState([]);

  const [submittedSearchTerms, setSubmittedSearchTerms] = React.useState(null);
  const [submittedDocs, setSubmittedDocs] = React.useState([]);

  const [error, setError] = React.useState(null);

  const [isSelectingDocs, setIsSelectingDocs] = React.useState(false);
  const [docSelectMode, setDocSelectMode] = React.useState(searchDocTypes.SPEAKER);
  const onAfterDocReceived = (docs) => {};

  const { docs } = useDocs(filters, onAfterDocReceived);

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  const onSubmit = async () => {
    if (searchTerms.length === 0) {
      setError('Please enter search terms');
      return;
    }
    if (selectedDocs.length === 0) {
      setError('Please select at least one document to search');
      return;
    }

    setSubmittedSearchTerms(null);
    setError(null);
    setTimeout(() => {
      setSubmittedSearchTerms(searchTerms.filter((t) => t !== ''));
    }, 100);
    setSubmittedDocs(selectedDocs);
    scrollToResults();
  };
  const onClear = async () => {
    scrollToTop(100);
    setSearchTerms([]);
    setSelectedDocs([]);
    setError(null);
    setSubmittedSearchTerms(null);
    setSubmittedDocs([]);
  };

  const scrollToResults = () => {
    try {
      const delay = 300;
      const id = 'text-search-results';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, delay);
      }
    } catch (e) {
      logger('error', e);
    }
  };
  const onSelectedDocsChange = (t) => setSelectedDocs(t);

  const handleAddDocument = (v) => {
    if (Array.isArray(v)) {
      onSelectedDocsChange((prevState) => filterDocDuplicates([...prevState, ...v]));
    } else {
      onSelectedDocsChange((prevState) => filterDocDuplicates([...prevState, v]));
    }
  };

  const handleRemoveDocument = (filenameOrFilenames) => {
    console.log({ filenameOrFilenames });
    if (Array.isArray(filenameOrFilenames)) {
      onSelectedDocsChange((prevState) => prevState.filter((d) => !includes(filenameOrFilenames, d.filename)));
    } else {
      onSelectedDocsChange((prevState) => prevState.filter((d) => d.filename !== filenameOrFilenames));
    }
  };

  const onSelectDocumentsClick = () => setIsSelectingDocs(true);

  const onSearchTermEnter = (v, index) => {
    if (v === '') return;

    if (index === null) {
      setSearchTerms([...searchTerms, v]);
    } else {
      const nextTerms = [...searchTerms];
      nextTerms[index] = v;
      setSearchTerms(nextTerms);
    }
  };
  const onDeleteTerm = (index) => {
    const nextTerms = [...searchTerms];
    setSearchTerms(nextTerms.filter((t, i) => i !== index));
  };

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <Container>
            <BackArrowButton onClick={onGoBack} />
            <Container top={36}>
              <H5>Fed Document Text Search</H5>
              {/*<InfoIconTooltip type={isFedGpt ? InfoIconTypes.FED_GPT : InfoIconTypes.QUERY_TOOL} />*/}
              {/*<AdvancedSettingsButton fields={defaultAdvancedSettingsFields} />*/}
            </Container>
            {isSelectingDocs && (
              <Modal onDismiss={() => setIsSelectingDocs(false)} modalProps={modalProps}>
                <Container row verticallyCenter spaceBetween>
                  <Container row verticallyCenter spaceBetween fullWidth>
                    <Container>
                      <Body1 bold>Select Documents</Body1>
                    </Container>
                    <Container>
                      {docSelectMode === searchDocTypes.SPEAKER ? (
                        <FlatButton onClick={() => setDocSelectMode(searchDocTypes.DOC)}>
                          Search by document type
                        </FlatButton>
                      ) : (
                        <FlatButton onClick={() => setDocSelectMode(searchDocTypes.SPEAKER)}>
                          Search by Speaker
                        </FlatButton>
                      )}
                    </Container>
                  </Container>
                  <Container top={8} left={16}>
                    <CloseButton onClick={() => setIsSelectingDocs(false)} />
                  </Container>
                </Container>
                <Container top={24}>
                  {docSelectMode === searchDocTypes.SPEAKER ? (
                    <SpeakerDocumentSelection
                      onSelect={handleAddDocument}
                      onRemoveSelect={handleRemoveDocument}
                      selectedDocs={selectedDocs}
                    />
                  ) : (
                    <DocumentSelection
                      docs={docs}
                      filters={filters}
                      onSelect={handleAddDocument}
                      onRemoveSelect={handleRemoveDocument}
                      selectedDocs={selectedDocs}
                      onAfterDocReceived={onAfterDocReceived}
                      onClose={() => setIsSelectingDocs(false)}
                    />
                  )}
                </Container>
              </Modal>
            )}

            <Container top={24}>
              <Body5 bold>Enter Search Terms:</Body5>
            </Container>
            <Container top={8}>
              {searchTerms.map((s, i) => (
                <SearchTermInput
                  key={i}
                  index={i}
                  savedValue={s}
                  onSearchTermEnter={onSearchTermEnter}
                  onDeleteTerm={onDeleteTerm}
                />
              ))}
            </Container>
            <Container>
              <SearchTermInput
                index={null}
                savedValue={''}
                onSearchTermEnter={onSearchTermEnter}
                onDeleteTerm={onDeleteTerm}
              />
            </Container>

            <Container top={16}>
              <SelectedDocuments
                onSelectDocumentsClick={onSelectDocumentsClick}
                selectedDocs={selectedDocs}
                onSelectedDocsChange={onSelectedDocsChange}
                showAddAllDocumentsAction={false}
              />
            </Container>

            <Container top={48}>
              {error && (
                <Container bottom={8} centerAll>
                  <ErrorText>{error}</ErrorText>
                </Container>
              )}
              <Container style={{ textAlign: 'center' }}>
                <FlatButton onClick={onSubmit} fullWidth>
                  Submit
                </FlatButton>
              </Container>
              <Container top={4} style={{ textAlign: 'center' }}>
                <TransparentFlatButton onClick={onClear} fullWidth>
                  Clear
                </TransparentFlatButton>
              </Container>
            </Container>
          </Container>
        </Page>
        <a id={'text-search-results'} />
        <DocumentProvider documents={submittedDocs}>
          {submittedSearchTerms !== null &&
            submittedSearchTerms.map((t, i) => (
              <Page key={i + t} noAutoScroll>
                <TextSearchResults selectedDocs={selectedDocs} searchTerm={t} index={i} />
              </Page>
            ))}
        </DocumentProvider>
      </Body>
    </PageWrapper>
  );
}
