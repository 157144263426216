import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { useColorTheme } from '../../../../../hooks';
import { NODE_HANDLE_IDS } from '../../index';

const DEFAULT_HANDLE_STYLE = {
  top: '13px',
  left: '4px',
};
export default React.memo(({ data, isConnectable }) => {
  const colorTheme = useColorTheme();
  return (
    <Handle
      type="target"
      id={NODE_HANDLE_IDS.executionFlowTarget}
      position={Position.Left}
      style={{ ...DEFAULT_HANDLE_STYLE, background: colorTheme.text }}
      isConnectable={isConnectable}
    />
  );
});
