import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { ExtraWideBody } from '../components/layout/Body';
import { addEdge, Background, Controls, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import { Page } from '../libs/nvstr-common-ui.es';
import '@xyflow/react/dist/style.css';
import {
  createFlowNode,
  EDGE_TYPES,
  generateCreationContextFrom,
  getCreateNodeTypeFromHandleId,
  NODE_COMPONENT_TYPES,
  NODE_HANDLE_IDS,
  NODE_HANDLE_TYPES,
  NODE_TYPES,
} from '../containers/flow/nodes';
import { executeFlowQuery } from '../services/flowEngine';
import { useColorTheme } from '../hooks';
import { DocSelectionModalController } from '../containers/flow/DocSelectionModalController';
import ConnectionLine from '../containers/flow/nodes/components/ConnectionLine';
import { logger } from '../utils/usefulFuncs';
import { CompanySelectionModalController } from '../containers/flow/CompanySelectionModalController';

const PageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const FlowWrapper = styled.div`
  position: relative;
  height: calc(98vh - 106px);
  width: 100%;
  max-width: 2440px;

  .react-flow__handle {
    min-height: 8px;
    min-width: 20px;

    border-radius: 3px;
  }

  .react-flow__handle-right,
  .react-flow__handle-left {
    min-height: 20px;
    min-width: 8px;
  }
`;

const initialNodes = [];
const initialEdges = [];

let cachedNodes = initialNodes;
let cachedEdges = initialEdges;
let connectionStartSourceNode = null;

const defaultEdgeOptions = {
  type: 'custom',
};

function calcPositionFromConnectEvent(event, viewport) {
  const { clientX, clientY } = event;
  const xOffset = -40;
  const yOffset = 10;
  const canvasX = (clientX - viewport.x - xOffset) / viewport.zoom - 80;
  const canvasY = (clientY - viewport.y - yOffset) / viewport.zoom - 80;
  return { x: canvasX, y: canvasY };
}

const generateConnectionLineStyle = (colorTheme) => ({
  stroke: colorTheme.text,
  strokeWidth: 3,
  strokeDasharray: '10,4',
});

export function QueryEngine() {
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null);

  const colorTheme = useColorTheme();
  const [companySelectionModalInstance, setCompanySelectionModalInstance] = React.useState(null);
  const [docSelectionModalInstance, setDocSelectionModalInstance] = React.useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [viewport, setViewport] = React.useState({ x: 0, y: 0, zoom: 1 });

  React.useEffect(() => {
    const delay = 700;
    setTimeout(() => {
      const startNode = createFlowNode(NODE_TYPES.StartNode);
      startNode.position = { x: 24, y: 24 };
      onAddNode(startNode);
    }, delay);
  }, []);

  React.useEffect(() => {
    // logger('node state', nodes, null);
  }, [nodes]);
  React.useEffect(() => {
    // logger('edge state', edges, null);
  }, [edges]);

  React.useEffect(() => {
    cachedNodes = nodes;
    cachedEdges = edges;

    return () => {
      cachedNodes = [];
      cachedEdges = [];
      connectionStartSourceNode = null;
    };
  }, [nodes, edges]);

  const attemptCreateEdgeForNode = (targetNode, sourceNodeId, handleParams) => {
    const { handleId, handleType } = handleParams;
    logger('attemptCreateEdgeForNode', { targetNode, sourceNodeId, handleParams });
    const newEdge = {
      id: `e${sourceNodeId}-${targetNode.id}`,
    };

    if (
      [
        NODE_HANDLE_IDS.executionFlowTarget,
        NODE_HANDLE_IDS.executionFlowSource,
        NODE_HANDLE_IDS.executionFlowSourceTrue,
        NODE_HANDLE_IDS.executionFlowSourceFalse,
      ].includes(handleId)
    ) {
      if (handleId === NODE_HANDLE_IDS.executionFlowSource) {
        newEdge.source = sourceNodeId;
        newEdge.sourceHandle = NODE_HANDLE_IDS.executionFlowSource;

        newEdge.target = targetNode.id;
        newEdge.targetHandle = NODE_HANDLE_IDS.executionFlowTarget;
      }

      if (handleId === NODE_HANDLE_IDS.executionFlowTarget) {
        newEdge.target = sourceNodeId;
        newEdge.targetHandle = NODE_HANDLE_IDS.executionFlowSource;

        newEdge.source = targetNode.id;
        newEdge.sourceHandle = NODE_HANDLE_IDS.executionFlowTarget;
      }
      if (handleId === NODE_HANDLE_IDS.executionFlowSourceTrue) {
        newEdge.target = targetNode.id;
        newEdge.targetHandle = NODE_HANDLE_IDS.executionFlowTarget;

        newEdge.source = sourceNodeId;
        newEdge.sourceHandle = NODE_HANDLE_IDS.executionFlowSourceTrue;
      }
      if (handleId === NODE_HANDLE_IDS.executionFlowSourceFalse) {
        newEdge.target = targetNode.id;
        newEdge.targetHandle = NODE_HANDLE_IDS.executionFlowTarget;

        newEdge.source = sourceNodeId;
        newEdge.sourceHandle = NODE_HANDLE_IDS.executionFlowSourceFalse;
      }
    } else {
      newEdge.type = 'custom'; // for sdk when using custom handles

      if (handleType === NODE_HANDLE_TYPES.target) {
        newEdge.target = sourceNodeId;
        newEdge.source = targetNode.id;
        newEdge.targetHandle = handleId;
        newEdge.sourceHandle = handleId;
      }

      if (handleType === NODE_HANDLE_TYPES.source) {
        newEdge.source = sourceNodeId;
        newEdge.target = targetNode.id;
        newEdge.targetHandle = handleId;
        newEdge.sourceHandle = handleId;
      }
    }

    logger('creating edge', newEdge);
    setEdges((eds) => addEdge(newEdge, eds));
  };

  const onMove = React.useCallback((event, { x, y, zoom }) => {
    setViewport({ x, y, zoom });
  }, []);

  const onConnect = React.useCallback((params) => {
    return setEdges((eds) => addEdge(params, eds));
  }, []);

  const replaceNode = (sourceNodeId, currentNodeId, type, params) => {
    let nodeToReplace = null;
    let sourceNode = null;
    setNodes((prevState) => {
      const updatedNodes = prevState.filter((node) => node.id !== currentNodeId);
      nodeToReplace = prevState.find((node) => node.id === currentNodeId);
      sourceNode = prevState.find((node) => node.id === sourceNodeId);
      return updatedNodes;
    });

    const newNode = createFlowNode(type);
    newNode.position = nodeToReplace.position;
    onAddNode(newNode);

    logger('replacing node', newNode, {
      sourceNodeId,
      currentNodeId,
      type,
      params,
      replacedNode: nodeToReplace,
    });

    attemptCreateEdgeForNode(newNode, sourceNodeId, params);
  };

  const onAddNode = (node) => {
    const getNewNodeOptimalPosition = (nodes) => {
      const lastNode = nodes[nodes.length - 1];
      const lastNodePosition = lastNode?.position || { x: 24, y: 24 };
      return {
        x: lastNodePosition.x - 200,
        y: lastNodePosition.y,
      };
    };

    setNodes((prevNodes) => {
      node.data.actions.updateData = (data) => updateNodeData(node.id, data);
      node.data.actions.updateDataCb = (cb) => updateNodeDataCb(node.id, cb);
      node.data.actions.updateAnswer = (answer) => updateNodeData(node.id, { answer });
      node.data.actions.updateAnswers = (data) => updateNodeAnswersData(node.id, data);

      node.data.actions.onRemove = () => onRemove(node.id);
      node.data.actions.onRemoveAllLinks = () => onRemoveAllLinks(node.id);

      if (node.type === NODE_TYPES.StartNode) {
        node.data.actions.onRunOutput = () => onRunOutput(node.id);
        node.data.actions.onClearAnswers = () => onClearAnswers(node.id);
      }
      if (node.type === NODE_TYPES.DocListNode) {
        node.data.actions.onShowDocumentSelection = () => docSelectionModalInstance.showModal(node.id);
        node.data.selectedDocs = [];
      }
      if (node.type === NODE_TYPES.CompanyListNode) {
        node.data.actions.onShowCompanySelection = () => companySelectionModalInstance.showModal(node.id);
        node.data.selectedCompanies = [];
      }
      if (node.type === NODE_TYPES.CreateNode) {
        node.data.actions.replaceNode = (sourceNodeId, currentNodeId, type, params) =>
          replaceNode(sourceNodeId, currentNodeId, type, params);
      }

      if (!node.position) {
        const { x, y } = getNewNodeOptimalPosition(prevNodes);
        node.position = { x, y };
      }
      logger('adding node', node);
      return [...prevNodes, node];
    });
  };

  const onConnectStart = React.useCallback(
    (event, params) => {
      logger('onConnectStart', { event, params });
      const node = nodes.find((n) => n.id === params.nodeId);
      connectionStartSourceNode = node;
      const { handleId, handleType } = params;
      const handleParams = {
        handleId,
        handleType,
      };
      connectionStartSourceNode.handleParams = handleParams;
    },
    [nodes]
  );

  const onConnectEnd = (event) => {
    const { target } = event;

    const connectedToNodeId = target?.getAttribute('data-nodeid');
    const connectedToNode = nodes.find((node) => node.id === connectedToNodeId); // to find the node that was connected to

    logger('connect end', connectedToNode, connectionStartSourceNode);

    const handleParams = {
      handleId: connectionStartSourceNode.handleParams.handleId,
      handleType: connectionStartSourceNode.handleParams.handleType,
    };
    const nodeType = getCreateNodeTypeFromHandleId(handleParams.handleId, handleParams.handleType);
    const hasAutoNodeType = nodeType !== null;
    if (hasAutoNodeType) {
      const newNode = createFlowNode(nodeType);
      newNode.position = calcPositionFromConnectEvent(event, viewport);
      onAddNode(newNode);
      attemptCreateEdgeForNode(newNode, connectionStartSourceNode.id, handleParams);

      connectionStartSourceNode = null;
      return true;
    }
    if (!connectedToNode && !!connectionStartSourceNode) {
      const creationContext = generateCreationContextFrom(connectionStartSourceNode);
      if (creationContext) {
        const newNode = createFlowNode(NODE_TYPES.CreateNode);
        newNode.data.sourceNodeId = connectionStartSourceNode.id;
        newNode.data.creationContext = creationContext;
        newNode.data.connectionStartSourceNode = connectionStartSourceNode;
        newNode.position = calcPositionFromConnectEvent(event, viewport);
        onAddNode(newNode);

        connectionStartSourceNode = null;
        return true;
      }
    }
  };
  const onCompanySelectionModalInit = (ref) => {
    setCompanySelectionModalInstance(ref);
  };

  const onDocSelectionModalInit = (ref) => {
    setDocSelectionModalInstance(ref);
  };

  const onRunOutput = (nodeId) => {
    executeFlowQuery(
      cachedNodes.find((n) => n.id === nodeId),
      cachedNodes,
      cachedEdges
    );
  };

  const onRemove = (nodeId) => {
    setNodes((prevNodes) => prevNodes.filter((f) => f.id !== nodeId));
  };

  const onRemoveAllLinks = (nodeId) => {
    setEdges((prevEdges) =>
      prevEdges.filter((e) => {
        if (e.target === nodeId) return false;
        if (e.source === nodeId) return false;
        return true;
      })
    );
  };

  const updateNodeData = (id, data) => {
    setNodes((prevNodes) => {
      const index = prevNodes.findIndex((f) => f.id === id);
      const nextState = [...prevNodes];
      nextState[index] = {
        ...nextState[index],
        data: {
          ...nextState[index].data,
          ...data,
        },
      };
      return nextState;
    });
  };

  const updateNodeDataCb = (id, updaterCallback) => {
    setNodes((prevNodes) => {
      const updatingNodeIndex = prevNodes.findIndex((f) => f.id === id);
      const copyNodesState = [...prevNodes];
      const currentNodeState = copyNodesState[updatingNodeIndex];
      copyNodesState[updatingNodeIndex] = {
        ...currentNodeState,
        data: updaterCallback(currentNodeState.data),
      };
      return copyNodesState;
    });
  };

  const onClearAnswers = () => {
    setNodes((prevNodes) => {
      const nextState = prevNodes.map((n) => {
        const updatedNode = { ...n, data: { ...n.data } };
        updatedNode.data.answer = null;
        updatedNode.data.answers = {};
        if (updatedNode.type === NODE_TYPES.StartNode) {
          updatedNode.data.isComplete = false;
        }
        return updatedNode;
      });
      return nextState;
    });
  };

  const updateNodeAnswersData = (id, data) => {
    setNodes((prevNodes) => {
      const index = prevNodes.findIndex((f) => f.id === id);
      const nextState = [...prevNodes];
      nextState[index] = {
        ...nextState[index],
        data: {
          ...nextState[index].data,
          answers: {
            ...(nextState[index].data.answers || {}),
            ...data,
          },
        },
      };
      return nextState;
    });
  };

  return (
    <>
      <Header />
      <ExtraWideBody withFooter>
        <PageWrapper>
          <Page width={'2440px'} noPadding>
            <DocSelectionModalController
              onInit={onDocSelectionModalInit}
              nodes={nodes}
              updateNodeData={updateNodeData}
            />
            <CompanySelectionModalController
              onInit={onCompanySelectionModalInit}
              nodes={nodes}
              updateNodeData={updateNodeData}
            />
            <FlowWrapper>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={NODE_COMPONENT_TYPES}
                edgeTypes={EDGE_TYPES}
                defaultEdgeOptions={defaultEdgeOptions}
                connectionLineStyle={generateConnectionLineStyle(colorTheme)}
                onInit={setReactFlowInstance}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                connectionLineComponent={ConnectionLine}
                onMove={onMove}
              >
                <Controls />
                <Background variant="dots" gap={12} size={1} />
              </ReactFlow>
            </FlowWrapper>
          </Page>
        </PageWrapper>
      </ExtraWideBody>
    </>
  );
}
