import Papa from 'papaparse';
import { convertHexToRGBA, createTimeInstance, moment } from '../../libs/nvstr-utils.es';
import { logError, logger, truncateStringWithDate } from '../usefulFuncs';

const usedColors = [
  '#222',
  '#3477eb',
  '#34eb5e',
  '#eba134',
  '#ebe134',
  '#eb4334',
  '#6e34eb',
  '#c334eb',
  '#f291e2',
  '#8e89f5',
  '#7db1e8',
  '#c2ebbe',
  '#125c0b',
  '#e6d0aa',
  '#750a05',
  '#999',
  '#ddd',
  '#f5f097',
  '#053b75',
  '#4a3209',
  '#380704',
  '#494a09',
];

function cleanAggregationData(data) {
  function clean(data) {
    // remove record_id
    const result = {};
    const listOfAggregationLookups = Object.values(data);
    listOfAggregationLookups.forEach((agg) => {
      Object.entries(agg).forEach((el) => {
        const [k, v] = el;
        result[k] = v;
      });
    });
    return result;
  }

  const { aggregation_by_speaker, aggregation_by_doc, aggregation_by_doc_and_speaker } = data;
  const cleaned_aggregation_by_speaker = clean(aggregation_by_speaker);
  const cleaned_aggregation_by_doc = clean(aggregation_by_doc);
  const cleaned_aggregation_by_doc_and_speaker = clean(aggregation_by_doc_and_speaker);
  return {
    aggregation_by_speaker: cleaned_aggregation_by_speaker,
    aggregation_by_doc: cleaned_aggregation_by_doc,
    aggregation_by_doc_and_speaker: cleaned_aggregation_by_doc_and_speaker,
  };
}

function findMissingIds(docIds, transcriptIds) {
  return docIds.filter((id) => !transcriptIds.includes(id.toString()));
}

export async function buildStoreDataFromResponse(responseData, isV2) {
  const {
    csv_string,
    document_metadata: documentLookup,
    aggregation_by_speaker,
    aggregation_by_doc,
    aggregation_by_doc_and_speaker,
  } = responseData;

  const aggregationData = cleanAggregationData({
    aggregation_by_speaker,
    aggregation_by_doc,
    aggregation_by_doc_and_speaker,
  });

  const parsedCsv = await parseCSV(csv_string);

  const documents = Object.values(documentLookup);
  let docIds = documents.map((d) => d.id);

  const sortedTranscriptData = sortTranscriptStatementOrder(parsedCsv);
  const transcriptData = applyFilters(sortedTranscriptData);
  const documentTranscriptLookup = generateDocumentTranscriptLookup(transcriptData);

  const missingDocIds = findMissingIds(docIds, Object.keys(documentTranscriptLookup));
  console.log(Object.keys(documentTranscriptLookup).length + ' Documents found', { missing: missingDocIds });
  docIds = docIds.filter((id) => !missingDocIds.includes(id));

  const docAggregationChartData = formatDocAggregationData(
    aggregationData.aggregation_by_doc,
    documentLookup,
    docIds,
    isV2
  );
  const speakerByDocAggregationChartData = formatSpeakerByDocAggregationData(
    aggregationData.aggregation_by_doc_and_speaker,
    documentLookup,
    docIds,
    isV2
  );

  return {
    docsInAnalysis: documents.filter((d) => !missingDocIds.includes(d.id)),
    csv: csv_string,
    parsedCsv,
    documentTranscriptLookup,
    documentLookup,
    docAggregationChartData,
    speakerByDocAggregationChartData,
  };
}

const formatSpeakerByDocAggregationData = (aggregationData, documentLookup, docIds) => {
  const formattedLookupByDocId = {};
  const chartDataByKey = {};

  const sortedDocs = sortDocIdsByTime(docIds, documentLookup);
  const sampleDoc = aggregationData[docIds[0]];
  const availableMetricKeys = generateAvailableMetricKeysSpeaker(sampleDoc);

  const speakers = [];

  docIds.forEach((dId) => {
    const docData = aggregationData[dId];
    if (docData) {
      updateSpeakers(speakers, docData);
      formattedLookupByDocId[dId] = formatDocSpeakerAggregationData(docData);
    }
  });

  availableMetricKeys.forEach((metricKey) => {
    chartDataByKey[metricKey] = generateSpeakerDocChartDataByMetricKey(
      metricKey,
      sortedDocs,
      speakers,
      formattedLookupByDocId
    );
  });
  return chartDataByKey;
};

function generateAvailableMetricKeys(sampleDoc) {
  const ignoreKeys = ['min', 'max'];
  return parseAvailableMetricKeys(sampleDoc).filter((k) => !ignoreKeys.includes(k));
}

function generateAvailableMetricKeysSpeaker(sampleDoc) {
  const ignoreKeys = ['min', 'max'];
  return parseAvailableMetricKeysSpeakerDoc(sampleDoc).filter((k) => !ignoreKeys.includes(k));
}

const formatDocAggregationData = (aggregationData, docsLookup, docIds, isV2) => {
  const formattedLookupByDocId = {};
  const chartDataByKey = {};

  const documents = Object.values(docsLookup);
  const sortedDocs = sortDocIdsByTime(docIds, docsLookup);
  const sampleDoc = aggregationData[docIds[0]];
  const availableMetricKeys = generateAvailableMetricKeys(sampleDoc);

  docIds.forEach((dId) => {
    const doc = aggregationData[dId];
    if (doc) {
      formattedLookupByDocId[dId] = formatSentimentAggregationData(doc);
    } else {
      logger('missing doc', dId, formattedLookupByDocId);
    }
  });

  availableMetricKeys.forEach((metricKey) => {
    chartDataByKey[metricKey] = generateChartDataByMetricKey(metricKey, sortedDocs, formattedLookupByDocId, documents);
  });
  return chartDataByKey;
};

export const formatSentimentAggregationData = (data) => {
  const list = Object.entries(data);
  return list.map((a) => {
    try {
      const v = {
        key: a[0],
        value: formatMetricValue(a[1]),
      };
      return v;
    } catch (e) {
      logError(a);
    }
  });
};

const applyFilters = (data) => {
  return data.filter((d) => {
    return d.speaker !== 'Heading';
  });
};

const formatMetricValue = (v) => v.toFixed(3);

const parseAvailableMetricKeys = (doc) => {
  const keys = Object.keys(doc);
  return keys;
};

const parseAvailableMetricKeysSpeakerDoc = (doc) => {
  const keys = Object.keys(doc)[0];
  return Object.keys(doc[keys]);
};

function generateChartDataByMetricKey(metricKey, sorted, formattedLookupByDocId) {
  const labels = [];
  const datapoints = [];
  sorted.forEach((doc, i) => {
    const { id: docId, filename, report_date } = doc;
    const docValues = formattedLookupByDocId[docId];
    if (docValues) {
      const valueObj = docValues.filter((dv) => dv?.key === metricKey)[0];
      if (!valueObj) {
        logger('missing values', { metricKey, docValues, docId, formattedLookupByDocId });
      } else {
        const y = valueObj.value;
        const x = i;
        if (x === null) {
          console.log('null found', {
            doc,
            docValues,
          });
        }
        labels.push(truncateStringWithDate(filename, 110));
        datapoints.push({
          x,
          y,
        });
      }
    }
  });
  return {
    labels,
    datapoints,
  };
}

const formatDocSpeakerAggregationData = (data) => {
  const list = Object.entries(data);
  const filtered = list.filter((e) => {
    return e[0] !== 'Heading';
  });
  return filtered.map((s) => ({
    speaker: s[0],
    scores: formatSentimentAggregationData(s[1]),
  }));
};

function updateSpeakers(speakers, docData) {
  if (docData) {
    const docSpeakers = Object.keys(docData);
    docSpeakers.forEach((s) => {
      if (!speakers.includes(s)) {
        speakers.push(s);
      }
    });
  }
}

function generateDatasetsLookup(speakers) {
  const datasets = {};

  speakers.forEach((speaker, i) => {
    const color = usedColors[i] || '#222';
    const d = {
      label: speaker,
      data: [],
      fill: false,
      backgroundColor: color,
      borderColor: convertHexToRGBA(color, 1),
      tension: 0.1,
      spanGaps: true,
    };
    datasets[speaker] = d;
  });
  return datasets;
}

function generateSpeakerDocChartDataByMetricKey(metricKey, sorted, speakers, formattedLookupByDocId) {
  const labels = [];
  const datasetsLookup = generateDatasetsLookup(speakers);

  sorted.forEach((doc) => {
    const { id: docId, filename } = doc;
    const x = truncateStringWithDate(filename, 110);
    labels.push(truncateStringWithDate(filename, 110));

    const docSpeakersScores = formattedLookupByDocId[docId];
    if (docSpeakersScores) {
      const speakersInDoc = docSpeakersScores.map((i) => i.speaker);
      const speakersNotInDoc = speakers.filter((s) => !speakersInDoc.includes(s));
      docSpeakersScores.forEach((speakerScoreData) => {
        const { speaker, scores } = speakerScoreData;
        const valueObj = scores.filter((dv) => (dv ? dv.key === metricKey : false))[0];
        const y = valueObj ? valueObj.value : 0;
        datasetsLookup[speaker].data.push({
          x,
          y,
        });
      });
      speakersNotInDoc.forEach((speaker) => {
        datasetsLookup[speaker].data.push({
          x,
          y: null,
        });
      });
    }
  });
  return {
    labels,
    datasets: Object.values(datasetsLookup).filter((ds) => ds.label),
  };
}

const sortDocIdsByTime = (ids, docLookup) => {
  const docs = ids.map((id) => docLookup[id]);
  return docs
    .sort((a, b) => {
      const { report_date: aReportDate, published_at: aPublishedAt } = a;
      const { report_date: bReportDate, published_at: bPublishedAt } = b;
      const aDate = createTimeInstance(aReportDate || aPublishedAt);
      const bDate = createTimeInstance(bReportDate || bPublishedAt);
      return moment(aDate).valueOf() - moment(bDate).valueOf();
    })
    .map((d) => ({
      id: d.id,
      ...d,
      report_date: d.report_date || d.published_at,
    }));
};

const sortTranscriptStatementOrder = (data) => {
  return data.sort((a, b) => {
    const { seq_num_start: sequencePosA } = a;
    const { seq_num_start: sequencePosB } = b;
    return sequencePosA - sequencePosB;
  });
};

function generateDocumentTranscriptLookup(transcriptData) {
  const lookup = {};
  transcriptData.forEach((line) => {
    const { fid } = line;
    if (!lookup[fid]) {
      lookup[fid] = { lines: [] };
    }
    lookup[fid].lines.push(line);
  });
  const documentTranscriptLines = Object.keys(lookup);
  // logger(documentTranscriptLines.length + ' documents', documentTranscriptLines);
  documentTranscriptLines.forEach((key) => {
    const groupedLinesBySpeaker = [];
    const lines = lookup[key].lines;
    let currentSpeaker = lines[0].speaker;
    lines.forEach((line, i) => {
      const { speaker } = line;
      if (i === 0) {
        groupedLinesBySpeaker.push([line]);
      } else {
        if (currentSpeaker === speaker) {
          groupedLinesBySpeaker[groupedLinesBySpeaker.length - 1].push(line);
        } else {
          currentSpeaker = speaker;
          groupedLinesBySpeaker.push([line]);
        }
      }
    });
    lookup[key].groupedLinesBySpeaker = groupedLinesBySpeaker;
  });
  return lookup;
}

const parseCSV = (csvString) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvString, {
      header: true, // Indicates that the first row of the CSV are headers
      delimiter: ',', // Specifies the delimiter character
      skipEmptyLines: true, // Skips empty lines in the input
      complete: function (results) {
        resolve(results.data);
      },
    });
  });
};
