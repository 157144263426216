import React from 'react';
import styled from 'styled-components';
import { Container, Page } from '../libs/nvstr-common-ui.es';
import { Body } from '../components/layout/Body';
import { Header } from '../components/navigation';
import { usePermissionedModesV2 } from '../hooks/user/useCurrentUser';
import { scrollToTop } from '../utils/usefulFuncs';
import { TrackingEvent } from '../libs/nvstr-utils.es';
import { PermissionedMode } from '../components/UI/PermissionedMode';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div``;
const GroupModesWrapper = styled.div`
  display: grid;
  grid-template-columns: 500px 500px;
  width: max-content;
  grid-column-gap: 24px;

  @media (max-width: 1140px) {
    display: block;
    width: 100%;
  }
`;
const GroupWrapper = styled.div`
  padding: 24px 0 0 0;
`;
const NameWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const PermissionedModeGroup = ({ expandedGroup, metadata, onGroupSelect }) => {
  const { group_name: groupName } = metadata;

  return (
    <GroupWrapper>
      <Container row verticallyCenter bottom={16}>
        <NameWrapper>{groupName}</NameWrapper>
      </Container>
      <GroupModesWrapper>
        {metadata.modes.map((m) => (
          <PermissionedMode key={m.heading} metadata={m} />
        ))}
      </GroupModesWrapper>
    </GroupWrapper>
  );
};

function parseTrackingToolList(groups, ungrouped) {
  const names = [];
  groups.forEach((g) => {
    g.modes.forEach((m) => names.push(m.heading));
  });
  ungrouped?.forEach((g) => {
    g.modes?.forEach((m) => names.push(m.heading));
  });
  return names;
}

export default function ToolSelector() {
  const permissionData = usePermissionedModesV2();

  React.useEffect(() => {
    if (permissionData) {
      const { groups, ungrouped } = permissionData;
      const availTools = parseTrackingToolList(groups, ungrouped);
      TrackingEvent.create('View Tool Selector', {
        'Available Tools': availTools,
      });
    }

    scrollToTop();
  }, []);

  if (!permissionData) {
    return null;
  }
  const { groups, ungrouped } = permissionData;

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page width={'1100px'}>
            <Container top={16}>
              <h3>Digital Analyst</h3>
            </Container>

            <Container top={16} bottom={24}>
              {groups.map((m) => (
                <PermissionedModeGroup metadata={m} />
              ))}
              {ungrouped.map((m) => (
                <PermissionedModeGroup metadata={m} />
              ))}
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}
