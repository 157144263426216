import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: ${({ $Size: size }) => (size || 16) + 'px'};
  width: ${({ $Size: size }) => (size || 16) + 'px'};

  path {
    stroke: ${({ theme, $Color: color }) => color || theme.themeColors.text};
    fill: ${({ theme, $Color: color }) => color || theme.themeColors.text};
  }
`;

export const CitationIcon = ({ size, color }) => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" $Size={size} $Color={color}>
      <path d="M9 22C8.4 22 8 21.6 8 21V18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H13.9L10.2 21.7C10 21.9 9.8 22 9.5 22H9M10 16V19.1L13.1 16H20V4H4V16H10M16.3 6L14.9 9H17V13H13V8.8L14.3 6H16.3M10.3 6L8.9 9H11V13H7V8.8L8.3 6H10.3Z" />
    </SVG>
  );
};
