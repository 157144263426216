import { logger } from '../utils/usefulFuncs';

export default class ExecutionState {
  constructor() {
    this.trees = [];

    this.onComplete = () => null;
    this.onError = () => null;
  }

  addTree = (tree) => {
    this.trees.push(tree);
    tree.registerCompletionListener(this.onTreeExecutionComplete);
    tree.registerErrorListener(this.onTreeExecutionError);
  };

  onTreeExecutionError = (e) => {
    try {
      this.onError(e);
    } catch (e) {
      logger('catch caught in error execution state', e);
    }
  };

  onTreeExecutionComplete = () => {
    try {
      const areAllTreesComplete = this.trees.every((t) => t.isComplete);
      if (areAllTreesComplete) {
        this.onComplete();
      }
    } catch (e) {
      logger('catch caught in completing execution state', e);
    }
  };
}
