import React from 'react';
import { getContextItem } from '../../utils/result';
import { Modal } from '../layout/Modal';
import { Body1, Body5, Container } from '../../libs/nvstr-common-ui.es';
import { CloseButton } from '../buttons/CloseButton';
import { SkeletonButton } from '../buttons/SkeletonButton';

export const CitationsModal = ({ onDismiss, item, context }) => {
  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: false });

  const { contextData, allContextData } = getContextItem(item, context);

  if (!context) return null;

  const { doc } = item;
  const { document_url } = doc;
  const text = contextData?.text;

  const handleViewInOriginal = () => {
    const urlEncodedTextBlock = encodeURIComponent(text);
    const searchParam = `#:~:text=${urlEncodedTextBlock}`;
    window.open(document_url + searchParam, '_blank');
  };

  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <Container row verticallyCenter spaceBetween>
        <Container>
          <Body1 bold>Citation</Body1>
        </Container>
        <CloseButton onClick={onDismiss} />
      </Container>
      <Container top={8}>
        {item?.doc ? <Body5>{item.doc.filename}</Body5> : null}
        <Container top={8}>
          {text ? <Body5>{text}</Body5> : allContextData?.map((t, i) => <Body5 key={i}>{t}</Body5>)}
        </Container>
      </Container>
      {document_url && (
        <Container top={16}>
          <SkeletonButton onClick={handleViewInOriginal}>View Original Document</SkeletonButton>
        </Container>
      )}
    </Modal>
  );
};
