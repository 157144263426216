import React from 'react';
import { getBezierPath, useEdges } from '@xyflow/react';
import { useColorTheme } from '../../../../hooks';
import { generateCustomInputNodeHandle } from '../index';

function findEdge(id, edges) {
  return edges.filter((edge) => edge.id === id)[0] || null;
}

function CustomEdge({ id, sourceX, sourceY, targetX, targetY, style = {} }) {
  const colorTheme = useColorTheme();
  const [edgePath] = getBezierPath({ sourceX, sourceY, targetX, targetY });
  const edges = useEdges();
  const edge = findEdge(id, edges);
  const nodeHandle = generateCustomInputNodeHandle(edge.targetHandle);
  const color = nodeHandle ? colorTheme[nodeHandle.colorThemeName] : colorTheme.text;
  return (
    <path
      id={id}
      style={{
        stroke: color,
        strokeWidth: 3,
        strokeDasharray: '10,4',
        ...style,
      }}
      className="react-flow__edge-path"
      d={edgePath}
    />
  );
}

function ReverseBendEdge({ id, sourceX, sourceY, targetX, targetY, style = {}, markerEnd }) {
  const colorTheme = useColorTheme();

  const offset = 50; // Adjust offset for the bend
  const reversedPath = `M${sourceX},${sourceY} C${sourceX + offset},${sourceY - offset} ${targetX - offset},${
    targetY - offset
  } ${targetX},${targetY}`;

  return (
    <path
      id={id}
      style={{
        stroke: colorTheme.text,
        strokeWidth: 3,
        strokeDasharray: '10,4',
        ...style,
      }}
      className="react-flow__edge-path"
      d={reversedPath}
      markerEnd={markerEnd}
    />
  );
}

export default CustomEdge;
