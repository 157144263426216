export const applicationActionTypes = {
  setColorTheme: 'set-color-theme',

  setStaticCopy: 'set-static-copy',

  showAdvancedSettings: 'show-adv-settings',
  hideAdvancedSettings: 'hide-adv-settings',
  setAdvancedSettings: 'set-adv-settings',

  showErrorBanner: 'show-error-banner',
  hideErrorBanner: 'hide-error-banner',

  setCustomClientLogo: 'set-custom-logo',
};

export const newsActionTypes = {
  setNews: 'set-news',
};

export const docsActionTypes = {
  addDocs: 'set-docs',
  setMacroDocs: 'set-macro-docs',

  setGettingRecentDocs: 'set-getting-recent-docs',
  setRecentDocs: 'set-recent-docs',

  setGettingRecentFedDocs: 'set-getting-recent-fed-docs',
  setRecentFedDocs: 'set-recent-fed-docs',
};

export const currentUserActionTypes = {
  setCurrentUser: 'set-current-user',
  updateCurrentUser: 'update-current-user',
};

export const colorThemeActions = {
  set: 'setColorTheme',
  setCustomColorThemeColorDefinition: 'setCustomColorThemeColorDefinition',
  resetCustomColorTheme: 'resetCustomColorTheme',
};
export const fedSentimentActions = {
  setIsGetting: 'set-isGettingFedSentimentResults',
  setResults: 'set-FedSentimentResults',
  setError: 'set-ErrorFedSentimentResults',
  clear: 'clear-FedSentiment',
};
export const flowActions = {
  clear: 'clearFlowState',
};
export const liveDocsActions = {
  addGroups: 'addGroups',
  editDoc: 'editDoc',
  deleteDoc: 'deleteDoc',
};
export const gridTableAnalysisActions = {
  replaceFavoriteQuestions: 'replaceFavoriteQuestions',
  addFavoriteQuestion: 'addFavoriteQuestion',
  updateFavoriteQuestion: 'updateFavoriteQuestion',
  removeFavoriteQuestion: 'removeFavoriteQuestion',

  addPrompts: 'addPrompts',
  updatePrompt: 'updatePrompt',
  removePrompt: 'removePrompt',
  replacePrompts: 'replacePrompts',

  initTableRows: 'initTableRows',
  addTableRows: 'addTableRows',
  updateTableRow: 'updateTableRow',
  deleteTableRow: 'deleteTableRow',

  addSelectedDocument: 'addSelectedDocument',
  removeSelectedDocument: 'removeSelectedDocument',

  loadAllFavs: 'loadAllFavs',

  importTable: 'importTable',
  clearTable: 'clearTable',
};

export const infoIconActions = {
  show: 'showInfoIcon',
  hide: 'hideInfoIcon',
};

export const securitiesActions = {
  add: 'addSecurity',
  addMultiple: 'addSecurities',
};

export const watchlistActions = {
  update: 'updateWatchlist',
  add: 'addUpdateWatchlist',
  remove: 'removeWatchlistItem',
};

export const constantsActions = {
  updateDocTypes: 'updateDocTypes',
};

export const uploadStatusActions = {
  receiveData: 'receiveUploadStatusData',
  clearNewlyCompletedUploadDocsCount: 'clearNewlyCompletedUploadDocsCount',
};

export const uploadFilesActions = {
  addFiles: 'addFilesForUpload',
  updateFiles: 'updateFilesForUpload',
  deleteFile: 'deleteFileForUpload',
  clear: 'clearFilesForUpload',
};
