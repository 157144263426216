import React from 'react';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';

function findResult(data, id) {
  try {
    return data.doc_tags?.filter((item) => item.id == id)[0] || null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function fetchCompany(id) {
  const URL = `v1/genai_companies?company_id=${id}`;
  const { status, data, error } = await sendApiRequest('get', URL);
  logNetRequest(status, data);
  if (status === 200) {
    return {
      ok: true,
      data: data[0],
    };
  }
  return { status, error };
}

async function fetchCompanyTag(id) {
  const URL = `v1/genai_doc_tags?query=${id}`;
  const { status, data, error } = await sendApiRequest('get', URL);
  logNetRequest(status, data);
  if (status === 200) {
    return {
      ok: true,
      data: findResult(data, id),
    };
  }
  return { status, error };
}

export const useCompanyTagData = (id) => {
  const [company, setCompany] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const getter = async (id) => {
      const { ok, data } = await fetchCompanyTag(id);
      if (ok) {
        setCompany(data);
        setIsLoading(false);
        setError(null);
      } else {
        setError('Something went wrong');
        setIsLoading(false);
      }
    };
    getter(id);
  }, [id]);

  return React.useMemo(() => {
    return {
      isLoading,
      company,
      error,
    };
  }, [isLoading, company, error]);
};

export const useCompanyData = (id) => {
  const [company, setCompany] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const getter = async (id) => {
      const { ok, data } = await fetchCompany(id);
      if (ok) {
        setCompany(data);
        setIsLoading(false);
        setError(null);
      } else {
        setError('Something went wrong');
        setIsLoading(false);
      }
    };
    getter(id);
  }, [id]);

  return React.useMemo(() => {
    return {
      isLoading,
      company,
      error,
    };
  }, [isLoading, company, error]);
};
