import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { Body1, Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { useColorTheme } from '../hooks';
import { useSearchParams } from 'react-router-dom';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { ROUTES } from '../constants/routes';
import { sendApiRequest } from '../services/api';
import { logError, logNetRequest } from '../utils/usefulFuncs';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;

const fetchDoc = async (filename) => {
  const URL = `v1/genai_tickers_filenames?user_uploaded=true`;
  const { status, data } = await sendApiRequest('get', URL);
  logNetRequest(status, data, URL);
  if (status === 200) {
    try {
      const docs = Object.values(data)[0];
      const foundDocs = docs.filter((d) => d.filename === filename);
      if (foundDocs.length > 0) {
        return foundDocs[0];
      }
      return null;
    } catch (e) {
      logError(e);
      return null;
    }
  } else {
    return null;
  }
};

export const AwaitDocProcessingToDigitalAnalystMode = () => {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const filename = searchParams.get('filename');

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    const init = async () => {
      const doc = await fetchDoc(filename);
      if (doc) {
        setTimeout(() => {
          navigate(ROUTES.digitalAnalyst + `?doc-ids=${doc.id}`);
        }, 600);
      } else {
        setTimeout(() => {
          init();
        }, 500);
      }
    };
    init();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={40}>
              <Body1 bold>Processing {filename ? filename : ''}</Body1>
            </Container>
            <Container top={48} bottom={59} row verticallyCenter>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
              <Container left={16}>
                <Body5>Working...</Body5>
              </Container>
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
};
