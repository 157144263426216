import { logger } from '../utils/usefulFuncs';
import { NodeExecutor } from './NodeExecutor';

export class ExecutionTree {
  constructor(startNode, state, mutableState, onAddNewTree) {
    this.startNode = startNode;
    this.mutableState = mutableState;
    this.state = state;
    this.onAddNewTree = onAddNewTree;

    this.isComplete = false;

    this.listeners = [];
    this.errorListeners = [];
  }

  registerCompletionListener = (cb) => {
    this.listeners.push(cb);
  };

  registerErrorListener = (cb) => {
    this.errorListeners.push(cb);
  };

  onTreeExecutionError = () => {
    this.isComplete = true;
    this.errorListeners.forEach((listener) => {
      listener();
    });
  };

  onTreeExecutionComplete = () => {
    this.isComplete = true;
    this.listeners.forEach((listener) => {
      listener();
    });
  };

  run = async () => {
    let executionNode = new NodeExecutor(this.startNode, this.state, this.mutableState);
    logger('running tree', { startNode: this.startNode, executionNode });

    while (executionNode) {
      await executionNode.execute();

      if (executionNode.error) {
        this.onTreeExecutionError();
        logger('execution errored', executionNode.error);
        return;
      }

      const nextNodes = executionNode.nextNodes;
      logger('nextNodes', nextNodes);
      if (nextNodes.length > 0) {
        const nextNode = nextNodes[0];
        const nextExecutionNode = new NodeExecutor(nextNode, this.state, this.mutableState);
        executionNode = nextExecutionNode;

        const newTreeNodes = nextNodes.slice(1, nextNodes.length);
        newTreeNodes.forEach((node) => {
          const tree = new ExecutionTree(node, this.state, this.mutableState);
          this.onAddNewTree(tree);
        });
      } else {
        executionNode = null;
        logger('tree execution complete');
        this.onTreeExecutionComplete();
      }
    }
  };
}
