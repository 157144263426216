import React from 'react';
import styled from 'styled-components';
import { NODE_DEBUGGING } from '../index';
import { useEdges, useNodes } from '@xyflow/react';
import { logger } from '../../../../utils/usefulFuncs';
import { NodeData } from '../../../../classes/NodeUtils';

const Wrapper = styled.div`
  position: relative;
`;
const PositionWrapper = styled.div`
  position: absolute;
  bottom: -16px;
  right: -13px;
`;
const IconWrapper = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 35px;
  background: ${({ theme }) => theme.themeColors.error};
  border: 1px solid #222;

  cursor: pointer;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    z-index: 101;
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  &:hover {
    svg {
      path {
        opacity: 0.7;
      }
    }
  }
`;

export const NodeDebugger = ({ id, actions }) => {
  const nodes = useNodes();
  const edges = useEdges();

  if (!NODE_DEBUGGING) {
    return null;
  }

  const printDebug = () => {
    const node = nodes.find((node) => node.id === id);
    const nodeData = new NodeData(node, nodes, edges);
    const {
      children,
      actionNode,
      parentNodes,
      selectedDocs,

      advancedSettings,
      selectedCompanies,
      companies,
      documentList,

      nodeContext,
      extraContext,
    } = nodeData.data;
    const conditionalNextNodes = nodeData.getConditionalNextNodes(node);

    const data = {
      children,
      extraContext,
      parentNodes,
      nodeContext,
    };
    logger(id, {
      node,
      actionNode,

      companies,
      documentList,
      selectedDocs,
      selectedCompanies,
      advancedSettings,
      data,
      conditionalNextNodes,
    });
  };

  const onClick = () => {
    printDebug();
  };

  return (
    <Wrapper>
      <PositionWrapper>
        <IconWrapper onClick={onClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M9.9 6.6L9.1 7.4L10.3 8.6C9.8 8.9 9.4 9.4 9.2 10H7V11H9V12H7V13H9V14H7V15H9.2C9.6 16.2 10.7 17 12 17S14.4 16.2 14.8 15H17V14H15V13H17V12H15V11H17V10H14.8C14.6 9.4 14.2 8.9 13.7 8.5L14.9 7.3L14.2 6.6L12.8 8H12C11.8 8 11.5 8 11.3 8.1L9.9 6.6M11 11H13V12H11V11M11 13H13V14H11V13M21 11C21 16.5 17.2 21.7 12 23C6.8 21.7 3 16.5 3 11V5L12 1L21 5V11M12 21C15.8 20 19 15.5 19 11.2V6.3L12 3.2L5 6.3V11.2C5 15.5 8.2 20 12 21Z" />
          </svg>
        </IconWrapper>
      </PositionWrapper>
    </Wrapper>
  );
};
