import React, { useState } from 'react';
import { Body5, CheckCircle, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { FILE_UPLOAD_STATUS, useFileUploader, useUploadFormFiles } from '../../hooks/features/useUploadFormFiles';
import { FlatButton } from '../../components/buttons';
import { uploadFilesActions } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { CloseButton } from '../../components/buttons/CloseButton';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { Edit } from '../../assets/icons/svgs/Edit';
import { UPLOAD_DOC_TYPE_OPTIONS } from '../../constants';
import { SearchAutocompleteWrapper } from './components/SearchAutocomplete';
import { useColorTheme } from '../../hooks';
import { fetchAndUpdateStoreUploadStatus } from '../../hooks/features/useUploadStatus';
import { CompanySelectionInput, CompanySelectionInputStyling } from './components/CompanySelectionInput';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { stripFileExtension } from '../../utils/usefulFuncs';
import { ROUTES } from '../../constants/routes';

const Wrapper = styled.div``;
const CheckWrapper = styled.div`
  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

const FileRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 100px auto; /* Creates a row for each element */
  gap: 16px;
  padding-bottom: 8px;

  height: 66px;
`;
const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 42px;

  margin-left: 8px;

  svg {
    padding-top: 1px;
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const DocumentIconWrapper = styled.div`
  padding-right: 8px;

  svg {
    padding-top: 1px;
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const EditIconWrapper = styled.div`
  padding-left: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CompanySelectorWrapper = styled.div`
  ${SearchAutocompleteWrapper} {
    width: 100%;
  }

  ${CompanySelectionInputStyling} {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 8px;
    height: 34px;

    input {
      width: 100%;
      padding: 0 !important;
    }
  }

  input::placeholder {
    color: ${({ theme }) => theme.themeColors.text} !important;
    opacity: 1;
  }
`;
const ButtonWrapper = styled.div`
  button {
    width: 105px;
  }
`;

const ExtraActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FileName = ({ name, type, onEditFile }) => {
  const [editValue, setEditValue] = useState(name);
  const [isEditing, setIsEditing] = useState(false);

  const onEditClick = () => setIsEditing(true);

  const onFileNameChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleSave = (v) => {
    onEditFile('name', v);
    setIsEditing(false);
  };

  React.useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === 'Enter') {
        handleSave(editValue);
      }
    }

    if (isEditing) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isEditing, editValue]);

  if (isEditing) {
    return (
      <Container row verticallyCenter>
        <DocumentIconWrapper>
          <DocumentIcon />
        </DocumentIconWrapper>
        <input value={editValue} onChange={onFileNameChange} onBlur={() => handleSave(editValue)} autoFocus />
        <Container left={2}>
          <Body5>.{type}</Body5>
        </Container>
      </Container>
    );
  }
  return (
    <Container row verticallyCenter>
      <DocumentIconWrapper>
        <DocumentIcon />
      </DocumentIconWrapper>
      <div onClick={onEditClick}>
        <Container row verticallyCenter>
          <Body5>{name}</Body5>
          <Body5>.{type}</Body5>
          <EditIconWrapper onClick={onEditClick}>
            <Edit />
          </EditIconWrapper>
        </Container>
      </div>
    </Container>
  );
};

const CompanySelect = ({ company, onEditFile }) => {
  const handleCompanyTagSelect = (v) => {
    onEditFile('company', v);
  };
  const handleRemove = () => {
    onEditFile('company', null);
  };
  return (
    <CompanySelectorWrapper>
      <Container row verticallyCenter height={66} fullWidth>
        <CompanySelectionInput
          value={company}
          label={null}
          onAddCompany={handleCompanyTagSelect}
          onRemoveCompany={handleRemove}
          placeholder={'Assign Company'}
          enableCreateTag
          alwaysHideClear
        />
      </Container>
    </CompanySelectorWrapper>
  );
};

const DocTypeSelect = ({ docType, onEditFile }) => {
  const handleDocTypeSelect = (e) => {
    onEditFile('docType', e.target.value);
  };
  return (
    <Container row verticallyCenter height={66}>
      <select value={docType} onChange={handleDocTypeSelect}>
        <option value={''}>Document Type...</option>
        {UPLOAD_DOC_TYPE_OPTIONS.map((o) => (
          <option key={o}>{o}</option>
        ))}
      </select>
    </Container>
  );
};

const FileRow = ({ file, onUploadFile, onEditCompany }) => {
  const dispatch = useDispatch();

  const colorTheme = useColorTheme();
  const { name, type, company, docType, status, error } = file;

  const [didRowComplete, setDidRowComplete] = useState(false);

  React.useEffect(() => {
    if (status === FILE_UPLOAD_STATUS.complete && !didRowComplete) {
      setDidRowComplete(true);
    }
  }, [status, didRowComplete]);

  React.useEffect(() => {
    if (didRowComplete) {
      fetchAndUpdateStoreUploadStatus(dispatch);
    }
  }, [didRowComplete]);

  const handleUploadClick = () => {
    onUploadFile(file);
  };

  const onRetryUpload = () => {
    onUploadFile(file);
  };

  const handleQueryNowClick = () => {
    const normalizedName = stripFileExtension(file);
    const encodedName = encodeURIComponent(normalizedName);
    const url = `${ROUTES.docProcessingToDA}?filename=${encodedName}`;
    window.open(url, '_blank');
  };

  const onDeleteFile = () => {
    dispatch({
      type: uploadFilesActions.deleteFile,
      payload: file.name,
    });
  };

  const onEditFile = (key, value) => {
    if (key === 'company' && !!value) {
      onEditCompany(value);
    }

    dispatch({
      type: uploadFilesActions.updateFiles,
      payload: [{ prevName: file.name, changes: { [key]: value } }],
    });
  };

  if (status === FILE_UPLOAD_STATUS.uploading) {
    return (
      <FileRowWrapper>
        <Container row verticallyCenter>
          <DocumentIconWrapper>
            <DocumentIcon />
          </DocumentIconWrapper>
          <div>
            <Container row verticallyCenter>
              <Body5>{name}</Body5>
              <Body5>.{type}</Body5>
            </Container>
          </div>
        </Container>
        <div />
        <div />
        <div />
        <Container right={8} row verticallyCenter>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={12}>
            <Body5>Uploading...</Body5>
          </Container>
        </Container>
      </FileRowWrapper>
    );
  }

  if (status === FILE_UPLOAD_STATUS.complete) {
    return (
      <FileRowWrapper>
        <Container row verticallyCenter>
          <DocumentIconWrapper>
            <DocumentIcon />
          </DocumentIconWrapper>
          <div>
            <Container row verticallyCenter>
              <Body5>{name}</Body5>
              <Body5>.{type}</Body5>
            </Container>
          </div>
        </Container>
        <div />
        <Container row verticallyCenter alignRight right={24}>
          <SkeletonButton onClick={handleQueryNowClick}>Query Document Now</SkeletonButton>
        </Container>
        <Container row verticallyCenter alignRight>
          <Container right={0} bottom={1}>
            <Body5>Complete</Body5>
          </Container>
        </Container>
        <Container row verticallyCenter>
          <CheckWrapper>
            <CheckCircle />
          </CheckWrapper>
        </Container>
      </FileRowWrapper>
    );
  }

  if (status === FILE_UPLOAD_STATUS.failed) {
    return (
      <FileRowWrapper>
        <Container row verticallyCenter>
          <DocumentIconWrapper>
            <DocumentIcon />
          </DocumentIconWrapper>
          <div>
            <Container row verticallyCenter>
              <Body5>{name}</Body5>
              <Body5>.{type}</Body5>
            </Container>
          </div>
        </Container>
        <div />
        <Container bottom={2} row verticallyCenter>
          <Body5>{error || 'Something went wrong, please try again'}</Body5>
        </Container>
        <Container row verticallyCenter height={66}>
          <ButtonWrapper>
            <FlatButton onClick={onRetryUpload}>Retry</FlatButton>
          </ButtonWrapper>
        </Container>
        <Container row verticallyCenter height={66}>
          <CloseButtonWrapper>
            <CloseButton onClick={onDeleteFile} />
          </CloseButtonWrapper>
        </Container>
      </FileRowWrapper>
    );
  }

  return (
    <FileRowWrapper>
      <FileName name={file.name} type={file.type} onEditFile={onEditFile} />
      <CompanySelect company={company} onEditFile={onEditFile} />
      <DocTypeSelect docType={docType} onEditFile={onEditFile} />
      <Container row verticallyCenter height={66}>
        <ButtonWrapper>
          <FlatButton onClick={handleUploadClick}>Upload</FlatButton>
        </ButtonWrapper>
      </Container>
      <Container row verticallyCenter height={66}>
        <CloseButtonWrapper>
          <CloseButton onClick={onDeleteFile} />
        </CloseButtonWrapper>
      </Container>
    </FileRowWrapper>
  );
};

export const UploadFilesList = () => {
  const dispatch = useDispatch();
  const [updateAllFilesCompanyTag, setUpdateAllFilesCompanyTag] = React.useState(true);

  const files = useUploadFormFiles();
  const onUploadFile = useFileUploader();

  const pendingFiles = files.filter((f) => !f.status);

  const updateAllPendingFilesWithCompany = (company) => {
    if (updateAllFilesCompanyTag && company !== null) {
      pendingFiles.forEach((file) => {
        dispatch({
          type: uploadFilesActions.updateFiles,
          payload: [{ prevName: file.name, changes: { company: company } }],
        });
      });
    }
  };

  const handleUploadAll = () => {
    pendingFiles.forEach((file) => onUploadFile(file));
  };

  const toggleCompanyTagSet = () => setUpdateAllFilesCompanyTag(!updateAllFilesCompanyTag);
  return (
    <Wrapper>
      <ExtraActionsWrapper>
        {pendingFiles.length > 0 ? (
          <Container top={8} row verticallyCenter>
            <input
              type="checkbox"
              id="updateAllFilesCompanyTag"
              checked={updateAllFilesCompanyTag}
              onClick={toggleCompanyTagSet}
              style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
            />
            <label
              htmlFor="updateAllFilesCompanyTag"
              className="not-selectable"
              style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
            >
              <Container>
                <Body5>Assign the same company to all files</Body5>
              </Container>
            </label>
          </Container>
        ) : (
          <div />
        )}
        {pendingFiles.length > 1 ? (
          <Container vertical={8} right={39} row verticallyCenter>
            <SkeletonButton onClick={handleUploadAll}>Upload All</SkeletonButton>
          </Container>
        ) : (
          <div />
        )}
      </ExtraActionsWrapper>

      {files.map((file) => (
        <FileRow
          key={file.name}
          file={file}
          onUploadFile={onUploadFile}
          onEditCompany={updateAllPendingFilesWithCompany}
        />
      ))}
    </Wrapper>
  );
};
