import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import { calcSuggestedMinMax } from '../../utils/usefulFuncs';
import { useColorTheme } from '../../hooks';

const ChartHeader = styled.div`
  padding: 0 0 16px 48px;
`;
const ChartAnalysisGlobalWrapper = styled.div`
  /* Customizes the whole scrollbar (including track and thumb) */

  .chart-wrapper {
    scrollbar-color: #222 #fff;
  }

  .chart-wrapper::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
    height: 12px; /* Height of the horizontal scrollbar */
  }

  /* Customizes the track (part the thumb slides within) */

  .chart-wrapper::-webkit-scrollbar-track {
    background: #fff; /* Color of the track */
  }

  /* Customizes the thumb (the draggable part of the scrollbar) */

  .chart-wrapper::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the thumb */
  }

  /* Changes the thumb color when hovered or clicked */

  .chart-wrapper::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
`;
const ChartAnalysisWrapper = styled.div`
  overflow: auto;
`;
const datapointWidth = 10;
const ChartWrapper = styled.div`
  height: 460px;
  width: ${({ DataPoints }) => Math.max(1000, DataPoints * datapointWidth) + 'px'};
  padding: 0 0 10px 0;

  canvas {
    display: block;
    height: 460px;
    width: ${({ DataPoints }) => Math.max(1000, DataPoints * datapointWidth) + 'px'};
  }
`;

export const DocSentimentChart = ({ data, activeKey }) => {
  const colorTheme = useColorTheme();

  const generateOptions = (data) => {
    const { min, max } = calcSuggestedMinMax(data, 1);

    return {
      maintainAspectRatio: false,

      elements: {
        point: {
          radius: 4,
          hoverRadius: 12,
          pointStyle: 'circle',
        },
        line: {
          borderWidth: 3,
        },
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
      },

      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: false,
          min,
          max,
          ticks: {
            min,
            max,
            stepSize: 0.5,
            color: colorTheme.text,
          },
          title: {
            display: true,
            text: 'Dovish - Neutral - Hawkish',
          },
          grid: {
            color: (context) => (context.tick.major ? colorTheme.border : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
        },
        x: {
          beginAtZero: false,
          grid: {
            tickWidth: 1,
            lineWidth: 1,
            color: (context) => (context.tick.major ? colorTheme.border : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
            color: colorTheme.text,
            font: {
              family: 'Bolton, Arial',
              size: 10,
              color: colorTheme.text,
            },
            autoSkip: false,
          },
        },
      },
    };
  };

  const [options, setOptions] = React.useState(generateOptions(data));

  React.useEffect(() => {
    setOptions(generateOptions(data));
  }, [colorTheme]);

  const chartColor = colorTheme.text; // '#222';
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: `Doc Sentiment Over Time (${activeKey})`,
        data: data.datapoints,
        fill: false,
        backgroundColor: chartColor,
        borderColor: convertHexToRGBA(chartColor, 1),
        tension: 0.1,
      },
    ],
  };

  React.useEffect(() => {
    setOptions(generateOptions(data));
  }, [data]);
  return (
    <ChartAnalysisGlobalWrapper>
      <ChartAnalysisWrapper className={'chart-wrapper'}>
        <ChartHeader>
          <Body5 bold>{`Doc Sentiment Over Time (${activeKey})`}</Body5>
        </ChartHeader>
        <ChartWrapper DataPoints={data.labels.length}>
          <Line data={chartData} options={options} />
        </ChartWrapper>
      </ChartAnalysisWrapper>
    </ChartAnalysisGlobalWrapper>
  );
};
