import { DocListNode } from './DocListNode';
import { CompanyListNode } from './CompanyListNode';
import { AdvancedSettingsNode } from './AdvancedSettingsNode';
import { PromptNode } from './PromptNode';
import CustomEdge from './components/CustomEdge';
import StartNode from './StartNode';
import { nanoid } from 'nanoid';
import { CreateNode } from './CreateNode';
import { GenerateCompanyListNode } from './GenerateCompanyListNode';
import ForEachCompanyQuestionNode from './ForEachCompanyQuestion';
import ForEachCompanyNode from './ForEachCompany';
import ForEachIngestCompanyWebsite from './ForEachIngestCompanyWebsite';
import { AdvancedSettingsFields } from '../../../hooks/application/useAdvancedSettings';
import { IfElseNode } from './IfElseNode';

export const NODE_DEBUGGING = false;

export const COMPANY_LIST_PROMPT_PREFIX =
  'do not includes any extra text, using json and responding with a list of companies where each company is an element in an array of companies, and each element is an object with the key value pairs of "name", "symbol", satisfy this description of a company list:';

export const NODE_TYPES = {
  StartNode: 'Start Node',
  CreateNode: 'Create Node',

  PromptNode: 'Question Node',
  GenerateCompanyListNode: 'Generate Company List Node',
  IfElseNode: 'If/Else Node',

  ForEachCompanyNode: 'ForEach Company Node',

  ForEachCompanyQuestionNode: 'ForEach Company Question Node',
  ForEachIngestWebsiteNode: 'ForEach Ingest Website Node',

  CompanyListNode: 'Company List Node',
  DocListNode: 'Document List Node',
  AdvancedSettingsNode: 'Advanced Settings Node',
};

export const NODE_CREATION_CONTEXTS = {
  Start: 'Start Node',

  BodyTargetNode: 'Body Target Node',
  BodySourceNode: 'Body Source Node',

  ExecutionFlowNode: 'Execution Flow Node',

  SourceInputNode: 'Source Input Node',

  ForEachAction: 'ForEach Action',

  CompanyList: 'Company List',
  DocumentList: 'Document List',
};

export const NODE_HANDLE_TYPES = {
  target: 'target',
  source: 'source',
};

export const NODE_HANDLE_IDS = {
  executionFlowTarget: 'target',
  executionFlowSource: 'source',
  executionFlowSourceTrue: 'source-yes',
  executionFlowSourceFalse: 'source-no',

  DocumentList: 'document-list',
  CompanyList: 'company-list',
  AdvancedSettings: 'advanced-settings',
  ForEachAction: 'foreach-action',
};

export const EDGE_TYPES = {
  custom: CustomEdge,
};

export const NODE_COMPONENT_TYPES = {
  [NODE_TYPES.StartNode]: StartNode,
  [NODE_TYPES.CreateNode]: CreateNode,

  [NODE_TYPES.PromptNode]: PromptNode,
  [NODE_TYPES.IfElseNode]: IfElseNode,

  [NODE_TYPES.GenerateCompanyListNode]: GenerateCompanyListNode,
  [NODE_TYPES.ForEachCompanyNode]: ForEachCompanyNode,

  [NODE_TYPES.ForEachCompanyQuestionNode]: ForEachCompanyQuestionNode,
  [NODE_TYPES.ForEachIngestWebsiteNode]: ForEachIngestCompanyWebsite,

  [NODE_TYPES.DocListNode]: DocListNode,
  [NODE_TYPES.CompanyListNode]: CompanyListNode,
  [NODE_TYPES.AdvancedSettingsNode]: AdvancedSettingsNode,
};

const HANDLE_TYPE_TO_COMPONENT_LOOKUP = {
  [NODE_HANDLE_IDS.DocumentList]: NODE_TYPES.DocListNode,
  [NODE_HANDLE_IDS.CompanyList]: NODE_TYPES.CompanyListNode,
  [NODE_HANDLE_IDS.AdvancedSettings]: NODE_TYPES.AdvancedSettingsNode,
};

export const AVAILABLE_SETTING_FIELDS = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxAnswerLength,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.maxContextChunks,
];

export const generateCustomInputNodeHandle = (handleType) => {
  if (handleType === NODE_HANDLE_IDS.ForEachAction) {
    return { name: 'Action', colorThemeName: 'actionNode' };
  }
  if (handleType === NODE_HANDLE_IDS.DocumentList) {
    return { name: 'Document List', colorThemeName: 'documentListNode' };
  }
  if (handleType === NODE_HANDLE_IDS.CompanyList) {
    return { name: 'Company List', colorThemeName: 'companyListNode' };
  }
  if (handleType === NODE_HANDLE_IDS.AdvancedSettings) {
    return { name: 'Advanced Settings', colorThemeName: 'settingsNode' };
  }
  return null;
};

export function getCreateNodeTypeFromHandleId(handleId, handleType) {
  if (handleType === NODE_HANDLE_TYPES.target) {
    return HANDLE_TYPE_TO_COMPONENT_LOOKUP[handleId] || null;
  }
  return null;
}

export function getAvailableCreateNodes(creationContext, connectionStartSourceNodeType) {
  if (creationContext === NODE_CREATION_CONTEXTS.ForEachAction) {
    return [NODE_TYPES.ForEachCompanyQuestionNode, NODE_TYPES.ForEachIngestWebsiteNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.DocumentList) {
    return [NODE_TYPES.PromptNode, NODE_TYPES.IfElseNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.CompanyList) {
    return [NODE_TYPES.PromptNode, NODE_TYPES.IfElseNode, NODE_TYPES.ForEachCompanyNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.Start) {
    return [NODE_TYPES.PromptNode, NODE_TYPES.IfElseNode, NODE_TYPES.GenerateCompanyListNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.BodySourceNode) {
    const defaultNodes = [NODE_TYPES.PromptNode, NODE_TYPES.IfElseNode, NODE_TYPES.GenerateCompanyListNode];

    if (connectionStartSourceNodeType === NODE_TYPES.GenerateCompanyListNode) {
      return [NODE_TYPES.ForEachCompanyNode, ...defaultNodes];
    }

    return defaultNodes;
  }
  if (creationContext === NODE_CREATION_CONTEXTS.ExecutionFlowNode) {
    const defaultNodes = [NODE_TYPES.PromptNode, NODE_TYPES.IfElseNode, NODE_TYPES.GenerateCompanyListNode];

    if (connectionStartSourceNodeType === NODE_TYPES.GenerateCompanyListNode) {
      return [NODE_TYPES.ForEachCompanyNode, ...defaultNodes];
    }

    return defaultNodes;
  }

  if (creationContext === NODE_CREATION_CONTEXTS.BodyTargetNode) {
    return [NODE_TYPES.PromptNode, NODE_TYPES.IfElseNode, NODE_TYPES.GenerateCompanyListNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.SourceInputNode) {
    return [NODE_TYPES.DocListNode, NODE_TYPES.AdvancedSettingsNode];
  }
  return [];
}

export const generateCreationContextFrom = (connectionSourceNode) => {
  const { handleParams, type } = connectionSourceNode;
  const { handleId, handleType } = handleParams;

  if (type === NODE_TYPES.StartNode) {
    return NODE_CREATION_CONTEXTS.Start;
  }
  if (handleId === NODE_HANDLE_IDS.ForEachAction) {
    return NODE_CREATION_CONTEXTS.ForEachAction;
  }
  if (handleId === NODE_HANDLE_IDS.DocumentList) {
    return NODE_CREATION_CONTEXTS.DocumentList;
  }
  if (handleId === NODE_HANDLE_IDS.CompanyList) {
    return NODE_CREATION_CONTEXTS.CompanyList;
  }
  const questionNodeSourceContextTypes = [
    NODE_TYPES.StartNode,
    NODE_TYPES.PromptNode,
    NODE_TYPES.IfElseNode,
    NODE_TYPES.GenerateCompanyListNode,
    NODE_TYPES.ForEachCompanyNode,
  ];
  if (questionNodeSourceContextTypes.includes(type) && handleType === NODE_HANDLE_IDS.executionFlowSource) {
    return NODE_CREATION_CONTEXTS.BodySourceNode;
  }
  if (questionNodeSourceContextTypes.includes(type) && handleType === NODE_HANDLE_IDS.executionFlowTarget) {
    return NODE_CREATION_CONTEXTS.BodyTargetNode;
  }
  return null;
};

export const createFlowNode = (type) => {
  const id = nanoid(6);
  const node = {
    id,
    type,
    dragHandle: '.custom-drag-handle',
    data: {
      id,
      actions: {},
    },
  };
  return node;
};
