import React from 'react';
import { AddPromptModal } from '../TableAnalysis/tableComponents/AddPromptRow';
import { TransparentFlatButton } from '../../components/buttons';

export const AddPromptCell = () => {
  const [isAddingPrompt, setIsAddingPrompt] = React.useState(false);
  const onDismiss = () => setIsAddingPrompt(false);

  const handleAddQuestionClick = () => {
    setIsAddingPrompt(true);
  };

  return (
    <>
      {isAddingPrompt && <AddPromptModal onDismiss={onDismiss} />}
      <TransparentFlatButton onClick={handleAddQuestionClick}>+ Add Question</TransparentFlatButton>
    </>
  );
};
