import React from 'react';
import styled from 'styled-components';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { useFedSentiment } from '../../hooks/features/useFedSentiment';
import { SentimentChart } from './SentimentChart';
import { SentimentTranscript } from './SentimentTranscript';
import { createTimeInstance, moment } from '../../libs/nvstr-utils.es';
import { truncateStringWithDate } from '../../utils/usefulFuncs';

const Wrapper = styled.div`
  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }
`;

function convertLinesToChartData(lines) {
  const labels = [];
  const datapoints = [];
  lines.forEach((d, i) => {
    const { statement, score } = d;
    labels.push(statement);
    const datapoint = {
      x: i,
      y: parseFloat(score),
    };
    datapoints.push(datapoint);
  });
  return {
    labels,
    datapoints,
  };
}

function getMostRecentTranscript(docs) {
  let mostRecent = null;
  docs.forEach((doc) => {
    if (mostRecent === null) {
      mostRecent = doc;
    } else {
      const { published_at } = doc;
      if (createTimeInstance(published_at).isAfter(createTimeInstance(mostRecent.published_at))) {
        mostRecent = doc;
      }
    }
  });
  return mostRecent;
}

function buildChartData(document, documentTranscriptLookup) {
  const transcript = documentTranscriptLookup[document];
  if (!transcript) {
    return null;
  }
  return {
    ...convertLinesToChartData(transcript.lines),
  };
}

export const SentimentDocumentSummary = () => {
  const { docsInAnalysis: documents, documentTranscriptLookup } = useFedSentiment();

  const [highlightStatementIndex, setHighlightStatementIndex] = React.useState(null);

  const [sortedAvailableDocuments, setSortedAvailableDocuments] = React.useState(null);

  const [selectedDocId, setSelectedDocId] = React.useState(getMostRecentTranscript(documents).id);
  const [chartData, setChartData] = React.useState(
    buildChartData(getMostRecentTranscript(documents).id, documentTranscriptLookup)
  );

  const onClearHighlight = () => {
    setHighlightStatementIndex(null);
  };

  React.useEffect(() => {
    if (documents) {
      const sorted = documents.sort((a, b) => {
        const { report_date: aReportDate, published_at: aPublishedAt } = a;
        const { report_date: bReportDate, published_at: bPublishedAt } = b;
        const aDate = createTimeInstance(aReportDate || aPublishedAt);
        const bDate = createTimeInstance(bReportDate || bPublishedAt);
        return moment(bDate).valueOf() - moment(aDate).valueOf();
      });
      setSortedAvailableDocuments(sorted);
    }
  }, [documents]);

  const handleSelectedDocumentChange = (e) => {
    const value = e.target.value;
    setSelectedDocId(value);
    setChartData(buildChartData(value, documentTranscriptLookup));
  };

  return (
    <Wrapper>
      <Container>
        <Container bottom={8}>
          <Body5>Selected Transcript Reader Document/Speech</Body5>
        </Container>
        <select value={selectedDocId} onChange={handleSelectedDocumentChange}>
          {sortedAvailableDocuments?.map((d) => (
            <option key={d.id} value={d.id}>
              {truncateStringWithDate(d.filename, 190)}
            </option>
          ))}
        </select>
      </Container>
      <Container top={32}>
        <SentimentChart data={chartData} />
      </Container>
      <Container top={24}>
        <SentimentTranscript
          docId={selectedDocId}
          highlightStatementIndex={highlightStatementIndex}
          onClearHighlight={onClearHighlight}
        />
      </Container>
    </Wrapper>
  );
};
