import { uploadFilesActions } from '../constants/actionTypes';

const defaultState = {
  files: [],
};

function filterDuplicatesByFilename(arr) {
  const seenFilenames = [];
  const result = [];
  arr.reverse().forEach((file) => {
    if (!seenFilenames.includes(file.name)) {
      seenFilenames.push(file.name);
      result.push(file);
    }
  });
  return result.reverse();
}

function updateFiles(currentState, updatedState) {
  const updatedCurrentState = currentState.map((currentItem) => {
    const matchingUpdatedItem = updatedState.find((updatedItem) => updatedItem.prevName === currentItem.name);
    return matchingUpdatedItem ? { ...currentItem, ...matchingUpdatedItem.changes } : currentItem;
  });

  return updatedCurrentState;
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case uploadFilesActions.addFiles: {
      const nextFilesState = [...state.files, ...action.payload];
      return {
        ...state,
        files: filterDuplicatesByFilename(nextFilesState),
      };
    }

    case uploadFilesActions.updateFiles: {
      return {
        ...state,
        files: updateFiles(state.files, action.payload),
      };
    }

    case uploadFilesActions.deleteFile: {
      const name = action.payload;
      return {
        ...state,
        files: state.files.filter((f) => f.name !== name),
      };
    }

    case uploadFilesActions.clear: {
      return {
        ...state,
        files: [],
      };
    }

    default:
      return state;
  }
}
