import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import styled from 'styled-components';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import InputSourceHandle from './components/handles/InputSourceHandle';
import { NODE_HANDLE_IDS } from './index';
import { NodeDebugger } from './components/NodeDebugger';

const ContentWrapper = styled.div`
  padding: 12px 0 16px 0;

  button {
    padding: 6px 12px;
  }
`;

export const CompanyListNode = (props) => {
  const { data, id } = props;

  const hc = () => {
    data.actions.onShowCompanySelection();
  };

  const documentsSelected = data.selectedCompanies;

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Company List'} actions={data.actions} Left={8} Right={8} />
      <NodeContentWrapper>
        <ContentWrapper>
          <Container bottom={16} left={2}>
            <Body5 bold>{`${documentsSelected.length} Compan${
              documentsSelected.length === 1 ? 'y' : 'ies'
            } Selected`}</Body5>
          </Container>
          <SkeletonButton onClick={hc}>Select Companies</SkeletonButton>
        </ContentWrapper>
      </NodeContentWrapper>
      <InputSourceHandle {...props} handleActionId={NODE_HANDLE_IDS.CompanyList} />
      <NodeDebugger id={id} />
    </NodeWrapper>
  );
};
