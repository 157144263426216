import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { useColorTheme } from '../../../../../hooks';
import { generateCustomInputNodeHandle } from '../../index';
import styled from 'styled-components';
import { Body5 } from '../../../../../libs/nvstr-common-ui.es';

const DEFAULT_HANDLE_STYLE = {
  top: 'auto',
  bottom: '13px',
};

const Wrapper = styled.div``;
const NameWrapper = styled.div`
  padding-bottom: 4px;

  * {
    color: ${({ theme, ColorName }) => theme.themeColors[ColorName]};
  }
`;
const PositionName = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 8px;

  left: 2px;
  bottom: ${({ IndexPosition }) => 6 + (34 * IndexPosition || 0)}px;

  height: 34px;
`;

export default React.memo(({ data, isConnectable, handleActionId, index }) => {
  const colorTheme = useColorTheme();
  const { name, colorThemeName } = generateCustomInputNodeHandle(handleActionId);
  if (handleActionId) {
    return (
      <Wrapper>
        <PositionName IndexPosition={index}>
          <NameWrapper ColorName={colorThemeName}>
            <Body5 bold>{name}</Body5>
          </NameWrapper>
        </PositionName>
        <Handle
          type="target"
          id={handleActionId}
          index={index}
          position={Position.Left}
          style={{
            ...DEFAULT_HANDLE_STYLE,
            bottom: 6 + 34 * (index || 0) + 'px',
            background: colorTheme[colorThemeName],
          }}
          isConnectable={isConnectable}
        />
      </Wrapper>
    );
  }
});
